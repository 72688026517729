import { Box, Button, Card, CircularProgress, TextField } from "@mui/material";
import { useState } from "react";
import ReactMarkdown from "react-markdown";
import { componentStyles } from "./styles";
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';

export const MarkdownTestComponent = () => {
    const [inputValue, setInputValue] = useState('');
    const [paragraphValue, setParagraphValue] = useState('');

    const [text, setText] = useState('');
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(event.target.value);
    };

    const handleButtonClick = () => {
        setParagraphValue(inputValue);
    };
    const styles = componentStyles;
    const content =`כדי לחשב את ההסתברות לקבל 13 מטופלים עם פצעים בעיניים מתוך 27, נשתמש בהתפלגות בינומית. ההתפלגות הבינומית מתארת את ההסתברות לקבל מספר מסוים של הצלחות ב-n ניסויים בלתי תלויים, כאשר ההסתברות להצלחה בכל ניסוי היא p.\n\nנסמן:\n- n = 27 (מספר המטופלים)\n- k = 13 (מספר המטופלים עם פצעים בעיניים)\n- p = 0.833 (ההיארעות של פצעים בעיניים)\n\nהנוסחה להסתברות בהתפלגות בינומית היא:\n\n$$ ^{n-k}(p-1) ^kp\\binom{n}{k} = (k = P(X$$\n\nכאשר:\n- P(X = k) היא ההסתברות לקבל בדיוק k הצלחות.\n- $$ \\binom{n}{k}$$ הוא מקדם הבינום, המחושב על ידי $$\\frac{n!}{k!(n-k)!} \\ $$, כאשר ! מציין עצרת.\n\nנחשב את ההסתברות לקבל בדיוק 13 מטופלים עם פצעים בעיניים:\n\n$$ \\binom{27}{13} \\cdot 0.833^{13} \\cdot (1-0.833)^{27-13} = (13 = P(X$$\n\nנחשב את מקדם הבינום:\n\n$$ \\frac{27!}{13!14!} = \\frac{27!}{13!(27-13)!} = \\binom{27}{13}$$\n\nעכשיו נחשב את ההסתברות:\n\n$$ \\frac{27!}{13!14!} \\cdot 0.833^{13} \\cdot (1-0.833)^{14}= (13 = P(X $$\n\n\כדי למצוא את התשובה, נשתמש במחשבון מדעי, נציב בו את הביטוי שקיבלנו. הערך המספרי של ההסתברות זו התשובה`



       return (
        <>
            <Box sx={{
                direction: 'rtl',
                maxWidth: '800px',
                width: { xs: '80vw', sm: '70vw' },
                mx: 'auto',
                my: 2,
                display: 'flex',
                flexDirection: 'column'

            }}>
                <TextField
                    label="Enter text"
                    value={inputValue}
                    onChange={handleInputChange}
                    variant="outlined"
                    margin="normal"
                />
                <Button variant="contained" onClick={handleButtonClick}>
                    Update Paragraph
                </Button>

                <Card sx={styles.partialResponseCard} >
                    <ReactMarkdown
                        remarkPlugins={[remarkMath]}
                        rehypePlugins={[rehypeKatex]}
                    >{content}
                    </ReactMarkdown>
                </Card>
            </Box>
        </>
    );
};

import React, { useState, useEffect, useCallback } from "react";
import Fuse from "fuse.js";
import {
  Box,
  Card,
  CardContent,
  TextField,
  Typography,
  List,
  ListItem,
  ListItemText,
  debounce,
  CircularProgress,
  Fade,
  Divider,
} from "@mui/material";
import { logCustomEvent } from "../utils/Analytics";
import SearchIcon from "@mui/icons-material/Search";
import { Term } from "./types";

interface SearchComponentProps {
  terms: Term[];
  onSearchResults: (results: any[]) => void;
}

const SearchComponent = ({ terms, onSearchResults }: SearchComponentProps) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [fuse, setFuse] = useState<any>(null);

  useEffect(() => {
    console.log("Initializing Fuse with terms:", terms);
    const fuseOptions = {
      keys: [
        { name: "term", weight: 2 },
        { name: "definition", weight: 0.25 },
        { name: "relatedTerms", weight: 0.2 },
      ],
      threshold: 0.3,
      includeScore: true,
    };

    setFuse(new Fuse(terms, fuseOptions));
  }, [terms]);

  const debouncedSearch = useCallback(
    debounce((value: string) => {
      console.log("Searching for:", value);
      if (value.length > 0 && fuse) {
        setIsLoading(true);
        const results = fuse.search(value);
        console.log("Search results:", results);
        onSearchResults(results);
        setIsLoading(false);
      } else {
        onSearchResults([]);
        setIsLoading(false);
      }
    }, 300),
    [fuse, onSearchResults]
  );

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = event.target.value;
    console.log("Search value changed:", searchValue);
    setSearchTerm(searchValue);
    debouncedSearch(searchValue);
  };

  return (
    <Box sx={{ width: "100%", mb: 2 }}>
      <TextField
        fullWidth
        variant="outlined"
        type="text"
        value={searchTerm}
        onChange={handleSearch}
        placeholder="הקלידו מונח לחיפוש..."
        InputProps={{
          style: { textAlign: "right" },
          startAdornment: (
            <>
              <SearchIcon sx={{ color: "black", mr: 1 }} />
              {isLoading && (
                <CircularProgress
                  size={20}
                  sx={{
                    color: "black",
                    position: "absolute",
                    left: 40,
                  }}
                />
              )}
            </>
          ),
        }}
        sx={{
          "& .MuiOutlinedInput-root": {
            color: "black",
            "& fieldset": {
              borderColor: "rgba(0, 0, 0, 0.5)",
            },
            "&:hover fieldset": {
              borderColor: "black",
            },
            "&.Mui-focused fieldset": {
              borderColor: "black",
            },
          },
          "& .MuiInputLabel-root": {
            color: "rgba(0, 0, 0, 0.7)",
          },
        }}
      />
    </Box>
  );
};

export default SearchComponent;

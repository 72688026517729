interface prop {
  waveSpeed:number
}

export const Waves = ({waveSpeed}: prop) => {
    // Inline styles for the SVG waves
    const waveStyle = {
        width: '100%',
        maxHeight: '25vh'
    };
    return (
        <>
            <style>
                {`
            .parallax > use {
              animation: move-forever 25s cubic-bezier(.55,.5,.45,.5) infinite;
            } 
            .parallax > use:nth-child(1) {
              animation-delay: -2s;
              animation-duration: ${waveSpeed}s;
            }
            .parallax > use:nth-child(2) {
              animation-delay: -4s;
              animation-duration: ${waveSpeed*2}s;
            }
            .parallax > use:nth-child(3) {
              animation-delay: -6s;
              animation-duration: ${waveSpeed*2.5}s;
            }
            .parallax > use:nth-child(4) {
              animation-delay: -8s;
              animation-duration: ${waveSpeed*3}s;
            }
            @keyframes move-forever {
              0% { transform: translate3d(90px, 0, 0); }
              100% { transform: translate3d(-85px, 0, 0); }
            }
          `}
            </style>
            <svg className="waves" xmlns="http://www.w3.org/2000/svg" viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto" style={waveStyle}>
                <defs>
                    <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
                </defs>
                <g className="parallax">
                    <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" />
                    <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
                    <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
                    <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
                </g>
            </svg>
        </>
    );
};
import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  useMemo,
} from "react";
import { Box, Typography, SxProps, Theme } from "@mui/material";
import { createStyles } from "./styles";
import { TOCItem } from "./TableOfContents";
import HierarchicalContent from "./HierarchicalContent";
import { styled } from "@mui/material/styles";

const frameBaseUrl = "https://transcriptionsdata.blob.core.windows.net/";
interface ImageComponentProps {
  src: string;
  alt: string;
  title?: string;
  styles: any;
  showImages: boolean;
  handleImageClick: (src: string) => void;
  toPDF?: boolean;
}
interface MarkdownProps {
  children: string | React.ReactNode;
  fontSize: number;
  isLongForm?: boolean;
  handleImageClick: (src: string) => void;
  showImages: boolean;
  toPDF?: boolean;
  isTocOpen: boolean;
  onTocItemsChange?: (items: TOCItem[]) => void;
}

export interface ContentNode {
  type: "h3" | "h4" | "h5" | "h6" | "content";
  content: React.ReactNode;
  children: ContentNode[];
  id: string;
  level?: number;
  parent?: ContentNode;
}

const ImageComponent: React.FC<{
  src: string;
  title: string;
  alt: string;
  styles: any;
  showImages: boolean;
  handleImageClick: (src: string) => void;
  toPDF?: boolean;
}> = ({ src, title, alt, styles, handleImageClick, showImages, toPDF }) => {
  return showImages ? (
    <Box sx={styles.markdownImageContainer}>
      <Box
        onClick={() => handleImageClick(frameBaseUrl + src)}
        component="img"
        src={frameBaseUrl + src.replace("\\\\", "/")}
        alt={alt || title}
        sx={styles.markdownImage}
      />
      <Typography
        variant="caption"
        sx={styles.markdownCaption as SxProps<Theme>}
      >
        {title}
      </Typography>
    </Box>
  ) : null;
};

const ImageWrapper: React.FC<ImageComponentProps> = ({
  src,
  alt,
  title,
  styles,
  showImages,
  handleImageClick,
  toPDF,
  ...props
}) => {
  if (!src) {
    return null;
  }

  return (
    <ImageComponent
      src={src}
      alt={alt || ""}
      title={title || ""}
      styles={styles}
      handleImageClick={handleImageClick}
      showImages={showImages}
      toPDF={toPDF}
    />
  );
};

let uniqueIdCounter = 0;

const generateUniqueId = (text: string, type: string): string => {
  uniqueIdCounter += 1;
  let cleanText = text
    .replace(/\([^)]*\)/g, "")
    .toLowerCase()
    .replace(/[^א-תa-z0-9\s-]/g, "")
    .replace(/\s+/g, "-")
    .replace(/-+/g, "-")
    .trim();

  if (!cleanText) {
    cleanText = "content";
  }

  return `${type}-${cleanText}-${uniqueIdCounter}`;
};

const parseContent = (markdown: string): ContentNode[] => {
  const lines = markdown.split("\n");
  const root: ContentNode = {
    type: "content",
    content: null,
    children: [],
    level: 0,
    id: "root",
  };

  let currentNode = root;
  let currentParagraph: string[] = [];

  const flushParagraph = () => {
    if (currentParagraph.length > 0) {
      const content = currentParagraph.join("\n").trim();
      if (content) {
        currentNode.children.push({
          type: "content",
          content,
          children: [],
          id: generateUniqueId("content", content),
          level: (currentNode.level || 0) + 1,
          parent: currentNode,
        });
      }
      currentParagraph = [];
    }
  };

  lines.forEach((line) => {
    const headerMatch = line.match(/^(#{3,6})\s+(.*)/);

    if (headerMatch) {
      flushParagraph();
      const headerLevel = headerMatch[1].length;
      const content = headerMatch[2];
      const newNode: ContentNode = {
        type: `h${headerLevel}` as "h3" | "h4" | "h5" | "h6",
        content,
        children: [],
        id: generateUniqueId(content, `h${headerLevel}`),
        level: headerLevel,
        parent: currentNode,
      };

      while (currentNode.level! >= headerLevel) {
        currentNode = currentNode.parent!;
      }

      currentNode.children.push(newNode);
      currentNode = newNode;
    } else {
      // Accumulate lines for the current paragraph
      currentParagraph.push(line);

      // If line is empty, flush the current paragraph
      if (line.trim() === "") {
        flushParagraph();
      }
    }
  });

  // Flush any remaining paragraph content
  flushParagraph();

  return root.children;
};

const MarkdownContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  position: "relative",

  [theme.breakpoints.down("lg")]: {
    paddingRight: 0,
  },
}));

const MainContent = styled(Box)(({ theme }) => ({
  flex: 1,
  width: "100%",
  maxWidth: "100%",
  transition: "all 0.3s ease",
}));

const Markdown: React.FC<MarkdownProps> = ({
  children,
  fontSize,
  isLongForm = false,
  handleImageClick,
  showImages,
  toPDF,
  isTocOpen,
  onTocItemsChange,
}) => {
  const styles = useMemo(
    () => createStyles(fontSize, isLongForm, toPDF),
    [fontSize, isLongForm, toPDF]
  );
  const [activeId, setActiveId] = useState<string>("");
  const [collapsedHeaders, setCollapsedHeaders] = useState<Set<string>>(
    new Set()
  );
  const observerRef = useRef<IntersectionObserver | null>(null);
  const headerRefs = useRef<Map<string, IntersectionObserverEntry>>(new Map());
  const getMarkdownContent = (children: string | React.ReactNode): string => {
    if (typeof children === "string") {
      return children;
    }
    // Handle ReactNode case - convert to string if needed
    return String(children || "");
  };

  const parsedContent = useMemo(
    () => parseContent(getMarkdownContent(children)),
    [children]
  );

  const toggleCollapse = useCallback(
    (headerId: string) => {
      setCollapsedHeaders((prev) => {
        const newSet = new Set(prev);
        if (newSet.has(headerId)) {
          const childIds = getAllChildHeaderIds(parsedContent, headerId);
          childIds.forEach((id) => newSet.delete(id));
          newSet.delete(headerId);
        } else {
          const childIds = getAllChildHeaderIds(parsedContent, headerId);
          childIds.forEach((id) => newSet.add(id));
          newSet.add(headerId);
        }
        return newSet;
      });
    },
    [parsedContent]
  );

  const handleHeaderClick = useCallback(
    (headerId: string) => {
      toggleCollapse(headerId);
    },
    [toggleCollapse]
  );

  const generateTocItems = useCallback((nodes: ContentNode[]): TOCItem[] => {
    const items: TOCItem[] = [];

    const processNode = (node: ContentNode) => {
      if (node.type.startsWith("h")) {
        items.push({
          id: node.id,
          text: node.content as string,
          level: parseInt(node.type.slice(1)) - 3,
        });
      }

      // Process children
      node.children?.forEach((child) => processNode(child));
    };

    nodes.forEach((node) => processNode(node));
    return items;
  }, []);

  const memoizedTocItems = useMemo(
    () => generateTocItems(parsedContent),
    [parsedContent, generateTocItems]
  );

  // Enhanced intersection observer setup
  useEffect(() => {
    if (!isTocOpen) return;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          headerRefs.current.set(entry.target.id, entry);

          // Find the most visible header
          const visibleHeaders = Array.from(headerRefs.current.values())
            .filter((e) => e.isIntersecting)
            .sort((a, b) => b.intersectionRatio - a.intersectionRatio);

          if (visibleHeaders.length > 0) {
            setActiveId(visibleHeaders[0].target.id);
          }
        });
      },
      {
        rootMargin: "-80px 0px -80% 0px",
        threshold: [0, 0.25, 0.5, 0.75, 1],
      }
    );

    const headers = document.querySelectorAll("h3, h4, h5, h6");
    headers.forEach((header) => observer.observe(header));
    observerRef.current = observer;

    return () => {
      headerRefs.current.clear();
      headers.forEach((header) => observer.unobserve(header));
      observer.disconnect();
    };
  }, [isTocOpen]);

  // Update the effect to only pass the TOC items
  useEffect(() => {
    if (onTocItemsChange) {
      onTocItemsChange(memoizedTocItems);
    }
  }, [memoizedTocItems, onTocItemsChange]);

  return (
    <MarkdownContainer>
      <MainContent>
        <HierarchicalContent
          nodes={parsedContent}
          styles={styles}
          showImages={showImages}
          handleImageClick={handleImageClick}
          toPDF={toPDF}
          collapsedHeaders={collapsedHeaders}
          handleHeaderClick={handleHeaderClick}
        />
      </MainContent>
    </MarkdownContainer>
  );
};

const getAllChildHeaderIds = (
  nodes: ContentNode[],
  parentId: string
): string[] => {
  const ids: string[] = [];

  const traverse = (node: ContentNode) => {
    if (node.id === parentId) {
      node.children.forEach((child) => {
        if (child.type.startsWith("h")) {
          ids.push(child.id);
          traverse(child);
        }
      });
    } else {
      node.children.forEach((child) => traverse(child));
    }
  };

  nodes.forEach((node) => traverse(node));
  return ids;
};

export default Markdown;

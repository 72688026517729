import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Container,
  Paper,
  useTheme,
  Grid,
  Card,
  CardContent,
  CardActionArea,
  IconButton,
} from "@mui/material";
import { folders } from "./quizLoaders";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ResponsiveAppBar from "../shared/AppBar";

// Hebrew names for each topic
const hebrewNames: { [key: string]: string } = {
  biochapters: "ביוכימיה",
  metabolic: "מטבוליזם",
  lung_diseases: "מחלות ריאה דלקתיות",
  bioinformatics: "ביואינפורמטיקה",
  nutrition: "תזונה",
  inflamatory: "תהליכים דלקתיים",
};

// Color palette for each topic
const topicColors: { [key: string]: string } = {
  biochapters: "#FF6B6B",
  metabolic: "#4ECDC4",
  lung_diseases: "#45B7D1",
  bioinformatics: "#FFA07A",
  nutrition: "#98D8C8",
  inflamatory: "#F7B267",
};

/**
 * QuizSelector component for choosing a specific quiz from available options.
 * This component displays a grid of quiz subjects and navigates to the selected quiz.
 */
const QuizSelector: React.FC = () => {
  const [gradientColors, setGradientColors] = useState<string[]>([
    "#ff9a9e",
    "#fad0c4",
  ]);
  const navigate = useNavigate();
  const { subject } = useParams<{ subject: string }>();
  const theme = useTheme();

  useEffect(() => {
    const gradientPairs = [
      ["#a8edea", "#fed6e3"],
      ["#d299c2", "#fef9d7"],
      ["#f5f7fa", "#c3cfe2"],
      ["#e0c3fc", "#8ec5fc"],
      ["#f093fb", "#f5576c"],
    ];
    setGradientColors(
      gradientPairs[Math.floor(Math.random() * gradientPairs.length)]
    );
  }, []);

  const handleQuizSelection = (subject: string) => {
    navigate(`/bioquiz/${subject}`);
  };

  const handleBack = () => {
    navigate("/");
  };

  return (
    <>
      <Box
        sx={{
          backgroundImage: `linear-gradient(120deg, ${gradientColors[0]} 0%, ${gradientColors[1]} 100%)`,
          minHeight: "100vh",
        }}
      >
        <ResponsiveAppBar opacity={0.6} />
        <Container maxWidth="lg">
          <Paper
            elevation={3}
            sx={{
              direction: "rtl",
              mt: 4,
              p: { xs: 2, md: 4 },
              borderRadius: 2,
              backgroundColor: "rgba(255, 255, 255, 0.8)",
              backdropFilter: "blur(10px)",
              border: "1px solid rgba(255, 255, 255, 0.3)",
              position: "relative",
            }}
          >
            {subject && (
              <IconButton
                onClick={handleBack}
                sx={{
                  position: "absolute",
                  top: 16,
                  left: 16,
                  backgroundColor: "rgba(255, 255, 255, 0.8)",
                  "&:hover": {
                    backgroundColor: "rgba(255, 255, 255, 1)",
                  },
                }}
              >
                <ArrowBackIcon />
              </IconButton>
            )}
            <Typography
              variant="h3"
              gutterBottom
              align="center"
              color="primary"
              sx={{ fontWeight: "bold", mb: 6 }}
            >
              בחר נושא לבחינה
            </Typography>

            <Grid container spacing={3}>
              {Object.entries(folders).map(([key, value]) => (
                <Grid item xs={12} sm={6} md={4} key={key}>
                  <Card
                    elevation={4}
                    sx={{
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      transition: "transform 0.2s, box-shadow 0.2s",
                      "&:hover": {
                        transform: "translateY(-5px)",
                        boxShadow: theme.shadows[8],
                      },
                      backgroundColor: topicColors[key],
                    }}
                  >
                    <CardActionArea
                      onClick={() => handleQuizSelection(key)}
                      sx={{ height: "100%" }}
                    >
                      <CardContent
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          p: 2,
                        }}
                      >
                        <Typography
                          variant="h5"
                          component="div"
                          align="center"
                          sx={{ fontWeight: "bold", color: "white" }}
                        >
                          {hebrewNames[key]}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Paper>
        </Container>
      </Box>
    </>
  );
};

export default QuizSelector;

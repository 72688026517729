import { Box, Button, Modal, Paper, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import googleCalendarPlugin from "@fullcalendar/google-calendar";
import { useRef, useState } from "react";
import saveAs from "file-saver";
import ResponsiveAppBar from "./shared/AppBar";

export const CalendarComponent = () => {
  function generateRandomColor(): string {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  const navigate = useNavigate();
  const calendarRef = useRef<FullCalendar>(null);
  const [modalOpen, setModalOpen] = useState(false);

  const handleDateClick = (arg: any) => {
    alert(arg.dateStr);
  };
  const [selectedEvent, setSelectedEvent] = useState<{
    title: string;
    start: string;
    end?: string;
    description?: string;
  } | null>(null);
  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedEvent(null);
  };

  const handleEventClick = (arg: { event: any; jsEvent: any }) => {
    arg.jsEvent.preventDefault(); // Prevent navigation

    console.log(arg);
    setSelectedEvent({
      title: arg.event.title,
      start: arg.event.start.toISOString(),
      end: arg.event.end?.toISOString(),
      description: arg.event.extendedProps.description,
    });

    setModalOpen(true);
  };

  const exportToICal = (calendarApi: FullCalendar) => {
    const events = calendarApi.getApi().getEvents();
    let icalContent = "BEGIN:VCALENDAR\nVERSION:2.0\n";

    events.forEach((event: any) => {
      icalContent += `BEGIN:VEVENT\nSUMMARY:${
        event.title
      }\nDTSTART:${event.start.toISOString()}\nDTEND:${event.end?.toISOString()}\nEND:VEVENT\n`;
    });

    icalContent += "END:VCALENDAR";

    const blob = new Blob([icalContent], {
      type: "text/calendar;charset=utf-8",
    });
    saveAs(blob, "events.ics");
  };

  const exportToGoogleCalendar = () => {
    const calendarApi = calendarRef.current ?? null;
    if (calendarApi) {
      exportToICal(calendarApi);
    }
  };

  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        background:
          "linear-gradient(60deg, rgba(84,58,183,1) 0%, rgba(0,172,193,1) 100%)",
        color: "#fff",
        boxSizing: "border-box",
      }}
    >
      <ResponsiveAppBar opacity={0.3} />

      <Box
        sx={{
          display: "flex",
          p: 2,
          flexDirection: "column",
          alignItems: "center",
          gap: "10px",
          width: "90vw",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row-reverse",
            alignItems: "right",
            width: "100%",
          }}
        >
          <Button
            sx={{
              m: 1,
              background: "orangered",
              "&:hover": { background: "orange" },
            }}
            variant="contained"
            onClick={() => navigate("/", { replace: true })}
          >
            חזור לאתר
          </Button>
        </Box>
        <Box
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "90vw",
            maxWidth: "1500px",
            "& .fc-toolbar": {
              color: "#fff",
            },
            "& .fc-event, & .fc-event-dot": {
              backgroundColor: "orange",
              color: "#000",
            },

            "& .fc-col-header, .fc-timegrid-body, .fc-scrollgrid-sync-table, .fc-daygrid-body, .fc-daygrid-body-balanced":
              {
                width: "100% !important",
                height: "100% !important",
              },
            "& .fc-direction-ltr": {
              width: "100% !important",
              height: " 100% !important",
            },
          }}
        >
          <FullCalendar
            eventInteractive={true}
            contentHeight="70vh"
            aspectRatio={2}
            themeSystem="standard"
            ref={calendarRef}
            plugins={[
              dayGridPlugin,
              interactionPlugin,
              timeGridPlugin,
              googleCalendarPlugin,
              listPlugin,
            ]}
            initialView="dayGridMonth"
            googleCalendarApiKey="AIzaSyA6tfwXMSAMyXa80fWn9gKioPBtZP7gncg"
            weekends={true}
            headerToolbar={{
              left: "prev,next today",
              center: "title",
              right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
            }}
            dayMaxEvents={true}
            initialDate={new Date().toISOString()}
            eventClick={handleEventClick}
            events={{
              googleCalendarId:
                "de1b735f89c2197891f6af5bfe114095973efdae5b15ee5dde0b9cd94858451f@group.calendar.google.com",
            }}
          />
          <Button
            sx={{
              mt: 2,
              background: "orangered",
              "&:hover": { background: "orange" },
            }}
            variant="contained"
            onClick={exportToGoogleCalendar}
          >
            Export
          </Button>
          <Modal
            open={modalOpen}
            onClose={handleCloseModal}
            aria-labelledby="event-modal-title"
            aria-describedby="event-modal-description"
          >
            <Paper
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "90vw",
                maxWidth: "600px",
                bgcolor: "background.paper",
                boxShadow: 24,
                p: 4,
              }}
            >
              {selectedEvent && (
                <>
                  <Typography
                    id="event-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    Event Details
                  </Typography>
                  <Typography id="event-modal-description" sx={{ mt: 2 }}>
                    <strong>Title:</strong> {selectedEvent.title}
                  </Typography>
                  <Typography sx={{ mt: 2 }}>
                    <strong>Start:</strong> {selectedEvent.start}
                  </Typography>
                  {selectedEvent.end && (
                    <Typography sx={{ mt: 2 }}>
                      <strong>End:</strong> {selectedEvent.end}
                    </Typography>
                  )}
                  {selectedEvent.description && (
                    <Typography sx={{ mt: 2 }}>
                      <strong>Description:</strong> {selectedEvent.description}
                    </Typography>
                  )}
                  <Button
                    sx={{ mt: 2 }}
                    variant="contained"
                    onClick={handleCloseModal}
                  >
                    Close
                  </Button>
                </>
              )}
            </Paper>
          </Modal>
        </Box>
      </Box>
    </Box>
  );
};

export default CalendarComponent;

import React, { useState } from "react";
import { Box, Typography, IconButton, Divider } from "@mui/material";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import ReactMarkdown from "react-markdown";
import remarkMath from "remark-math";
import rehypeKatex from "rehype-katex";
import { BASE_DATABASE_URL } from "../Constants/consts";

interface Prop {
  questionId: string;
  answer: string;
  question: string;
  votes: { upVotes: number; downVotes: number };
}

const QuestionAnswerSection = ({
  questionId,
  answer,
  question,
  votes,
}: Prop) => {
  const [upVotes, setUpVotes] = useState(0);

  const handleVote = async (isUpVote: boolean) => {
    const newUpvotes = isUpVote ? votes.upVotes + 1 : votes.upVotes - 1;
    setUpVotes(isUpVote ? 1 : -1);
    const updateResponse = await fetch(
      `${BASE_DATABASE_URL}/update_vote?container=votes&questionId=${questionId}`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          upvotes: newUpvotes,
        }),
      }
    );
  };
  return (
    <Box dir="rtl">
      <Typography sx={{ whiteSpace: "pre-line", mb: "30px" }} variant="body1">
        {question}
      </Typography>
      <Divider>תשובה</Divider>
      <ReactMarkdown remarkPlugins={[remarkMath]} rehypePlugins={[rehypeKatex]}>
        {answer}
      </ReactMarkdown>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "row-reverse",
        }}
      >
        <Typography variant="caption" sx={{ margin: "0 8px" }}>
          {votes.upVotes + upVotes}
        </Typography>
        <IconButton onClick={() => handleVote(true)} aria-label="upvote">
          <ThumbUpAltIcon fontSize="inherit" />
        </IconButton>
        <IconButton onClick={() => handleVote(false)} aria-label="upvote">
          <ThumbDownAltIcon fontSize="inherit" />
        </IconButton>
      </Box>
      <Divider>השאירו תגובה לשאר המחזור!</Divider>
    </Box>
  );
};

export default QuestionAnswerSection;

import React, { useState, useEffect } from "react";
import {
  Box,
  IconButton,
  TextField,
  Typography,
  Collapse,
  Avatar,
  Tooltip,
  Badge,
  CircularProgress,
} from "@mui/material";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import { TranscriptMetadata } from "./Services/fetchService";
import {
  CommentContainer,
  CommentSection,
  AddCommentButton,
  CommentHeader,
} from "./ControlStyles";
import { fetchLectureComments, postLectureComment } from './Services/firebaseService';
import { Lecture } from "../Constants/interface";
import { CommentResponse } from "../Constants/interface";

interface CommentsProps {
  comments: CommentResponse[];
  lectureId: string;
  setComments: React.Dispatch<React.SetStateAction<CommentResponse[]>>;
  lecture: Lecture;
}

const Comments: React.FC<CommentsProps> = ({
  comments,
  lectureId,
  setComments,
  lecture: lectureMetadata,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [newComment, setNewComment] = useState("");
  const [isComposing, setIsComposing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [author, setAuthor] = useState<string>("Anonymous");
  useEffect(() => {
    const loadComments = async () => {
      if (!lectureId) return;

      try {
        setIsLoading(true);
        const response = await fetchLectureComments(lectureId);
        setComments(response.comments);
      } catch (err) {
        setError('Failed to load comments');
        console.error('Error loading comments:', err);
      } finally {
        setIsLoading(false);
      }
    };

    loadComments();
  }, [lectureId, setComments]);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    const newCommentData = {
      content: newComment,
      author: author,
      lectureId: lectureId,
      timestamp: new Date().toISOString(),
      commentedToAnother: undefined,
      lectureMetadata: {
        lectureId: lectureId,
        subject: lectureMetadata.subject || "",
        name: lectureMetadata.name || "",
      },
    };

    try {
      setIsLoading(true);
      setError(null);
      const postedComment = await postLectureComment(lectureId, newCommentData);

      if (postedComment) {
        setComments(prev => [...prev, postedComment]);
        setNewComment("");
        setIsComposing(false);

        setTimeout(() => {
          const commentElements = document.querySelectorAll('.comment-item');
          const lastComment = commentElements[commentElements.length - 1];
          lastComment?.scrollIntoView({ behavior: 'smooth' });
        }, 100);
      }
    } catch (err) {
      setError('Failed to post comment. Please try again.');
      console.error('Error posting comment:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const renderComment = (comment: CommentResponse, index: number) => (
    <Box
      key={comment.id}
      className="comment-item"
      sx={{
        display: "flex",
        gap: 2,
        mb: 2,
        opacity: 0,
        animation: "fadeIn 0.3s ease forwards",
        animationDelay: `${index * 0.1}s`,
        "@keyframes fadeIn": {
          from: { opacity: 0, transform: "translateY(10px)" },
          to: { opacity: 1, transform: "translateY(0)" },
        },
      }}
    >
      <Box sx={{ flex: 1 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 0.5 }}>
          <Typography
            variant="subtitle2"
            sx={{
              fontWeight: "bold",
              color: "rgba(255, 255, 255, 0.9)",
            }}
          >
            {comment.author || "Anonymous"}
          </Typography>
        </Box>
        <Typography
          variant="body2"
          sx={{
            whiteSpace: "pre-wrap",
            color: "rgba(255, 255, 255, 0.7)",
            lineHeight: 1.5,
            fontSize: "0.9rem",
          }}
        >
          {comment.content}
        </Typography>
      </Box>
    </Box>
  );

  return (
    <CommentContainer>
      <CommentHeader>
        <Typography
          variant="subtitle1"
          sx={{
            color: "rgba(255, 255, 255, 0.9)",
            fontWeight: 500,
            ml: 1,
          }}
        >
          תגובות
        </Typography>
        <Tooltip title={`${comments.length} תגובות`}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <IconButton
              onClick={() => setIsExpanded(!isExpanded)}
              sx={{
                color: "rgba(255, 255, 255, 0.9)",
                transform: isExpanded ? "rotate(180deg)" : "none",
                transition: "all 0.3s ease",
              }}
            >
              <Badge
                badgeContent={comments.length ?? 0}
                color="primary"
                sx={{
                  "& .MuiBadge-badge": {
                    backgroundColor: "primary.main",
                    color: "rgba(255, 255, 255, 0.87)",
                    fontWeight: "bold",
                    minWidth: "20px",
                    height: "20px",
                    borderRadius: "10px",
                  },
                }}
              >
                <ChatBubbleOutlineIcon
                  sx={{ color: "rgba(255, 255, 255, 0.9)" }}
                />
              </Badge>
            </IconButton>
          </Box>
        </Tooltip>
      </CommentHeader>

      <Collapse in={isExpanded} timeout="auto">
        <CommentSection>
          {isLoading && !comments.length ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
              <CircularProgress size={24} />
            </Box>
          ) : error ? (
            <Typography color="error" sx={{ p: 2 }}>
              {error}
            </Typography>
          ) : comments.length === 0 ? (
            <Typography sx={{ p: 2, color: "rgba(255, 255, 255, 0.5)", textAlign: "center" }}>
              אין תגובות עדיין. היה הראשון להגיב!
            </Typography>
          ) : (
            comments.map((comment, index) => renderComment(comment, index))
          )}

          {isComposing ? (
            <Box sx={{ mt: 2 }}>
              <TextField
                fullWidth
                value={author}
                onChange={(e) => setAuthor(e.target.value)}
                placeholder="שם משתמש..."
                variant="outlined"
                disabled={isLoading}
                sx={{
                  mb: 1,
                  "& .MuiOutlinedInput-root": {
                    color: "rgba(255, 255, 255, 0.9)",
                    "& fieldset": {
                      borderColor: "rgba(255, 255, 255, 0.23)",
                    },
                    "&:hover fieldset": {
                      borderColor: "rgba(255, 255, 255, 0.4)",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "rgba(255, 255, 255, 0.7)",
                    },
                  },
                }}
              />

              <TextField
                fullWidth
                multiline
                rows={3}
                value={newComment}
                onChange={(e) => setNewComment(e.target.value)}
                placeholder="הוסף תגובה..."
                variant="outlined"
                disabled={isLoading}
                sx={{
                  mb: 1,
                  "& .MuiOutlinedInput-root": {
                    color: "rgba(255, 255, 255, 0.9)",
                    "& fieldset": {
                      borderColor: "rgba(255, 255, 255, 0.23)",
                    },
                    "&:hover fieldset": {
                      borderColor: "rgba(255, 255, 255, 0.4)",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "rgba(255, 255, 255, 0.7)",
                    },
                  },
                  "& .MuiInputLabel-root": {
                    color: "rgba(255, 255, 255, 0.7)",
                  },
                  "& .MuiInputBase-input::placeholder": {
                    color: "rgba(255, 255, 255, 0.5)",
                  },
                }}
              />
              <AddCommentButton
                onClick={handleSubmit}
                disabled={!newComment.trim() || isLoading}
              >
                {isLoading ? (
                  <CircularProgress size={20} color="inherit" sx={{ mr: 1 }} />
                ) : null}
                שלח תגובה
              </AddCommentButton>
            </Box>
          ) : (
            <AddCommentButton
              onClick={() => setIsComposing(true)}
              sx={{ mt: 2 }}
            >
              הוסף תגובה
            </AddCommentButton>
          )}
        </CommentSection>
      </Collapse>
    </CommentContainer>
  );
};

export default Comments;

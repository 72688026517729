import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { Button } from "@mui/material";
import LogsPage from "../LogsPage";

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {"Copyright © "}
      <Link
        color="inherit"
        href="https://www.linkedin.com/in/yosef-ben-yehuda-48a053207/"
      >
        יוסף בן יהודה היה פה
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

interface FooterProps {
  description: string;
  title: string;
}

export default function Footer(props: FooterProps) {
  const { description, title } = props;
  const [show, setShow] = React.useState(false);
  return (
    <Box
      component="footer"
      sx={{ marginTop: "5%", bgcolor: "background.paper", py: 6 }}
    >
      <Box sx={{ direction: "rtl" }}>
        <Typography variant="h6" align="center" gutterBottom>
          {title}
        </Typography>
        <Typography
          variant="subtitle1"
          align="center"
          color="text.secondary"
          component="p"
        >
          {description}
        </Typography>
        <Copyright />
      </Box>
    </Box>
  );
}

import meme1 from '../component/memes/meme1.jpg'
import meme2 from '../component/memes/meme2.jpg'
import meme3 from '../component/memes/meme3.jpg'
import meme4 from '../component/memes/meme4.jpg'
import meme5 from '../component/memes/meme5.jpg'
import meme6 from '../component/memes/meme6.jpg'
import meme7 from '../component/memes/meme7.jpg'
import meme8 from '../component/memes/meme8.jpg'
import meme9 from '../component/memes/meme9.jpg'
import meme10 from '../component/memes/meme10.jpg'
import meme11 from '../component/memes/meme11.jpg'
import meme12 from '../component/memes/meme12.jpg'
import meme13 from '../component/memes/meme13.jpg'
import meme14 from '../component/memes/meme14.png'
import meme15 from '../component/memes/meme15.jpg'
import meme16 from '../component/memes/meme15.jpg'
import meme17 from '../component/memes/meme17.jpg'
import meme18 from '../component/memes/meme18.jpg'
import meme19 from '../component/memes/meme19.jpg'
import meme20 from '../component/memes/meme20.jpg'
import meme21 from '../component/memes/meme21.jpg'
import meme22 from '../component/memes/meme22.jpg'
import meme23 from '../component/memes/meme23.jpg'
import meme24 from '../component/memes/meme24.jpg'
import meme25 from '../component/memes/meme25.jpg'
import meme26 from '../component/memes/meme26.jpg'
import meme27 from '../component/memes/meme28.png'
import { Box } from '@mui/material'

const memes = [
    meme1, meme2, meme3, meme4, meme5, meme6, meme7, meme8, meme9, meme10,
    meme11, meme12, meme13, meme14, meme15, meme17,meme16, meme18, meme19,
    meme20, meme21, meme22, meme23, meme24, meme25, meme26, meme27
];

const RandomMeme = ({ index }: { index: number }) => {

    return (
        <Box component="img" src={memes[index]} sx={{ maxWidth: {xs:'100%', sm:'400px'}, height: 'auto', padding: '10px', borderRadius: '5%' }} />
    );
}

export default RandomMeme;

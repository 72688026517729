import { ForceGraph2D } from "react-force-graph";
import { useMemo, useCallback, useState, useEffect, useRef } from "react";
import { Term } from "./types";
import * as d3 from "d3";
import SearchComponent from "./SearchComponent";

interface DisplayVisualTermsNetworkProps {
  terms: Term[];
}

const DisplayVisualTermsNetwork = ({
  terms,
}: DisplayVisualTermsNetworkProps) => {
  const [hoveredNode, setHoveredNode] = useState<any>(null);
  const [selectedNode, setSelectedNode] = useState<any>(null);
  const fgRef = useRef<any>();
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [showInfoCard, setShowInfoCard] = useState<boolean>(false);
  const isMobile = window.innerWidth < 768;

  const NODE_SIZE = 22;
  const NODE_CLICK_AREA_SIZE = isMobile ? 50 : 42;

  const handleNodeClick = useCallback(
    (node: any) => {
      if (isMobile) {
        console.log("Node clicked:", node);
        setHoveredNode(node);
        setShowInfoCard(true);
      }
      setSelectedNode(selectedNode === node ? null : node);
    },
    [selectedNode, isMobile]
  );

  const lectureColors = useMemo(() => {
    const uniqueLectures = Array.from(
      new Set(terms.map((term) => term.lectureName))
    );
    return Object.fromEntries(
      uniqueLectures.map((lecture, i) => [
        lecture,
        `hsl(${(i * 360) / uniqueLectures.length}, 70%, 65%)`,
      ])
    );
  }, [terms]);

  useEffect(() => {
    const handleResize = () => {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const graphData = useMemo(() => {
    try {
      const nodes = terms.map((term) => ({
        id: term.id,
        name: term.term,
        val: 1,
        desc: term.definition,
        lectureName: term.lectureName,
      }));

      const links = terms.flatMap((term) => {
        return term.relatedTerms.map((relatedId) => {
          return {
            source: term.id,
            target: relatedId,
            value: 1,
          };
        });
      });

      return {
        nodes,
        links: links.filter(
          (link) =>
            nodes.some((node) => node.id === link.source) &&
            nodes.some((node) => node.id === link.target)
        ),
      };
    } catch (error) {
      console.error("Error creating graph data:", error);
      return { nodes: [], links: [] };
    }
  }, [terms]);

  useEffect(() => {
    if (fgRef.current) {
      const isMobile = window.innerWidth < 768;
      const fg = fgRef.current;
      const numNodes = graphData.nodes.length;

      // Calculate dynamic forces based on number of nodes
      const chargeStrength = isMobile ? -10000 : -8000;
      const linkDistance = isMobile ? 500 : 500;

      // Remove existing forces first
      fg.d3Force("charge", null)
        .d3Force("link", null)
        .d3Force("center", null)
        .d3Force("collision", null);

      // Recreate forces with extreme values for mobile
      fg.d3Force(
        "charge",
        d3
          .forceManyBody()
          .strength((node) => chargeStrength / Math.sqrt(numNodes))
          .distanceMin(isMobile ? 500 : 400)
          .distanceMax(isMobile ? 5000 : 2000)
      );

      fg.d3Force(
        "link",
        d3
          .forceLink()
          .id((d: any) => d.id)
          .distance(linkDistance)
          .strength(0.05)
      );

      fg.d3Force(
        "collision",
        d3
          .forceCollide()
          .radius(isMobile ? 10 : 50)
          .strength(1)
          .iterations(4)
      );

      // Reheat simulation with high alpha
      fg.d3ReheatSimulation();
    }
  }, [dimensions, graphData]);

  const getNodeOpacity = useCallback(
    (node: any) => {
      if (!selectedNode) return 1;

      return selectedNode.id === node.id ||
        graphData.links.some(
          (link: any) =>
            (link.source.id === selectedNode.id &&
              link.target.id === node.id) ||
            (link.target.id === selectedNode.id && link.source.id === node.id)
        )
        ? 1
        : 0.2;
    },
    [selectedNode, graphData]
  );

  const getLinkOpacity = useCallback(
    (link: any) => {
      if (!selectedNode) return 1;

      return link.source.id === selectedNode.id ||
        link.target.id === selectedNode.id
        ? 0.5
        : 0.2;
    },
    [selectedNode]
  );

  const handleNodeHover = useCallback(
    (node: any) => {
      if (!isMobile) {
        setHoveredNode(node);
      }
    },
    [isMobile]
  );

  const getNodeColor = useCallback(
    (node: any) => {
      if (!node) return lectureColors[node?.lectureName] || "#ccc";

      if (searchResults.length === 0) {
        return lectureColors[node.lectureName];
      }

      console.log("Node:", node.id, "SearchResults:", searchResults);

      const searchResult = searchResults.find(
        (r) => r.id === (node.id || node.term)
      );
      if (searchResult) {
        const opacity = Math.max(0.3, 1 - (searchResult.score || 0));
        return `rgba(255, 165, 0, ${opacity})`; // Brighter orange color
      }

      // Make non-matching nodes more faded
      return `${lectureColors[node.lectureName]}20`; // More transparent for non-matches
    },
    [searchResults, lectureColors]
  );

  const handleSearchResults = useCallback((results: any[]) => {
    console.log("Setting search results:", results);
    // Transform the results to match the node IDs
    const transformedResults = results.map((result) => ({
      id: result.item.id || result.item.term, // Check which property matches your node ID
      score: result.score,
    }));
    setSearchResults(transformedResults);
  }, []);

  const nodeCanvasObject = useCallback(
    (node: any, ctx: any, globalScale: number) => {
      const selectionOpacity = getNodeOpacity(node);
      const searchOpacity =
        searchResults.length > 0
          ? searchResults.find((r) => r.id === (node.id || node.term))
            ? 1
            : 0.2
          : 1;

      const finalOpacity = Math.min(selectionOpacity, searchOpacity);
      const color = getNodeColor(node);

      ctx.shadowColor = color;
      ctx.shadowBlur = 15;
      ctx.beginPath();
      ctx.arc(node.x, node.y, NODE_SIZE, 0, 2 * Math.PI);
      ctx.fillStyle = color.includes("rgba")
        ? color
        : color.replace("hsl", "hsla").replace(")", `, ${finalOpacity})`);
      ctx.fill();
      ctx.shadowBlur = 0;

      const label = node.name;
      const fontSize = 15;
      ctx.font = `500 ${fontSize}px Inter, system-ui, -apple-system, sans-serif`;
      ctx.textAlign = "center";
      ctx.direction = "rtl";
      ctx.textBaseline = "middle";

      const textWidth = ctx.measureText(label).width;
      const padding = isMobile ? 14 : 8;
      const radius = 4;

      ctx.beginPath();
      ctx.roundRect(
        node.x - textWidth / 2 - padding,
        node.y - fontSize / 2 - padding / 2,
        textWidth + padding * 2,
        fontSize + padding,
        radius
      );
      ctx.fillStyle = `rgba(255, 255, 255, ${finalOpacity * 0.95})`;
      ctx.fill();

      ctx.fillStyle = `rgba(0, 0, 0, ${finalOpacity * 0.8})`;
      ctx.fillText(label, node.x, node.y);
    },
    [getNodeColor, getNodeOpacity, searchResults]
  );

  const handleBackgroundClick = useCallback((event: any) => {
    setSelectedNode(null);
    setHoveredNode(null);
    setShowInfoCard(false);
  }, []);

  const handleCloseInfoCard = useCallback(() => {
    setShowInfoCard(false);
    setHoveredNode(null);
  }, []);

  return (
    <div
      style={{
        position: "relative",
        border: "none",
        borderRadius: "12px",
        overflow: "hidden",
        height: "100vh",
        width: "100vw",
        background: "linear-gradient(145deg, #f3f4f6 0%, #e5e7eb 100%)",
      }}
    >
      <SearchComponent terms={terms} onSearchResults={handleSearchResults} />
      {graphData.nodes.length > 0 ? (
        <>
          <ForceGraph2D
            ref={fgRef}
            graphData={graphData}
            backgroundColor="#f8f9fa"
            linkWidth={1}
            nodeCanvasObject={nodeCanvasObject}
            onNodeClick={handleNodeClick}
            onNodeHover={handleNodeHover}
            onBackgroundClick={handleBackgroundClick}
            nodePointerAreaPaint={(
              node: any,
              color: string,
              ctx: CanvasRenderingContext2D
            ) => {
              ctx.beginPath();
              ctx.arc(node.x, node.y, NODE_CLICK_AREA_SIZE, 0, 2 * Math.PI);
              ctx.fillStyle = color; // Use the provided color instead of transparent
              ctx.fill();
            }}
          />
          {selectedNode && (
            <button
              onClick={() => setSelectedNode(null)}
              style={{
                position: "absolute",
                top: "20px",
                left: "20px",
                padding: "10px 20px",
                border: "none",
                borderRadius: "8px",
                cursor: "pointer",
                backgroundColor: "rgba(255, 255, 255, 0.9)",
                boxShadow: "0 2px 12px rgba(0, 0, 0, 0.1)",
                color: "#374151",
                fontSize: "14px",
                fontWeight: 500,
                transition: "all 0.2s ease",
                zIndex: 1000,
              }}
            >
              Clear Selection
            </button>
          )}
          {hoveredNode && (isMobile ? showInfoCard : true) && (
            <div
              style={{
                position: "absolute",
                top: isMobile ? "auto" : "20%",
                bottom: isMobile ? "50px" : "auto", // Added margin from bottom
                right: isMobile ? "0" : "40%",
                left: isMobile ? "0" : "auto",
                backgroundColor: "rgba(255, 255, 255, 0.98)",
                padding: "24px",
                borderRadius: isMobile ? "16px 16px 0 0" : "16px",
                boxShadow: "0 4px 24px rgba(0, 0, 0, 0.08)",
                backdropFilter: "blur(8px)",
                maxWidth: isMobile ? "70%" : "380px",
                width: isMobile ? "70%" : "auto",
                direction: "rtl",
                zIndex: 1000,
                transition: "all 0.3s ease",
                marginBottom: isMobile ? "env(safe-area-inset-bottom)" : "0", // Added safe area inset
              }}
            >
              {isMobile && (
                <button
                  onClick={handleCloseInfoCard}
                  style={{
                    position: "absolute",
                    maxWidth: "70%",
                    top: "16px",
                    left: "16px",
                    background: "none",
                    border: "none",
                    padding: "8px",
                    cursor: "pointer",
                    color: "#6B7280",
                    fontSize: "24px",
                    lineHeight: "1",
                    zIndex: 1001,
                  }}
                >
                  ×
                </button>
              )}
              <h3
                style={{
                  margin: "0 0 12px 0",
                  color: lectureColors[hoveredNode.lectureName],
                  fontSize: "20px",
                  fontWeight: 600,
                }}
              >
                {hoveredNode.name}
              </h3>
              <div
                style={{
                  fontSize: "14px",
                  color: "#6B7280",
                  marginBottom: "12px",
                  fontWeight: 500,
                }}
              >
                {hoveredNode.lectureName}
              </div>
              <p
                style={{
                  margin: "0",
                  lineHeight: "1.6",
                  textAlign: "right",
                  color: "#374151",
                  paddingBottom: isMobile ? "env(safe-area-inset-bottom)" : "0",
                }}
              >
                {hoveredNode.desc}
              </p>
            </div>
          )}
        </>
      ) : (
        <div
          style={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "#6B7280",
            fontSize: "16px",
            fontWeight: 500,
          }}
        >
          Loading graph data...
        </div>
      )}
    </div>
  );
};
export default DisplayVisualTermsNetwork;

import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Paper,
  List,
  ListItem,
  Button,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import { styled } from "@mui/system";
import { FILE } from "./Consts";
import { logCustomEvent } from "../utils/Analytics";

interface Message {
  date: string;
  sender: string;
  content: string;
}

const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: "#E5DDD5",
  height: "100vh",
  direction: "rtl",
}));

const MessageBubble = styled(Box)(
  ({ isCurrentUser }: { isCurrentUser: boolean }) => ({
    backgroundColor: isCurrentUser ? "#DCF8C6" : "#FFFFFF",
    borderRadius: "10px",
    padding: "10px",
    minWidth: "300px",
    alignSelf: "flex-end",
    marginBottom: "5px",
  })
);

const rtlTheme = createTheme({
  direction: "rtl",
});

const WhatsAppChatViewer: React.FC = () => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [fileContent, setFileContent] = useState<string>(FILE);

  const parseChat = (content: string) => {
    const lines = content.split("\n");
    const parsedMessages: Message[] = [];

    lines.forEach((line) => {
      const match = line.match(
        /(\d{2}\/\d{2}\/\d{4}, \d{2}:\d{2}) - (.*?): (.*)/
      );
      if (match) {
        parsedMessages.push({
          date: match[1],
          sender: match[2],
          content: match[3],
        });
      }
    });

    setMessages(parsedMessages);
  };

  useEffect(() => {
    parseChat(fileContent);
    logCustomEvent("Enter_WhatsappChatViewer", {});
  }, []);

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const content = e.target?.result as string;
        setFileContent(content);
        parseChat(content);
      };
      reader.readAsText(file);
    }
  };

  return (
    <ThemeProvider theme={rtlTheme}>
      <Box sx={{ height: "100vh", display: "flex", flexDirection: "column" }}>
        <Box sx={{ p: 2, backgroundColor: "#075E54" }}>
          <Typography
            sx={{
              textAlign: "right",
            }}
            variant="h6"
            color="white"
          >
            מציג וואטסאפ
          </Typography>
        </Box>
        <StyledPaper elevation={3}>
          <List
            sx={{
              flexDirection: "column",
              direction: "rtl",
              display: "flex",
              textAlign: "right",
              padding: 2,
            }}
          >
            {messages.map((message, index) => (
              <ListItem
                key={index}
                sx={{
                  textAlign: "right",
                  padding: 0,
                  marginBottom: 1,
                }}
              >
                <MessageBubble isCurrentUser={message.sender === "You"}>
                  <Typography variant="body2" color="textSecondary">
                    {message.sender}
                  </Typography>
                  <Typography variant="body1">{message.content}</Typography>
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    sx={{ display: "block", textAlign: "left" }}
                  >
                    {message.date}
                  </Typography>
                </MessageBubble>
              </ListItem>
            ))}
          </List>
        </StyledPaper>
        <Box
          sx={{
            p: 2,
            backgroundColor: "#F0F0F0",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <input
            accept=".txt"
            style={{ display: "none" }}
            id="raised-button-file"
            type="file"
            onChange={handleFileUpload}
          />
          <label htmlFor="raised-button-file">
            <Button variant="contained" component="span">
              העלאת קובץ צ'אט וואטסאפ
            </Button>
          </label>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default WhatsAppChatViewer;

import React from "react";
import { ContentNode } from "./Markdown";
import RenderNode from "./RenderNode";

export interface HierarchicalContentProps {
  nodes: ContentNode[];
  styles: any;
  showImages: boolean;
  handleImageClick: (src: string) => void;
  toPDF?: boolean;
  depth?: number;
  collapsedHeaders: Set<string>;
  handleHeaderClick: (headerId: string) => void;
}

const HierarchicalContent: React.FC<HierarchicalContentProps> = React.memo(
  ({
    nodes,
    styles,
    showImages,
    handleImageClick,
    toPDF,
    depth = 0,
    collapsedHeaders,
    handleHeaderClick,
  }) => {
    return (
      <>
        {nodes.map((node) => (
          <RenderNode
            key={node.id}
            node={node}
            styles={styles}
            showImages={showImages}
            handleImageClick={handleImageClick}
            toPDF={toPDF}
            depth={depth}
            handleHeaderClick={handleHeaderClick}
            collapsedHeaders={collapsedHeaders}
          />
        ))}
      </>
    );
  }
);

export default HierarchicalContent;

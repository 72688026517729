import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import roomData from './rooms.json';
import { Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Typography, List, ListItem, Slider, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Accordion, AccordionDetails, AccordionSummary } from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
interface SelectedTime {
    day: number,
    startHour: number,
    endHour: number,
}

interface HourAvailability {
    [hour: string]: boolean;
}

interface DayAvailability {
    [day: string]: HourAvailability;
}

interface RoomAvailability {
    [room: string]: DayAvailability;
}

interface BuildingAvailability {
    [building: string]: RoomAvailability;
}


const RoomFinderG = () => {
    const [availableRooms, setAvailableRooms] = useState<{ [building: string]: string[] }>({});
    const [selectedTime, setSelectedTime] = useState<SelectedTime>({ day: 1, startHour: 8, endHour: 10 });
    const styles = {
        mainBox: {
            direction: 'rtl',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            margin: '20px',
            color: 'white',
            background: "linear-gradient(60deg, rgba(84,58,183,1) 0%, rgba(0,172,193,1) 100%)",
            alignContent: 'center'
        },
        accordionSummary: {
            '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.08)',
            },
            transition: 'background-color 0.3s',
            color: 'white', // Adjust text color here
        },
        accordionDetails: {
            backgroundColor: 'rgba(255, 255, 255, 0.1)', // Slightly lighter background for the details
        },
        tableRowHover: {
            '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
            },
        },
        sliderBox: {
            margin: '20px',
            width: 300,
        },
        selectedHoursText: {
            direction: 'rtl',
            textAlign: 'center',
            mt: 2,
        }
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, field: keyof SelectedTime) => {
        setSelectedTime({
            ...selectedTime,
            [field]: parseInt(event.target.value),
        });
    };

    const handleSendButtonClick = () => {
        const newAvailableRooms: { [building: string]: string[] } = {};
        const buildings: BuildingAvailability = roomData;

        Object.keys(buildings).forEach(building => {
            const rooms = buildings[building];
            Object.keys(rooms).forEach(room => {
                let isAvailable = true;
                for (let hour = selectedTime.startHour; hour <= selectedTime.endHour; hour++) {
                    if (!(rooms[room][selectedTime.day] && rooms[room][selectedTime.day][hour])) {
                        isAvailable = false;
                        break;
                    }
                }
                if (isAvailable) {
                    if (!newAvailableRooms[building]) {
                        newAvailableRooms[building] = [];
                    }
                    newAvailableRooms[building].push(room);
                }
            });
        });

        setAvailableRooms(newAvailableRooms);
    };
    const navigate = useNavigate();


    return (
        <Box sx={{ width: '100vw', height: '100vh', background: "linear-gradient(60deg, rgba(84,58,183,1) 0%, rgba(0,172,193,1) 100%)", }}>
            
            <Box sx={{ justifyContent: 'flex-start', display: 'flex', alignItems: 'flex-end', flexDirection: 'column' }}>
                </Box>
            <Box sx={{
                direction: 'rtl', display: 'flex', flexDirection: 'column', alignItems: 'center', p: '20px', color: 'white',
                alignContent: 'center'
            }}>
                <Typography sx={{ p: '20px' }} variant="h6">מצא חדרים פנויים ללמידה באוניברסיטה</Typography>

                <FormControl sx={{ direction: 'rtl' }} component="fieldset">
                    <Typography variant="subtitle1">בחרו יום בשבוע</Typography>
                    <RadioGroup
                        row
                        aria-label="day"
                        name="day-of-week"
                        value={selectedTime.day.toString()}
                        onChange={(e) => setSelectedTime({ ...selectedTime, day: parseInt(e.target.value) })}
                    >
                        <FormControlLabel value="1" control={<Radio sx={{ color: 'white', '&.Mui-checked': { color: 'white' } }} />} label="ראשון" />
                        <FormControlLabel value="2" control={<Radio sx={{ color: 'white', '&.Mui-checked': { color: 'white' } }} />} label="שני" />
                        <FormControlLabel value="3" control={<Radio sx={{ color: 'white', '&.Mui-checked': { color: 'white' } }} />} label="שלישי" />
                        <FormControlLabel value="4" control={<Radio sx={{ color: 'white', '&.Mui-checked': { color: 'white' } }} />} label="רביעי" />
                        <FormControlLabel value="5" control={<Radio sx={{ color: 'white', '&.Mui-checked': { color: 'white' } }} />} label="חמישי" />
                    </RadioGroup>
                </FormControl>

                <Box sx={{ margin: '20px', width: 300 }}>
                    <Typography gutterBottom>בחרו טווח שעות</Typography>
                    <Slider
                        color="secondary"
                        value={[selectedTime.startHour, selectedTime.endHour]}
                        onChange={(event, newValue) => {
                            if (Array.isArray(newValue)) {
                                setSelectedTime({ ...selectedTime, startHour: newValue[0], endHour: newValue[1] });
                            }
                        }}
                        valueLabelDisplay="auto"
                        min={8}
                        max={19}
                        marks
                    />
                    <Typography variant="body2" sx={{ direction: 'rtl', textAlign: 'center', mt: 2 }}>
                        שעות שנבחרו: {selectedTime.endHour}:00 - {selectedTime.startHour}:00
                    </Typography>

                </Box>
                <Button variant="contained" onClick={handleSendButtonClick} sx={{ background: 'orangered', margin: '20px', '&:hover': { background: 'orange' } }}>מצא חדרים פנויים</Button>
                <TableContainer sx={{ direction: 'rtl', maxWidth: '700px' }} component={Paper}>
                    {Object.keys(availableRooms).map((building) => (
                        <Accordion sx={{maxWidth:'100vw'}} key={building}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                sx={{
                                    maxWidth:'100vw', 
                                    '&:hover': {
                                        backgroundColor: 'rgba(0, 0, 0, 0.08)',
                                    },
                                    transition: 'background-color 0.3s',
                                }}
                            >
                                <Typography>{`${building}: ${availableRooms[building].length} חדרים פנויים`}</Typography>
                            </AccordionSummary>
                            <AccordionDetails sx={{ backgroundColor: 'rgba(255, 255, 255, 0.1)', }}>
                                <Table sx={{ maxWidth:'100vw'}} size="small" aria-label="a dense table">
                                    <TableBody>
                                        {availableRooms[building].map((roomNumber) => (
                                            <TableRow
                                                key={roomNumber}
                                                sx={{
                                                    '&:hover': {
                                                        backgroundColor: 'rgba(0, 0, 0, 0.04)',
                                                    },
                                                }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {roomNumber}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </AccordionDetails>
                        </Accordion>

                    ))}
                </TableContainer>

            </Box>
        </Box>
    );
}

export default RoomFinderG;

import React, {
  useContext,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import { Box, Typography, Link } from "@mui/material";
import { styled } from "@mui/material/styles";
import { CollapsibleContext } from "../../context/CollapsibleContext";
import { createDebugger } from "../../utils/debug";

// Define the context type
interface CollapsibleContextType {
  collapsedHeaders: Set<string>;
  toggleCollapse: (headerId: string) => void;
}

export interface TOCItem {
  id: string;
  level: number;
  text: string;
}

interface TableOfContentsProps {
  items: TOCItem[];
  activeId: string;
  collapsedHeaders: Set<string>;
  toggleCollapse: (headerId: string) => void;
  isOpen: boolean;
  onClose: () => void;
}

const TOCWrapper = styled(Box)<{ isOpen: boolean }>(({ theme, isOpen }) => ({
  position: "sticky",
  top: "80px",
  width: "280px",
  maxHeight: `calc(100vh - 80px)`,
  height: "100%",
  overflowY: "auto",
  padding: theme.spacing(2),
  zIndex: theme.zIndex.drawer,
  display: isOpen ? "block" : "none",

  backgroundColor: "rgba(18, 18, 18, 0.1)",
  borderRadius: "8px",
  transition: "opacity 0.3s ease",
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));

const TOCContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isOpen",
})<{ isOpen: boolean }>(({ theme, isOpen }) => ({
  position: "sticky",
  right: 0,
  width: "240px",
  maxHeight: "calc(100vh - 200px)",
  overflowY: "auto",
  borderRadius: "16px 0 0 16px",
  border: "2px solid rgba(255, 255, 255, 0.52)",
  borderRight: "none",
  padding: theme.spacing(2),
  zIndex: 1000,
  transform: isOpen ? "translateX(0)" : "translateX(100%)",
  transition: "all 0.3s cubic-bezier(0.4, 0, 0.2, 1)",
  opacity: isOpen ? 1 : 0,
  display: isOpen ? "block" : "none",
  boxShadow: isOpen ? "-4px 0 24px rgba(0, 0, 0, 0.2)" : "none",

  "&::-webkit-scrollbar": {
    width: "8px",
    background: "transparent",
  },

  "&::-webkit-scrollbar-track": {
    background: "rgba(255, 255, 255, 0.05)",
    borderRadius: "4px",
    margin: theme.spacing(1),
  },

  "&::-webkit-scrollbar-thumb": {
    background:
      "linear-gradient(180deg, #4a90e2 0%, #357abd 50%, #2c5282 100%)",
    borderRadius: "4px",
    border: "2px solid transparent",
    backgroundClip: "padding-box",
    transition: "all 0.2s ease",

    "&:hover": {
      background:
        "linear-gradient(180deg, #5aa0f2 0%, #458acd 50%, #3c6292 100%)",
      boxShadow: "0 0 8px rgba(74, 144, 226, 0.5)",
    },
  },

  scrollbarWidth: "thin",
  scrollbarColor: "#4a90e2 rgba(255, 255, 255, 0.05)",

  scrollBehavior: "smooth",
  overscrollBehavior: "contain",

  "&:hover": {
    "&::-webkit-scrollbar-track": {
      background: "rgba(255, 255, 255, 0.08)",
    },
  },

  "&::before": {
    content: '""',
    position: "absolute",
    left: 0,
    top: 0,
    bottom: 0,
    width: "4px",
    background:
      "linear-gradient(180deg, #4a90e2 0%, #357abd 50%, #2c5282 100%)",
    opacity: isOpen ? 1 : 0,
    transition: "opacity 0.3s ease",
    borderRadius: "2px",
    boxShadow: "0 0 8px rgba(74, 144, 226, 0.3)",
  },

  "& a": {
    opacity: isOpen ? 1 : 0,
    transform: isOpen ? "translateX(0)" : "translateX(20px)",
    transition: "all 0.3s cubic-bezier(0.4, 0, 0.2, 1)",
    transitionDelay: "calc(var(--index) * 50ms)",
  },
}));

// Add new styled components for hierarchical display
const TOCItemWrapper = styled(Box)<{ level: number; isFirst?: boolean }>(
  ({ theme, level, isFirst }) => ({
    position: "relative",
    marginLeft: theme.spacing(level * 2),
    paddingLeft: theme.spacing(2),

    "&::before": {
      content: '""',
      position: "absolute",
      left: 0,
      top: isFirst ? "50%" : 0,
      bottom: 0,
      width: "1px",
      background: "rgba(255, 255, 255, 0.1)",
    },

    "&::after": {
      content: '""',
      position: "absolute",
      left: 0,
      top: "50%",
      width: theme.spacing(2),
      height: "1px",
      background: "rgba(255, 255, 255, 0.1)",
    },
  })
);

const TOCLink = styled(Link, {
  shouldForwardProp: (prop) =>
    !["active", "level", "index", "isParent"].includes(String(prop)),
})<{
  active?: boolean;
  level: number;
  index: number;
  isParent?: boolean;
  isOpen: boolean;
}>(({ theme, active, level, index, isParent, isOpen }) => ({
  "--index": index,
  display: "block",
  padding: theme.spacing(0.75, 1.5),
  color: active ? "rgba(255, 255, 255, 0.95)" : "rgba(255, 255, 255, 0.7)",
  textDecoration: "none",
  fontSize: `${16 - Math.min(level, 2)}px`,
  fontWeight: isParent ? 600 : active ? 500 : 400,
  transition: "all 0.2s cubic-bezier(0.4, 0, 0.2, 1)",
  borderRadius: "4px",
  position: "relative",
  opacity: isOpen ? 1 : 0,
  transform: isOpen ? "translateX(0)" : "translateX(20px)",
  transitionDelay: `${index * 50}ms`,

  ...(isParent && {
    textTransform: "uppercase",
    letterSpacing: "0.5px",
    color: "rgba(255, 255, 255, 0.9)",
  }),

  "&:hover": {
    color: "rgba(255, 255, 255, 1)",
    backgroundColor: "rgba(74, 144, 226, 0.15)",
    paddingLeft: theme.spacing(2),
  },

  ...(active && {
    backgroundColor: "rgba(74, 144, 226, 0.1)",
    borderLeft: "2px solid rgba(74, 144, 226, 0.9)",
  }),
}));

const TableOfContents: React.FC<TableOfContentsProps> = ({
  items,
  activeId,
  collapsedHeaders,
  toggleCollapse,
  isOpen,
  onClose,
}) => {
  const tocRef = useRef<HTMLDivElement>(null);

  // Group items by their parent
  const groupedItems = useMemo(() => {
    const result: { [key: number]: TOCItem[] } = {};
    items.forEach((item) => {
      if (!result[item.level]) {
        result[item.level] = [];
      }
      result[item.level].push(item);
    });
    return result;
  }, [items]);

  // Determine if an item is a parent (has children)
  const isParent = useCallback(
    (item: TOCItem) => {
      return items.some(
        (child) =>
          child.level > item.level &&
          items.indexOf(child) > items.indexOf(item) &&
          !items.some(
            (between) =>
              between.level <= item.level &&
              items.indexOf(between) > items.indexOf(item) &&
              items.indexOf(between) < items.indexOf(child)
          )
      );
    },
    [items]
  );

  useEffect(() => {
    if (isOpen && activeId && tocRef.current) {
      const activeElement = tocRef.current.querySelector(
        `[href="#${activeId}"]`
      );
      if (activeElement) {
        const containerRect = tocRef.current.getBoundingClientRect();
        const elementRect = activeElement.getBoundingClientRect();

        if (
          elementRect.top < containerRect.top ||
          elementRect.bottom > containerRect.bottom
        ) {
          activeElement.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }
      }
    }
  }, [activeId, isOpen]);

  const handleClick = (e: React.MouseEvent, itemId: string) => {
    e.preventDefault();
    console.log("clicked", itemId);
    const element = document.getElementById(itemId);
    if (element) {
      // Expand collapsed sections if needed
      const expandParents = () => {
        let currentElement = element;
        const headersToExpand: string[] = [];

        while (currentElement && currentElement.parentElement) {
          const parentHeader = currentElement.closest('[id^="h"]');
          if (parentHeader && collapsedHeaders.has(parentHeader.id)) {
            headersToExpand.push(parentHeader.id);
          }
          currentElement = currentElement.parentElement;
        }

        // Expand headers from top to bottom
        headersToExpand.reverse().forEach((headerId) => {
          toggleCollapse(headerId);
        });
      };

      expandParents();

      // Smooth scroll after expanding
      setTimeout(() => {
        element.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }, 300);
    }
  };

  return (
    <TOCWrapper ref={tocRef} isOpen={isOpen}>
      <TOCContainer isOpen={isOpen}>
        <Typography variant="h6" sx={{ mb: 2, color: "#fff" }}>
          תוכן עניינים
        </Typography>
        {items.map((item, index) => {
          const isItemParent = isParent(item);
          const isFirstInLevel =
            !items[index - 1] || items[index - 1].level !== item.level;

          return (
            <TOCItemWrapper
              key={item.id}
              level={item.level}
              isFirst={isFirstInLevel}
            >
              <TOCLink
                href={`#${item.id}`}
                level={item.level}
                index={index}
                active={item.id === activeId}
                isParent={isItemParent}
                isOpen={isOpen}
                onClick={(e) => handleClick(e, item.id)}
              >
                {item.text}
              </TOCLink>
            </TOCItemWrapper>
          );
        })}
      </TOCContainer>
    </TOCWrapper>
  );
};

export default TableOfContents;

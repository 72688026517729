import { useEffect, useState } from "react";
import DisplayVisualTermsNetwork from "./DisplayVisualTermsNetwork";
import DisplayVisualTermsNetwork3D from "./Display3dGraph";
import { useSearchParams } from "react-router-dom";
import { Term } from "./types";

const TERMS_API_URL =
  "https://transcriptionsdata.blob.core.windows.net/transcripts/lectures.json";

const MainVisualTermNetwork = () => {
  const [terms, setTerms] = useState<Term[]>([]);
  const [searchParams] = useSearchParams();
  const isLocal = true;
  const is3D = searchParams.get("d") === "3";

  const fetchAndProcessTerms = async (): Promise<void> => {
    try {
      const response = await fetch(TERMS_API_URL);
      const data = await response.json();
      const allTerms = data.flatMap((item: any) =>
        item.terms.map((term: any) => ({
          id: term.id, // Use term.id instead of item.id
          term: term.term,
          definition: term.definition,
          subject: item.subject,
          lectureName: item.name,
          appearances: term.appearances, // Fix typo in property name
          relatedTerms: term.related_terms,
        }))
      );
      isLocal ? setTerms(allTerms.slice(0, 550)) : setTerms(allTerms);
    } catch (error) {
      console.error("Error fetching terms:", error);
    }
  };

  useEffect(() => {
    fetchAndProcessTerms();
  }, []);

  return is3D ? (
    <DisplayVisualTermsNetwork3D terms={terms} />
  ) : (
    <DisplayVisualTermsNetwork terms={terms} />
  );
};

export default MainVisualTermNetwork;

import "./App.css";
import { Route, Routes, useLocation } from "react-router-dom";
import Blog from "./component/Blog";
import RoomFinder from "./component/RoomFinder";
import CalendarComponent from "./component/CalendarComponent";
import RoomFinderG from "./component/RoomFinder2";
import LogsPage from "./component/LogsPage";
import TestSolutionMain from "./component/TestSolution/TestSolutionMain";
import TranscriptionMain from "./component/Transcription/TranscriptionMain";
import Text from "./component/Transcription/test";
import { logPageView } from "./component/utils/Analytics";
import { useEffect } from "react";
import WhatsAppChatViewer from "./component/Whatsapp/WhatsappComponent";
import Quiz from "./component/QuizSolutions/Quiz";
import QuizSelector from "./component/QuizSolutions/QuizSelector";
import MainVisualTermNetwork from "./component/VisualTermsNetwork/MainVisualTermNetwork";

const RouteTracker = () => {
  const location = useLocation();
  useEffect(() => {
    logPageView();
  }, [location]);
  return null;
};

function App() {
  return (
    <>
      <RouteTracker />
      <Routes>
        <Route path="/med-helper" element={<Blog />} />
        <Route path="/" element={<Blog />} />
        <Route path="/RoomFinder" element={<RoomFinder />} />
        <Route path="/Room" element={<RoomFinderG />} />
        <Route path="/UserLogs" element={<LogsPage />} />
        <Route path="/TestSolution" element={<TestSolutionMain />} />
        <Route path="/demo" element={<MainVisualTermNetwork />} />
        <Route path="/Transcription" element={<TranscriptionMain />} />
        <Route path="/Transcription/:lectureId" element={<TranscriptionMain />} />
        <Route path="/Summeries" element={<TranscriptionMain />} />
        <Route path="/player" element={<Text />} />
        <Route path="/whatsapp" element={<WhatsAppChatViewer />} />
        <Route path="/Calender" element={<CalendarComponent />} />
        <Route path="/bioquiz/:subject" element={<Quiz />} />
        <Route path="/bioquiz" element={<QuizSelector />} />
      </Routes>
    </>
  );
}

export default App;

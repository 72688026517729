import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";

// Get the repository name from the pathname
const repoName = window.location.pathname.split("/")[1] || ""; // Will get 'med-helper' or 'med-helper-dev'
const basename = repoName ? `/${repoName}` : "";
console.log("index.tsx: Using basename:", basename);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter basename={basename}>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();

import React from "react";
import Rating from "@mui/material/Rating";
import Box from "@mui/material/Box";
import { Button, Card } from "@mui/material";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
interface RatingComponentProps {
  handleShare: () => void;
}

export const RatingComponent: React.FC<RatingComponentProps> = ({
  handleShare,
}) => {
  return (
    <Card sx={{ width: { xs: "300px", sm: "500px" }, mt: "50px", mb: "50px" }}>
      <Box sx={{ padding: 2 }}>
        <Box sx={{ direction: "rtl", fontWeight: "bold" }}>
          יצאה לך תשובה טובה?
        </Box>
        <Box sx={{ direction: "rtl" }}>
          אם לדעתך השיחה יכולה לעזור לחברי כיתה נוספים - הוסיפו אותה לשיחות
          השמורות הזמינות בצד ימין למעלה של האתר. היא תופיע בתוצאות החיפוש ותהיה
          זמינה לכולם (השמירה אנונימית)
        </Box>

        <Button
          variant="contained"
          size="small"
          sx={{
            mt: "15px",
            background: "green",
            "&:hover": {
              background: "rgb(26, 102, 3)",
            },
          }}
          onClick={handleShare}
          startIcon={<ShareOutlinedIcon />}
        >
          הוסיפו לשיחות שמורות
        </Button>
      </Box>
    </Card>
  );
};

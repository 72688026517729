import React, { useState, useEffect, useCallback } from "react";
import Fuse from "fuse.js";
import {
  Box,
  Dialog,
  DialogContent,
  TextField,
  Typography,
  Chip,
  CircularProgress,
  IconButton,
  InputAdornment,
  Card,
  CardContent,
  Tooltip,
  Divider,
  Button,
  useTheme,
  alpha,
} from "@mui/material";
import { ANALYTICS_CATEGORIES, logEventRe } from "../utils/Analytics";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import MenuBookIcon from '@mui/icons-material/MenuBook';
import SchoolIcon from '@mui/icons-material/School';
import { Term } from "../Constants/interface";
import { LectureSummaryDialog } from '../chat/LectureReference';
import { fetchSummary } from '../Transcription/Services/firebaseService';
import { debounce } from "lodash";
import FilterListIcon from '@mui/icons-material/FilterList';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';

interface SearchComponentProps {
  sessionId: string;
  terms: Term[];
  fuse: Fuse<Term>;
}

const SearchComponent: React.FC<SearchComponentProps> = ({ sessionId, terms, fuse }) => {
  const [searchResults, setSearchResults] = useState<Term[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedYears, setSelectedYears] = useState<string[]>([]);
  const [selectedLecture, setSelectedLecture] = useState<any>(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [lectureCache, setLectureCache] = useState<Record<string, any>>({});
  const [loading, setLoading] = useState<Record<string, boolean>>({});
  const [selectedSubjects, setSelectedSubjects] = useState<string[]>([]);
  const [selectedSubfolder, setSelectedSubfolder] = useState<string>("all");
  const theme = useTheme();
  const years = React.useMemo(() =>
    Array.from(new Set(terms.map(term => term.lecture_year)))
      .filter(year => year !== "-1")
      .sort(),
    [terms]
  );

  const subjects = React.useMemo(() =>
    Array.from(new Set(terms
      .filter(term => term.subject &&
        !["חן", "רועי", "משפטים", "סטטיסטיקה גאיה", "aa", "unknown", "חן - תרגול"]
          .includes(term.subject))
      .map(term => term.subject)))
      .sort(),
    [terms]
  );

  const renderAppearances = (appearances: string | string[]) => {
    if (!appearances) return null;
    const appearanceList = Array.isArray(appearances) ? appearances : [appearances];

    return (
      <Box sx={{ mt: 1 }}>
        <Typography variant="caption" color="text.secondary" sx={{ display: 'block', mb: 0.5 }}>
          הופעות:
        </Typography>
        {appearanceList.map((appearance, index) => (
          <Chip
            key={index}
            label={appearance}
            size="small"
            variant="outlined"
            sx={{
              m: 0.5,
              fontSize: '0.75rem',
              height: '24px'
            }}
          />
        ))}
      </Box>
    );
  };

  const debouncedSearch = useCallback(
    debounce((value: string) => {
      if (!value.trim()) {
        setSearchResults([]);
        setIsLoading(false);
        return;
      }

      if (fuse) {
        const results = fuse.search(value, { limit: 15 });
        let filteredResults = results.map(result => result.item);

        if (selectedYears.length > 0) {
          filteredResults = filteredResults.filter(
            term => term.lecture_year && selectedYears.includes(term.lecture_year)
          );
        }
        if (selectedSubjects.length > 0) {
          filteredResults = filteredResults.filter(
            term => term.subject && selectedSubjects.includes(term.subject)
          );
        }

        setSearchResults(filteredResults);
        logEventRe(ANALYTICS_CATEGORIES.SEARCH, "searched_term", value);
      }
      setIsLoading(false);
    }, 300),
    [fuse, selectedYears, selectedSubjects]
  );

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchTerm(value);
    setIsLoading(true);
    debouncedSearch(value);
  };

  const handleLectureClick = async (term: Term) => {
    if (!term.lecture_id) return;
    setDialogOpen(true);

    if (lectureCache[term.lecture_id]) {
      setSelectedLecture(lectureCache[term.lecture_id]);
      return;
    }

    try {
      setLoading(prev => ({ ...prev, [term.lecture_id!]: true }));
      const summaryData = await fetchSummary(term.lecture_id);
      const combinedData = {
        ...summaryData,
        name: term.lecture_name || '',
        subject: term.subject,
        id: term.lecture_id
      };

      setLectureCache(prev => ({ ...prev, [term.lecture_id!]: combinedData }));
      setSelectedLecture(combinedData);
    } catch (error) {
      console.error('Error fetching lecture:', error);
    } finally {
      setLoading(prev => ({ ...prev, [term.lecture_id!]: false }));
    }
  };

  return (
    <Box sx={{
      height: '80vh',
      maxHeight: '800px',
      display: 'flex',
      flexDirection: 'column',
      direction: 'rtl',
      bgcolor: theme.palette.mode === 'dark'
        ? alpha(theme.palette.background.paper, 0.2)  // More transparent in dark mode
        : 'transparent',
      borderRadius: 1,
    }}>
      {/* Search Header with Filters */}
      <Box sx={{
        p: 2,
        borderBottom: 1,
        borderColor: theme.palette.divider,
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'row' },
        gap: 2,
        bgcolor: theme.palette.mode === 'dark'
          ? alpha(theme.palette.background.default, 0.3)  // Lighter than main background
          : 'transparent',
      }}>
        <Box sx={{
          display: 'flex',
          gap: 2,
          flexDirection: { xs: 'column', sm: 'row' },
          width: { xs: '100%', sm: 'auto' }
        }}>
          <FormControl
            size="small"
            sx={{
              minWidth: { xs: '100%', sm: 200 },
              maxWidth: { xs: '100%', sm: 300 }
            }}
          >
            <InputLabel>שנים</InputLabel>
            <Select
              multiple
              value={selectedYears}
              onChange={(e) => setSelectedYears(typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value)}
              input={<OutlinedInput label="שנים" />}
              renderValue={(selected) => selected.join(', ')}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 300
                  },
                },
              }}
            >
              {years.map(year => (
                year && <MenuItem key={year} value={year}>
                  <Checkbox checked={selectedYears.indexOf(year) > -1} />
                  <ListItemText primary={year} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* <FormControl
            size="small"
            sx={{
              minWidth: { xs: '100%', sm: 200 },
              maxWidth: { xs: '100%', sm: 300 }
            }}
          >
            <InputLabel>נושאים</InputLabel>
            <Select
              multiple
              value={selectedSubjects}
              onChange={(e) => setSelectedSubjects(typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value)}
              input={<OutlinedInput label="נושאים" />}
              renderValue={(selected) => selected.join(', ')}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 300
                  },
                },
              }}
            >
              {subjects.map(subject => (
                subject && <MenuItem key={subject} value={subject}>
                  <Checkbox checked={selectedSubjects.indexOf(subject) > -1} />
                  <ListItemText primary={subject} />
                </MenuItem>
              ))}
            </Select>
          </FormControl> */}
        </Box>

        <TextField
          fullWidth
          value={searchTerm}
          onChange={handleSearch}
          placeholder="חפש מונח, נושא או הרצאה..."
          variant="outlined"
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {isLoading ? (
                  <CircularProgress size={20} />
                ) : (
                  <SearchIcon sx={{ color: theme.palette.text.secondary }} />
                )}
              </InputAdornment>
            ),
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              backgroundColor: theme.palette.mode === 'dark'
                ? alpha(theme.palette.background.paper, 0.3)
                : alpha(theme.palette.background.paper, 0.8),
              '&:hover': {
                backgroundColor: theme.palette.mode === 'dark'
                  ? alpha(theme.palette.background.paper, 0.4)
                  : alpha(theme.palette.background.paper, 0.9),
              },
              '& fieldset': {
                borderColor: theme.palette.mode === 'dark'
                  ? alpha(theme.palette.divider, 0.3)
                  : theme.palette.divider,
              },
            },
            '& .MuiInputBase-input': {
              color: theme.palette.text.primary,
              '&::placeholder': {
                color: theme.palette.text.secondary,
                opacity: 0.7,
              },
            },
          }}
        />
      </Box>

      {/* Filter Tags */}
      {(selectedYears.length > 0 || selectedSubjects.length > 0) && (
        <Box sx={{
          p: 1,
          display: 'flex',
          flexWrap: 'wrap',
          gap: 1,
          borderBottom: 1,
          borderColor: theme.palette.divider,
          bgcolor: theme.palette.mode === 'dark'
            ? alpha(theme.palette.background.default, 0.2)  // Subtle background
            : 'transparent',
        }}>
          {selectedYears.map(year => (
            <Chip
              key={year}
              label={`שנה: ${year}`}
              onDelete={() => setSelectedYears(prev => prev.filter(y => y !== year))}
              size="small"
              sx={{
                bgcolor: theme.palette.mode === 'dark'
                  ? alpha(theme.palette.primary.main, 0.2)
                  : alpha(theme.palette.primary.main, 0.1),
                color: theme.palette.text.primary,
                '& .MuiChip-deleteIcon': {
                  color: theme.palette.text.secondary,
                  '&:hover': {
                    color: theme.palette.error.main,
                  },
                },
              }}
            />
          ))}
          {selectedSubjects.map(subject => (
            <Chip
              key={subject}
              label={`נושא: ${subject}`}
              onDelete={() => setSelectedSubjects(prev => prev.filter(s => s !== subject))}
              size="small"
              sx={{
                bgcolor: theme.palette.mode === 'dark'
                  ? alpha(theme.palette.primary.main, 0.2)
                  : alpha(theme.palette.primary.main, 0.1),
                color: theme.palette.text.primary,
                '& .MuiChip-deleteIcon': {
                  color: theme.palette.text.secondary,
                  '&:hover': {
                    color: theme.palette.error.main,
                  },
                },
              }}
            />
          ))}
          <Button
            size="small"
            onClick={() => {
              setSelectedYears([]);
              setSelectedSubjects([]);
            }}
            sx={{
              color: theme.palette.text.secondary,
              '&:hover': {
                color: theme.palette.error.main,
              },
            }}
          >
            נקה הכל
          </Button>
        </Box>
      )}

      {/* Search Results */}
      <Box
        sx={{
          p: 2,
          flex: 1,
          overflowY: 'auto',
          bgcolor: 'transparent',  // Remove background here
        }}
      >
        {searchResults.length > 0 ? (
          searchResults.map(result => (
            <Card
              key={`${result.term}-${result.lecture_id}`}
              elevation={0}
              sx={{
                mb: 2,
                bgcolor: theme.palette.mode === 'dark'
                  ? alpha(theme.palette.background.paper, 0.4)  // More transparent cards
                  : theme.palette.background.paper,
                border: `1px solid ${alpha(
                  theme.palette.mode === 'dark'
                    ? theme.palette.primary.dark
                    : theme.palette.primary.main,
                  theme.palette.mode === 'dark' ? 0.2 : 0.1  // Slightly stronger border in dark mode
                )}`,
                transition: 'all 0.2s ease-in-out',
                '&:hover': {
                  transform: 'translateY(-2px)',
                  boxShadow: theme.shadows[4],
                  borderColor: theme.palette.primary.main,
                  bgcolor: theme.palette.mode === 'dark'
                    ? alpha(theme.palette.background.paper, 0.5)  // Slightly less transparent on hover
                    : theme.palette.background.paper,
                },
              }}
            >
              <CardContent dir="rtl">
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 1 }}>
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{
                      fontWeight: 600,
                      color: theme.palette.mode === 'dark'
                        ? theme.palette.primary.light
                        : theme.palette.primary.main
                    }}
                  >
                    {result.term}
                  </Typography>
                  {result.lecture_id && (
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={() => handleLectureClick(result)}
                      endIcon={loading[result.lecture_id] ? <CircularProgress size={16} /> : <MenuBookIcon sx={{ color: theme.palette.text.secondary, mr: 0.5 }} />}
                    >
                      פתח סיכום
                    </Button>
                  )}
                </Box>

                <Typography
                  variant="body1"
                  sx={{
                    mb: 2,
                    color: theme.palette.text.primary,
                  }}
                >
                  {result.definition}
                </Typography>

                {/* Metadata chips */}
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                  {result.subject && (
                    <Chip
                      icon={<SchoolIcon sx={{ color: theme.palette.text.secondary }} />}
                      label={result.subject}
                      size="small"
                      variant="outlined"
                      sx={{
                        borderColor: theme.palette.mode === 'dark'
                          ? alpha(theme.palette.primary.main, 0.3)
                          : alpha(theme.palette.primary.main, 0.2),
                        color: theme.palette.text.primary,
                      }}
                    />
                  )}
                  {result.lecture_name && (
                    <Chip
                      icon={<MenuBookIcon />}
                      label={result.lecture_name}
                      size="small"
                      variant="outlined"
                    />
                  )}
                </Box>

                {/* Related terms */}
                {result.relatedTerms?.length > 0 && (
                  <Box sx={{ mt: 1 }}>
                    <Typography variant="caption" color="text.secondary" sx={{ display: 'block', mb: 0.5 }}>
                      מונחים קשורים:
                    </Typography>
                    {result.relatedTerms.map((term, index) => (
                      <Chip
                        key={index}
                        label={term}
                        size="small"
                        variant="outlined"
                        sx={{ m: 0.5, opacity: 0.7 }}
                      />
                    ))}
                  </Box>
                )}

                {/* Appearances with timestamps */}
                {renderAppearances(result.appearances)}
              </CardContent>
            </Card>
          ))
        ) : (
          <Box
            sx={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              color: theme.palette.text.secondary,
            }}
          >
            {searchTerm ? (
              <Typography>לא נמצאו תוצאות עבור "{searchTerm}"</Typography>
            ) : (
              <>
                <SearchIcon sx={{ fontSize: 48, opacity: 0.5, mb: 2 }} />
                <Typography>הקלד מונח, נושא או שם הרצאה כדי להתחיל בחיפוש</Typography>
              </>
            )}
          </Box>
        )}
      </Box>

      <LectureSummaryDialog
        lecture={selectedLecture}
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
      />
    </Box>
  );
};

export default SearchComponent;

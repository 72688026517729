import { Box, Typography } from "@mui/material";

export const Introduction: React.FC = () => {
  return (
    <Box
      sx={{
        direction: "rtl",
        position: "relative",
        zIndex: 120,
        textAlign: "center",
        padding: "20px",
      }}
    >
      <Typography variant="h4" sx={{ marginBottom: "10px" }}></Typography>
    </Box>
  );
};

export default Introduction;

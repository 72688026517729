import { Widgets } from "@mui/icons-material";
import { Paper, ToggleButtonGroup, TextField, styled } from "@mui/material";

export const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  marginBottom: theme.spacing(3),
  background: "transparent",
  borderRadius: 24,
  marginTop: theme.spacing(4),
  transition: "all 0.3s ease",

  [theme.breakpoints.down("xs")]: {
    maxWidth: "90vw",
    width: "350px",
  },
  [theme.breakpoints.up("sm")]: {
    width: "1200px",
    maxWidth: "90vw",
  },
}));

export const TranscriptionContentPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  marginBottom: theme.spacing(3),
  background: "rgba(255, 255, 255, 0.08)",
  borderRadius: 24,
  boxShadow: "0 8px 32px rgba(0, 0, 0, 0.1)",
  backdropFilter: "blur(12px)",
  marginTop: theme.spacing(4),
  border: "1px solid rgba(255, 255, 255, 0.12)",
  transition: "all 0.3s ease",
  "&:hover": {
    boxShadow: "0 12px 40px rgba(0, 0, 0, 0.15)",
  },
}));

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)(
  ({ theme }) => ({
    gap: "4px",
    height: "100%",

    "& .MuiToggleButton-root": {
      border: "1px solid rgba(255, 255, 255, 0.15)",
      borderRadius: "10px !important",
      color: "rgba(255, 255, 255, 0.7)",
      transition: "all 0.2s ease",

      [theme.breakpoints.down("sm")]: {
        padding: "4px",
        minWidth: "36px",
        minHeight: "36px",
        "& .MuiSvgIcon-root": {
          fontSize: "1.3rem",
        },
      },

      [theme.breakpoints.up("sm")]: {
        padding: "6px",
        minWidth: "44px",
        minHeight: "44px",
        "& .MuiSvgIcon-root": {
          fontSize: "1.8rem",
        },
      },

      [theme.breakpoints.up("md")]: {
        padding: "8px",
        minWidth: "48px",
        minHeight: "48px",
        "& .MuiSvgIcon-root": {
          fontSize: "2rem",
        },
      },

      "&:hover": {
        backgroundColor: "rgba(255, 255, 255, 0.15)",
        transform: "translateY(-1px)",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.15)",
      },

      "&.Mui-selected": {
        backgroundColor: "rgba(255, 255, 255, 0.25)",
        color: "rgba(255, 255, 255, 0.95)",
        boxShadow: "0 4px 12px rgba(0, 0, 0, 0.2)",
        borderColor: "rgba(255, 255, 255, 0.3)",

        "&:hover": {
          backgroundColor: "rgba(255, 255, 255, 0.3)",
          boxShadow: "0 6px 16px rgba(0, 0, 0, 0.25)",
        },
      },

      "& .MuiSvgIcon-root": {
        transition: "transform 0.2s ease",
      },

      "&:hover .MuiSvgIcon-root": {
        transform: "scale(1.1)",
      },

      "&.Mui-selected .MuiSvgIcon-root": {
        transform: "scale(1.1)",
      },
    },
  })
);

export const SearchTextField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  width: '100%',
  '& .MuiOutlinedInput-root': {
    color: 'rgba(255, 255, 255, 0.9)',
    backgroundColor: 'rgba(255, 255, 255, 0.05)',
    borderRadius: '12px',
    transition: 'all 0.2s ease',

    '& fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.15)',
    },
    '&:hover fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.3)',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.5)',
    },
  },
  '& .MuiInputLabel-root': {
    color: 'rgba(255, 255, 255, 0.7)',
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: 'rgba(255, 255, 255, 0.9)',
  },
  '& .MuiInputBase-input': {
    '&::placeholder': {
      color: 'rgba(255, 255, 255, 0.5)',
      opacity: 1,
    },
  },
}));

import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Typography,
  CircularProgress,
  Grid,
  Card,
  Button,
  Tooltip,
} from "@mui/material";
import { Lecture } from "../Constants/interface";
import { TranscriptMetadata } from "./Services/fetchService";
import SelectLecture from "./TreeSelectedLecture";
import ToggleButtons from "./ToggleButtons";
import { StyledPaper, TranscriptionContentPaper } from "./StyledPaper";
import ContentDisplay from "./ContentDisplay";
import { SIZES } from "./ControlStyles";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import { SummeryComment } from "../Constants/interface";
import SearchIcon from '@mui/icons-material/Search';
import SearchDialog from './SearchDialog';
import Fuse from 'fuse.js';
import { Term } from '../Constants/interface';
import { keyframes } from '@emotion/react';

interface TranscriptionLayoutProps {
  playbackRate: number;
  isTestMode: boolean;
  lecture: Lecture | null;
  setLecture: React.Dispatch<React.SetStateAction<Lecture | null>>;
  view: "transcription" | "summary";
  setView: React.Dispatch<React.SetStateAction<"transcription" | "summary">>;
  length: "short" | "long";
  setLength: React.Dispatch<React.SetStateAction<"short" | "long">>;
  lectureMetadata: Lecture[];
  isMobile: boolean;
  comments: SummeryComment[] | undefined;
  preSelectedLectureId?: string;
  terms: Term[];
  sessionId: string;
}

const LayoutWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  minHeight: "100vh",
  maxWidth: "1400px",
  [theme.breakpoints.down("sm")]: {
    width: "95vw",
  },
}));

const gradientAnimation = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

const shimmerAnimation = keyframes`
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
`;

const TranscriptionLayout: React.FC<TranscriptionLayoutProps> = ({
  isTestMode,
  playbackRate,
  lecture,
  lectureMetadata,
  setLecture,
  view,
  setView,
  length,
  setLength,
  isMobile,
  comments,
  preSelectedLectureId,
  terms,
  sessionId
}) => {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const [fuse, setFuse] = useState<Fuse<Term> | null>(null);

  useEffect(() => {
    const fuseInstance = new Fuse(terms, {
      keys: [
        { name: "term", weight: 2 },
        { name: "definition", weight: 0.25 },
        { name: "relatedTerms", weight: 0.2 },
      ],
      threshold: 0.25,
      includeScore: true,
    });
    setFuse(fuseInstance);
  }, [terms]);

  return (
    <LayoutWrapper>
      <Box sx={{ flex: 1 }}>
        <StyledPaper>
          <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 2
          }}>
            <Tooltip title="חיפוש במאגר המונחים" placement="top">
              <Button
                variant="outlined"
                size="small"
                onClick={() => setSearchOpen(true)}
                startIcon={<SearchIcon />}
                sx={{
                  color: 'white',
                  fontSize: '0.85rem',
                  padding: '4px 8px',
                  minHeight: '32px',
                  textTransform: 'none',
                  fontWeight: 'normal',
                  position: 'relative',
                  border: '2px solid transparent',
                  borderRadius: '4px',
                  backgroundImage: 'none',
                  backgroundColor: 'transparent',
                  "&::before": {
                    content: '""',
                    position: "absolute",
                    top: -2,
                    left: -2,
                    right: -2,
                    bottom: -2,
                    borderRadius: "6px",
                    padding: "2px",
                    background: "linear-gradient(65deg, #FF1493, #9370DB, #00CED1, #00FA9A)",
                    backgroundSize: "300% 300%",
                    animation: `${gradientAnimation} 5s ease infinite`,
                    WebkitMask: "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
                    WebkitMaskComposite: "xor",
                    maskComposite: "exclude",
                  },
                  "&:hover": {
                    backgroundColor: "rgba(255, 255, 255, 0.1)",
                    border: "2px solid transparent",
                  },
                }}
              >
                חיפוש מונחים
              </Button>
            </Tooltip>
            <Typography
              variant="h5"
              component="h1"
              sx={{
                color: theme.palette.common.white,
                fontWeight: 600,
                letterSpacing: "0.5px",
                textShadow: "0 2px 4px rgba(0,0,0,0.2)",
              }}
            >
              צפו בסיכומי ההרצאות
            </Typography>


          </Box>

          {/* Loading State */}
          {isLoading && (
            <Box
              sx={{
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 9999,
                background: "rgba(10, 47, 92, 0.8)",
                backdropFilter: "blur(12px)",
              }}
            >
              <Card
                sx={{
                  background: "rgba(255, 255, 255, 0.1)",
                  backdropFilter: "blur(16px)",
                  padding: 4,
                  borderRadius: "20px",
                  border: "1px solid rgba(255, 255, 255, 0.15)",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: 2,
                  boxShadow: "0 8px 32px rgba(0, 0, 0, 0.2)",
                }}
              >
                <CircularProgress
                  size={80}
                  thickness={4}
                  sx={{
                    color: theme.palette.common.white,
                    opacity: 0.3,
                    filter: "drop-shadow(0 0 10px rgba(255,255,255,0.3))",
                  }}
                />
                <Typography
                  variant="h6"
                  sx={{
                    color: theme.palette.common.white,
                    opacity: 0.3,
                    textShadow: "0 2px 4px rgba(0,0,0,0.2)",
                  }}
                >
                  טוען את הסיכום...
                </Typography>
              </Card>
            </Box>
          )}

          <SelectLecture
            isTestMode={isTestMode}
            setIsLoading={setIsLoading}
            metadata={lectureMetadata}
            setLecture={setLecture}
            preSelectedLectureId={preSelectedLectureId}
          />
        </StyledPaper>

        {lecture && !isLoading && (
          <TranscriptionContentPaper>
            <ContentDisplay
              playbackRate={playbackRate}
              view={view}
              lecture={lecture}
              length={length}
              setView={setView}
              setLength={setLength}
              setLecture={setLecture}
            />
          </TranscriptionContentPaper>
        )}

        {fuse && (
          <SearchDialog
            open={searchOpen}
            onClose={() => setSearchOpen(false)}
            terms={terms}
            fuse={fuse}
            sessionId={sessionId}
          />
        )}
      </Box>
    </LayoutWrapper>
  );
};

export default TranscriptionLayout;

import { Box } from "@mui/material";
import { QuestionAnswer } from "../Constants/interface";
import QuestionAnswerSection from "./QuestionAnswerSection";
import QuestionCommentSection from "./QuestionCommentSection";

interface Prop {
  questionAnswer: QuestionAnswer;
}
const Solution = ({ questionAnswer }: Prop) => {
  return (
    <>
      <Box dir="rtl">
        <QuestionAnswerSection
          questionId={questionAnswer.questionID}
          answer={questionAnswer.answer}
          question={questionAnswer.question}
          votes={questionAnswer.votes}
        />
        <QuestionCommentSection
          questionNumber={questionAnswer.questionNumber}
          questionId={questionAnswer.questionID}
          initialComments={questionAnswer.Comments}
        />
      </Box>
    </>
  );
};

export default Solution;

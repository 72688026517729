import { addPostToDatabase } from "./addToDatabase";
import { BASE_DATABASE_URL, QUREIES, WEBSITE } from "../Constants/consts";
import { TagsSuggestion } from "../Constants/interface";

interface OpenAIResponse {
  choices: { message: { content: string } }[];
}

export const callAzureFunction = async (
  isGPT4: boolean,
  query: string,
  history: { role: string; content: string }[]
): Promise<string> => {
  const functionUrl =
    "https://api-query.azurewebsites.net/api/OldGetResponse?BAW8nJSMTLoCuKlr-KqpvOvccfEFEIEuSW9nkZFWqjOhAzFuI0b0jg==";
  console.log("called generate text with ", query);
  try {
    const response = await fetch(functionUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: query,
        isGPT4: isGPT4,
        conversationHistory: history,
      }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data: string = await response.json();
    console.log("Response from Azure Function:", data);

    return data;
  } catch (error) {
    console.error("Error calling Azure Function:", error);
    return "There was a problem";
  }
};

export const generateResponse = async (
  isGPT4: boolean,
  query: string,
  history: { role: string; content: string }[]
): Promise<string> => {
  const response = await callAzureFunction(isGPT4, query, history);
  return response;
};

export async function addTagsAndSubject(
  messageText: string
): Promise<TagsSuggestion> {
  console.log(messageText);
  try {
    const response = await fetch(`${BASE_DATABASE_URL}/create_label`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ chatHistory: messageText }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    console.log(response.body);
    const data = await response.json();
    const TagsSuggestion: TagsSuggestion = {
      mainSubject: data.main_subject,
      tags: data.tags,
    };
    console.log("Response from Azure Function:", data);

    return TagsSuggestion;
  } catch (error) {
    console.log("Azure OpenAI request failed:", error);
    return { mainSubject: "no", tags: [] };
  }
}

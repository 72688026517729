import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Box,
  Typography,
  Slider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  LinearProgress,
  TextField,
  InputAdornment,
  Tooltip,
} from "@mui/material";

import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import SearchIcon from "@mui/icons-material/Search";
import ImageIcon from "@mui/icons-material/Image";

import { Lecture, TranscriptionSegment } from "../Constants/interface";
import { logCustomEvent } from "../utils/Analytics";

export interface TranscriptionPlayerProps {
  transcription: Lecture;
  playbackRate?: number;
}

interface ChunkedSegment extends TranscriptionSegment {
  originalSegments: TranscriptionSegment[];
}

export function timeStringToSeconds(timeString: string): number {
  const [hours, minutes, seconds] = timeString.split(":").map(parseFloat);
  return hours * 3600 + minutes * 60 + seconds;
}

const TranscriptionPlayer: React.FC<TranscriptionPlayerProps> = ({
  transcription,
}) => {
  const [playbackRate, setPlaybackRate] = useState(1);
  const [totalDuration, setTotalDuration] = useState("");

  const [currentSegmentIndex, setCurrentSegmentIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [visibleChunks, setVisibleChunks] = useState<ChunkedSegment[]>([]);
  const [chunkSize, setChunkSize] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const itemsPerPage = 10;
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const listRef = useRef<HTMLDivElement | null>(null);
  const observer = useRef<IntersectionObserver | null>(null);

  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState<
    { index: number; matches: number[] }[]
  >([]);

  const searchInputRef = useRef<HTMLInputElement>(null);
  const [currentSearchIndex, setCurrentSearchIndex] = useState(-1);

  const [processedSegments, setProcessedSegments] = useState<
    TranscriptionSegment[]
  >([]);

  useEffect(() => {
    logCustomEvent("loaded_player", {
      subject: transcription.subject,
      transcriptionName: transcription.name,
    });
  }, [transcription]);

  useEffect(() => {
    console.log(
      "TranscriptionPlayer, transcription: ",
      transcription.audioFileId
    );
    if (
      transcription.audioFileId &&
      !transcription.audioFileId.endsWith(".mp3")
    ) {
      transcription.audioFileId += ".mp3";
    }
    const lastSegment =
      transcription.TranscriptionText?.[
        transcription.TranscriptionText.length - 1
      ];
    setTotalDuration(lastSegment?.end || "0");
    // convert start and end to
  }, [transcription]);

  useEffect(() => {
    if (transcription.TranscriptionText) {
      const processed = transcription.TranscriptionText.map((segment) => ({
        ...segment,
        start: segment.start,
        end: segment.end,
      }));
      setProcessedSegments(processed);

      const chunkedSegments = createChunkedSegments(processed, chunkSize);
      setVisibleChunks(chunkedSegments.slice(0, itemsPerPage));
      setHasMore(chunkedSegments.length > itemsPerPage);
    }
  }, [transcription.TranscriptionText, chunkSize]);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.playbackRate = playbackRate;
    }
  }, [playbackRate]);

  useEffect(() => {
    const handleTimeUpdate = () => {
      if (audioRef.current) {
        const newCurrentTime = audioRef.current.currentTime;
        setCurrentTime(newCurrentTime);

        const newIndex = visibleChunks.findIndex(
          (chunk, index) =>
            newCurrentTime >= Number(chunk.start) &&
            (index === visibleChunks.length - 1 ||
              newCurrentTime < Number(visibleChunks[index + 1].start))
        );

        if (newIndex !== -1 && newIndex !== currentSegmentIndex) {
          setCurrentSegmentIndex(newIndex);
        }
      }
    };

    const audioElement = audioRef.current;
    audioElement?.addEventListener("timeupdate", handleTimeUpdate);

    return () => {
      audioElement?.removeEventListener("timeupdate", handleTimeUpdate);
    };
  }, [visibleChunks]);

  const getTranscriptionSegments = useCallback((): TranscriptionSegment[] => {
    return processedSegments;
  }, [processedSegments]);

  const handleSearch = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const term = event.target.value.trim();
      setSearchTerm(term);
      if (term === "") {
        setSearchResults([]);
        setCurrentSearchIndex(-1);
        return;
      }

      const segments = getTranscriptionSegments();
      const results = segments.reduce(
        (acc: { index: number; matches: number[] }[], segment, index) => {
          const matches: number[] = [];
          let match;
          const searchRegex = new RegExp(escapeRegExp(term), "gi");

          while ((match = searchRegex.exec(segment.text)) !== null) {
            matches.push(match.index);
          }

          if (matches.length > 0) {
            acc.push({ index, matches });
          }
          return acc;
        },
        []
      );

      setSearchResults(results);
      setCurrentSearchIndex(results.length > 0 ? 0 : -1);
    },
    [processedSegments]
  );

  // Helper function to escape special characters in the search term
  const escapeRegExp = (string: string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  };
  /**
   * Jumps to the specified search result index.
   *
   * @param resultIndex - The index of the search result to jump to.
   */
  const jumpToSearchResult = (resultIndex: number) => {
    if (
      searchResults.length > 0 &&
      resultIndex >= 0 &&
      resultIndex < searchResults.length
    ) {
      console.log(
        "jumpToSearchResult, resultIndex: ",
        resultIndex,
        "searchResults: ",
        searchResults,
        "searchResults[resultIndex]: ",
        searchResults[resultIndex]
      );
      setCurrentSearchIndex(resultIndex);

      const targetSegmentIndex = searchResults[resultIndex].index;
      const chunkIndex = Math.floor(targetSegmentIndex / chunkSize);
      const startIndex = chunkIndex * chunkSize;
      const endIndex = Math.min(
        (chunkIndex + 1) * chunkSize,
        transcription.TranscriptionText?.length ?? 0
      );

      setVisibleChunks(
        createChunkedSegments(
          transcription.TranscriptionText?.slice(startIndex, endIndex) ?? [],
          chunkSize
        )
      );

      const indexWithinChunk = targetSegmentIndex % chunkSize;
      setCurrentSegmentIndex(targetSegmentIndex); // Changed this line

      console.log(
        "jumpToSearchResult, targetSegmentIndex: ",
        targetSegmentIndex,
        "indexWithinChunk: ",
        indexWithinChunk
      );

      setTimeout(() => {
        scrollToSegment(indexWithinChunk);
      }, 100); // Small delay to ensure the DOM has updated
    }
  };

  const jumpToNextResult = useCallback(() => {
    if (searchResults.length > 0) {
      const nextIndex = (currentSearchIndex + 1) % searchResults.length;
      jumpToSearchResult(nextIndex);
    }
  }, [searchResults.length, currentSearchIndex]);

  const jumpToPreviousResult = useCallback(() => {
    if (searchResults.length > 0) {
      const prevIndex =
        (currentSearchIndex - 1 + searchResults.length) % searchResults.length;
      jumpToSearchResult(prevIndex);
    }
  }, [searchResults.length, currentSearchIndex]);

  const scrollToSegment = (index: number) => {
    console.log("scrollToSegment, index: ", index);
    const element = document.getElementById(`segment-${index}`);
    console.log("scrollToSegment, element: ", element);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  const highlightText = (text: string, matches: number[]) => {
    if (matches.length === 0) return text;
    let result = [];
    let lastIndex = 0;

    const words = searchTerm.split(/\s+/);
    const useExactMatch = words.length > 1;

    const highlightRegex = useExactMatch
      ? new RegExp(escapeRegExp(searchTerm), "gi")
      : new RegExp(
          words.map((word) => `(${escapeRegExp(word)})`).join("|"),
          "gi"
        );

    matches.sort((a, b) => a - b);

    matches.forEach((matchIndex) => {
      result.push(text.slice(lastIndex, matchIndex));
      const remainingText = text.slice(matchIndex);
      const match = highlightRegex.exec(remainingText);
      if (match) {
        result.push(
          <span
            key={matchIndex}
            style={{ backgroundColor: "yellow", fontWeight: "bold" }}
          >
            {match[0]}
          </span>
        );
        lastIndex = matchIndex + match[0].length;
      }
    });
    result.push(text.slice(lastIndex));
    return result;
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (event.shiftKey) {
        jumpToPreviousResult();
      } else {
        jumpToNextResult();
      }
    }
  };

  const createChunkedSegments = (
    segments: TranscriptionSegment[],
    size: number
  ): ChunkedSegment[] => {
    const chunked: ChunkedSegment[] = [];
    for (let i = 0; i < segments.length; i += size) {
      const chunk = segments.slice(i, i + size);
      chunked.push({
        ...chunk[0],
        text: chunk.map((seg) => seg.text).join(" "),
        end: chunk[chunk.length - 1].end,
        originalSegments: chunk,
      });
    }
    return chunked;
  };

  const lastChunkRef = (node: HTMLLIElement) => {
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMore) {
        loadMoreChunks();
      }
    });
    if (node) observer.current.observe(node);
  };

  const loadMoreChunks = useCallback(() => {
    const currentLength = visibleChunks.length;
    const allChunkedSegments = createChunkedSegments(
      transcription.TranscriptionText ?? [],
      chunkSize
    );
    const nextChunks = allChunkedSegments.slice(
      currentLength,
      currentLength + itemsPerPage
    );

    if (nextChunks.length > 0) {
      setVisibleChunks((prev) => [...prev, ...nextChunks]);
      setHasMore(currentLength + nextChunks.length < allChunkedSegments.length);
    }
  }, [
    visibleChunks.length,
    transcription.TranscriptionText,
    chunkSize,
    itemsPerPage,
  ]);

  const parseTimeValue = (time: string | number): number => {
    if (typeof time === "string") {
      // If it's a timestamp string (HH:MM:SS)
      if (time.includes(":")) {
        return timeStringToSeconds(time);
      }
      // If it's a string number
      return parseFloat(time);
    }
    return time;
  };

  const togglePlayPause = (segment: ChunkedSegment, index: number) => {
    if (!audioRef.current) return;

    logCustomEvent("player_event", {
      label: segment.id,
      subject: transcription?.name || "",
      transcriptionName: transcription?.id || "",
      time: currentTime,
    });

    if (currentSegmentIndex !== index) {
      const startTime = parseTimeValue(segment.start);

      // Validate the time value before setting it
      if (isFinite(startTime) && startTime >= 0) {
        audioRef.current.currentTime = startTime;
        setCurrentSegmentIndex(index);
      } else {
        console.error("Invalid start time:", segment.start);
        return;
      }
    }

    if (audioRef.current.paused) {
      audioRef.current
        .play()
        .then(() => setIsPlaying(true))
        .catch((error) => console.error("Error playing audio:", error));
    } else {
      audioRef.current.pause();
      setIsPlaying(false);
    }
  };

  const handleSpeedChange = (increment: boolean) => {
    setPlaybackRate((prevRate) => {
      const newRate = increment ? prevRate + 0.25 : prevRate - 0.25;
      return Math.max(0.5, Math.min(5, Number(newRate.toFixed(2))));
    });
  };

  const calculateProgress = (chunk: ChunkedSegment | TranscriptionSegment, index: number) => {
    if (index === currentSegmentIndex) {
      const startTime = parseTimeValue(chunk.start);
      const endTime = parseTimeValue(chunk.end);
      const chunkDuration = endTime - startTime;
      const chunkProgress = currentTime - startTime;
      return (chunkProgress / chunkDuration) * 100;
    }
    return index < currentSegmentIndex ? 100 : 0;
  };

  useEffect(() => {
    const segments = getTranscriptionSegments();
    if (segments.length > 0) {
      const lastSegment = segments[segments.length - 1];
    }
  }, [processedSegments]);

  const handleChunkSizeChange = (event: Event, newValue: number | number[]) => {
    setChunkSize(newValue as number);
  };

  const formatTime = (seconds: number) => {
    const pad = (num: number) => num.toString().padStart(2, "0");
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = Math.floor(seconds % 60);
    return `${pad(hours)}:${pad(minutes)}:${pad(secs)}`;
  };

  useEffect(() => {
    const handleGlobalKeyPress = (event: KeyboardEvent) => {
      if (event.key === "Enter" && event.target !== searchInputRef.current) {
        event.preventDefault();
        if (event.shiftKey) {
          jumpToPreviousResult();
        } else {
          jumpToNextResult();
        }
      }
    };

    window.addEventListener("keydown", handleGlobalKeyPress);
    return () => window.removeEventListener("keydown", handleGlobalKeyPress);
  }, [jumpToNextResult, jumpToPreviousResult]);

  const renderListItems = useCallback(() => {
    const chunkStartIndex =
      Math.floor(currentSegmentIndex / chunkSize) * chunkSize;

    return visibleChunks
      .filter((segment) => !segment.originalSegments.some((seg) => seg.isImage)) // Filter out segments with path
      .map((segment, index) => {
        const isLastElement = index === visibleChunks.length - 1;
        const globalIndex = chunkStartIndex + index;
        const searchResult = searchResults.find(
          (result) => result.index === globalIndex
        );
        const isCurrentSearchResult =
          searchResults[currentSearchIndex]?.index === globalIndex;

        return (
          <ListItem
            ref={isLastElement ? lastChunkRef : null}
            id={`segment-${index}`}
            key={segment.id}
            sx={{
              bgcolor: isCurrentSearchResult
                ? "lightyellow" // Changed from "lightyellow" to "yellow" for more visibility
                : index === currentSegmentIndex
                ? "lightgray"
                : "inherit",
              display: "flex",
              flexDirection: "row-reverse",
              direction: "rtl",
              textAlign: "right",
            }}
          >
            <ListItemText
              primary={
                <Typography variant="body1">
                  {searchResult
                    ? highlightText(segment.text, searchResult.matches)
                    : segment.text}
                </Typography>
              }
              secondary={
                <>
                  {`${formatTime(parseTimeValue(segment.start))} - ${formatTime(
                    parseTimeValue(segment.end)
                  )}`}
                  {index === currentSegmentIndex && isPlaying && (
                    <LinearProgress
                      variant="determinate"
                      value={calculateProgress(segment, index)}
                    />
                  )}
                </>
              }
            />
            <IconButton
              onClick={() => togglePlayPause(segment, index)}
              size="small"
              sx={{
                marginLeft: "15px",
                backgroundColor: "rgb(66, 133, 244)",
                color: "white",
              }}
            >
              {(segment.originalSegments as any).some(
                (segment: any) => segment.path
              ) ? (
                <ImageIcon />
              ) : index === currentSegmentIndex && isPlaying ? (
                <PauseIcon />
              ) : (
                <PlayArrowIcon />
              )}
            </IconButton>
          </ListItem>
        );
      });
  }, [
    visibleChunks,
    currentSegmentIndex,
    chunkSize,
    searchResults,
    currentSearchIndex,
    isPlaying,
  ]);

  useEffect(() => {
    const options = {
      root: listRef.current,
      rootMargin: "20px",
      threshold: 0.1,
    };

    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMore) {
        loadMoreChunks();
      }
    }, options);

    return () => observer.current?.disconnect();
  }, [hasMore]); // Only recreate observer when hasMore changes

  return (
    <Box
      sx={{
        maxWidth: "1200px",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "calc(100vh - 64px)", // Adjust based on your header height
      }}
    >
      <Box
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 1,
          backgroundColor: "white",
          padding: 2,
          borderBottom: "1px solid #ddd",
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          alignItems: "center",
          gap: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: { xs: "100%", sm: "40%" },
          }}
        >
          <Typography variant="subtitle2" sx={{ marginRight: 1 }}>
            גודל משפט:
          </Typography>
          <Slider
            size="small"
            value={chunkSize}
            onChange={handleChunkSizeChange}
            aria-labelledby="chunk-size-slider"
            valueLabelDisplay="auto"
            step={3}
            marks
            min={1}
            max={24}
          />
        </Box>
        <Box
          sx={{
            width: { xs: "100%", sm: "60%" },
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="body2" sx={{ fontWeight: "bold", mx: 1 }}>
              מהירות השמעה:
            </Typography>
            <IconButton onClick={() => handleSpeedChange(false)} size="small">
              <RemoveIcon />
            </IconButton>
            <Typography variant="body2" sx={{ mx: 1 }}>
              {playbackRate.toFixed(2)}x
            </Typography>
            <IconButton onClick={() => handleSpeedChange(true)} size="small">
              <AddIcon />
            </IconButton>
          </Box>
          <Typography
            variant="body2"
            sx={{ display: { xs: "none", sm: "block" } }}
          >
            משך הרצאה: {totalDuration}
          </Typography>
        </Box>

        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <TextField
            fullWidth
            size="small"
            value={searchTerm}
            onChange={handleSearch}
            onKeyDown={handleKeyPress}
            placeholder="חיפוש בתמלול"
            inputRef={searchInputRef}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <Typography variant="body2">
            {searchResults.length > 0
              ? `${currentSearchIndex + 1}/${searchResults.length}`
              : "0/0"}
          </Typography>
          <Tooltip title="Previous (Shift+Enter)">
            <span>
              <IconButton
                onClick={jumpToPreviousResult}
                disabled={searchResults.length === 0}
              >
                <Typography>↑</Typography>
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Next (Enter)">
            <span>
              <IconButton
                onClick={jumpToNextResult}
                disabled={searchResults.length === 0}
              >
                <Typography>↓</Typography>
              </IconButton>
            </span>
          </Tooltip>
        </Box>
      </Box>

      <Box
        ref={listRef}
        sx={{
          flex: 1,
          overflowY: "auto",
          overflowX: "hidden",
          height: "auto", // Remove fixed height
          minHeight: 0, // Important for flex child
          "&::-webkit-scrollbar": {
            width: "8px",
          },
          "&::-webkit-scrollbar-track": {
            background: "#f1f1f1",
            borderRadius: "4px",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#888",
            borderRadius: "4px",
            "&:hover": {
              background: "#555",
            },
          },
        }}
      >
        <List
          sx={{
            width: "100%",
            bgcolor: "background.paper",
            padding: 0,
          }}
        >
          {processedSegments.map((segment, index) => (
            <ListItem
              key={segment.id}
              sx={{
                bgcolor: index === currentSegmentIndex
                  ? "lightgray"
                : index === currentSegmentIndex
                ? "lightgray"
                : "inherit",
              display: "flex",
              flexDirection: "row-reverse",
              direction: "rtl",
              textAlign: "right",
            }}><ListItemText
            primary={
              <Typography variant="body1">
                {searchResults.length > 0
                  ? highlightText(segment.text, searchResults[currentSearchIndex].matches)
                  : segment.isImage
                  ? `תמונה: ${segment.text}`
                  : segment.text}
              </Typography>
            }
            secondary={
              <>
                {`${formatTime(parseTimeValue(segment.start))} - ${formatTime(
                  parseTimeValue(segment.end)
                )}`}
                {index === currentSegmentIndex && isPlaying && (
                  <LinearProgress
                    variant="determinate"
                    value={calculateProgress(segment, index)}
                  />
                )}
              </>
            }
          />
          <IconButton
            onClick={() => togglePlayPause(segment as ChunkedSegment, index)}
            size="small"
            sx={{
              marginLeft: "15px",
              backgroundColor: "rgb(66, 133, 244)",
              color: "white",
            }}
          >
            {segment.isImage ? (
              <ImageIcon />
            ) : index === currentSegmentIndex && isPlaying ? (
              <PauseIcon />
            ) : (
              <PlayArrowIcon />
            )}
          </IconButton>
        </ListItem>
          ))}
        </List>

        {hasMore && (
          <Box
            ref={lastChunkRef}
            sx={{
              height: "20px",
              width: "100%",
              visibility: "hidden", // Hide the sentinel element
            }}
          />
        )}

        <audio ref={audioRef} src={transcription.audioFileId} />
      </Box>
    </Box>
  );
};

export default TranscriptionPlayer;

import {
  Lecture,
  TranscriptionSegment,
  Term,
  TranscriptMetadata,
  CommentResponse,
} from "../../Constants/interface";
import { CommentsResponse } from "../../Constants/interface";
// https://lecture-api-748614065639.us-central1.run.app
export const API_BASE_URL = "https://lecture-api-748614065639.us-central1.run.app";

export interface APIError {
  status: number;
  detail: string;
}

export const handleAPIError = (error: APIError) => {
  switch (error.status) {
    case 404:
      return "Resource not found";
    case 503:
      return "Service temporarily unavailable";
    default:
      return "An unexpected error occurred";
  }
};

// Add a helper function for converting types
export const convertLectureToMetadata = (
  lecture: Lecture
): TranscriptMetadata => {
  return {
    filename: lecture.id,
    name: lecture.name,
    subject: lecture.subject,
    audioSrc: lecture.audioFileId,
    id: lecture.id,
  };
};

export async function fetchLectureMetadata(): Promise<Lecture[]> {
  try {
    const response = await fetch(`${API_BASE_URL}/lectures/`);
    if (!response.ok) throw new Error("Failed to fetch lectures");
    return await response.json();
  } catch (error) {
    console.error("Error fetching lecture metadata:", error);
    return [];
  }
}

// Fetch basic lecture info without transcription/summary/terms
export async function fetchLectureBasicInfo(
  lectureId: string
): Promise<Lecture> {
  try {
    const response = await fetch(`${API_BASE_URL}/lectures/${lectureId}`);
    if (!response.ok) throw new Error("Failed to fetch lecture");
    return await response.json();
  } catch (error) {
    console.error("Error fetching lecture:", error);
    throw error;
  }
}

// Add response types for better type safety
interface TranscriptionResponse {
  segments: TranscriptionSegment[];
}


export interface SummaryResponse {
  fullSummary: string;
  keyPoints?: string[];
  metadata: Record<string, any>;
  versionHistory: any[];
  lectureId: string;
}

export interface TermsResponse {
  terms: Term[];
}

// Update fetch functions with specific return types
export async function fetchTranscription(
  lectureId: string
): Promise<TranscriptionResponse> {
  try {
    const response = await fetch(
      `${API_BASE_URL}/lectures/${lectureId}/transcription`
    );
    if (!response.ok) throw new Error("Failed to fetch transcription");
    return await response.json();
  } catch (error) {
    console.error("Error fetching transcription:", error);
    throw error;
  }
}

export async function fetchSummary(
  lectureId: string
): Promise<SummaryResponse> {
  try {
    const response = await fetch(
      `${API_BASE_URL}/lectures/${lectureId}/summary`
    );
    if (!response.ok) throw new Error("Failed to fetch summary");
    return await response.json();
  } catch (error) {
    console.error("Error fetching summary:", error);
    throw error;
  }
}

export async function fetchTerms(lectureId: string): Promise<TermsResponse> {
  try {
    const response = await fetch(`${API_BASE_URL}/lectures/${lectureId}/terms`);
    if (!response.ok) throw new Error("Failed to fetch terms");
    const data = await response.json();
    return {
      terms: data.terms.map((term: any) => ({
        ...term,
        term: term.term_english
          ? `${term.term_hebrew} (${term.term_english})`
          : term.term_hebrew,
        definition: term.explanation || "",
      })),
    };
  } catch (error) {
    console.error("Error fetching terms:", error);
    throw error;
  }
}

export async function searchLectures(params: {
  subject?: string;
  category?: string;
  name?: string;
}) {
  const queryParams = new URLSearchParams();
  Object.entries(params).forEach(([key, value]) => {
    if (value) queryParams.append(key, value);
  });

  const response = await fetch(
    `${API_BASE_URL}/lectures/search/?${queryParams.toString()}`
  );
  if (!response.ok) throw new Error("Search failed");
  return (await response.json()) as Lecture[];
}


// Add this new function
export async function fetchLectureComments(lectureId: string): Promise<CommentsResponse> {
  try {
    const response = await fetch(`${API_BASE_URL}/lectures/${lectureId}/comments`);
    if (!response.ok) throw new Error('Failed to fetch comments');
    return await response.json();
  } catch (error) {
    console.error('Error fetching comments:', error);
    throw error;
  }
}

// Add UUID generation function
function generateUUID(): string {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = Math.random() * 16 | 0;
    const v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

// Update the postLectureComment function to match the backend model
export async function postLectureComment(
  lectureId: string,
  comment: Omit<CommentResponse, 'id'>
): Promise<CommentResponse> {
  try {
    const commentWithId = {
      ...comment,
      id: generateUUID(),
      sectionId: null,
      commentedToAnother: null
    };

    const response = await fetch(`${API_BASE_URL}/lectures/${lectureId}/comments`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(commentWithId)
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.detail || 'Failed to post comment');
    }
    return await response.json();
  } catch (error) {
    console.error('Error posting comment:', error);
    throw error;
  }
}

import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fab,
  FormControl,
  InputLabel,
  Select,
  Skeleton,
  TextField,
  styled,
} from "@mui/material";
import { blinkAnimation, componentStyles } from "../styles";
import "katex/dist/katex.min.css";
import { Waves } from "./Waves";
import { addPostToDatabase } from "../ApiFunctions/addToDatabase";
import {
  BASE_DATABASE_URL,
  BASE_SITE_URL,
  LOCAL_SERVER_ADDRESS,
  SERVER_ADDRESS,
  SHARED_QUERY,
  WEBSITE,
} from "../Constants/consts";
import { MenuItem } from "@mui/material";
import {
  HistoryItem,
  SSEMessage,
  TagsSuggestion,
} from "../Constants/interface";
import useFetchSharedLogs from "../Hooks/useFetchSharedLogs";
import ChatDrawer from "./ChatDrawer";
import { Snackbar } from "@mui/material";
import { MarkdownTestComponent } from "../MarkdownTestComponent";
import { fetchFirstChat } from "../Hooks/FetchChat";
import { addTagsAndSubject } from "../ApiFunctions/generateResponse";
import ResponsiveAppBar from "../shared/AppBar";
import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { logCustomEvent, logEvent, logPageView } from "../utils/Analytics";
import Introduction from "./Introduction";
import { ChatBox } from "./ChatBox";
const getBackgroundByAlignment = (alignment: string, useGroq: boolean) => {
  if (useGroq) {
    return "linear-gradient(60deg, #0e1a01 0%, #00f9a9 100%)";
  }

  switch (alignment) {
    case "short":
      // Light, energetic blue - represents quick, concise answers
      return "linear-gradient(60deg, #1d78e0 0%, #1997f7 100%)";

    case "medium":
      // Balanced, professional blue - represents comprehensive answers
      return "linear-gradient(60deg, #2C5282 0%, #4299E1 100%)";

    case "long":
      // Deep, rich blue - represents thorough, detailed answers
      return "linear-gradient(60deg, #1A365D 0%, #2B6CB0 100%)";

    default:
      return "linear-gradient(60deg, #1A365D 0%, #2B6CB0 100%)";
  }
};

export const ChatMain: React.FC = () => {
  const [progress, setProgress] = React.useState(0);
  const [alignment, setAlignment] = useState("long");
  const [active, setActive] = useState(false);
  const [sessionId, setSessionId] = useState("not found");
  const [loadingShared, setLoadingShared] = useState(false);
  const [isSharedByID, setIsSharedByID] = useState("");

  const [showLogs, setShowLogs] = useState(false);
  const [saveResponse, setSaveResponse] = useState("yes");
  const [userAgent, setUserAgent] = useState<string | null>(null);
  const [ipData, setIpData] = useState<object>({});
  const [ip, setIp] = useState<string>("");
  const [cardRTL, setCardRTL] = useState(true);
  const [stopWaves, setStopWaves] = useState(false);

  const [responseReceived, setResponseReceived] = useState(false);
  const [partialResponse, setPartialResponse] = useState("");
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [conversationHistory, setConversationHistory] = useState<HistoryItem[]>(
    []
  );

  const [timer, setTimer] = useState(0);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [drawerOpen, setDrawerOpen] = useState(false);

  const [rating, setRating] = useState<number>(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const { chats, fetchSharedLogs } = useFetchSharedLogs(
    BASE_DATABASE_URL,
    SHARED_QUERY
  );
  const [openClearConfirm, setOpenClearConfirm] = useState(false);

  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [tags, setTags] = useState<TagsSuggestion>({
    mainSubject: "",
    tags: [],
  });
  const [newTag, setNewTag] = useState("");
  const [editSubject, setEditSubject] = useState("");

  const [userUpvotes, setUserUpvotes] = useState<Set<string>>(new Set());
  const [showTest, setShowTest] = useState(false);

  const [memeIndex, setMemeIndex] = useState(getRandomMemeIndex());
  const [useGroq, setUseGroq] = useState(false);

  function getRandomMemeIndex() {
    return Math.floor(Math.random() * 27); // Adjust to the number of your memes
  }

  useEffect(() => {
    logPageView();
    logEvent("load_chat", {
      category: "ChatMain",
      action: "Loaded",
    });
  }, []);

  const styles = componentStyles;

  function changeServerAddress(url: string): string {
    const urlParams = new URLSearchParams(new URL(url).search);
    let serverAddress = SERVER_ADDRESS;

    if (urlParams.get("local") === "true") {
      serverAddress = LOCAL_SERVER_ADDRESS;
    }

    return serverAddress;
  }

  const ServerAddress = changeServerAddress(window.location.href);

  const CustomSkeleton = styled(Skeleton)(({ theme }) => ({
    animation: `${blinkAnimation} 3s ease-in-out infinite`,
  }));

  // Event Handlers
  //ADD CONSOLE.LOGS TO ALL FUNCTIONS RIGHT HERE

  const handleNewMeme = () => {
    const newIndex = getRandomMemeIndex();
    console.log(newIndex);
    setMemeIndex(newIndex);
  };

  const handleFeedback = (index: number, isPositive: boolean) => {
    addPostToDatabase(
      {
        feedback: isPositive ? "positive" : "negative",
        sessionId: sessionId,
        ipData: ipData,
        query: query,
        conversationHistory: conversationHistory,
        response: partialResponse,
        userAgent: userAgent,
      },
      "Chats",
      WEBSITE
    );
    logCustomEvent("feedback", {
      label: isPositive ? "positive" : "negative",
      sessionId: sessionId,
      response: partialResponse,
      prompt: query,
    });
  };

  const handleAddTag = () => {
    console.log("Adding tag:", newTag);
    if (newTag) {
      setTags((prevTags) => ({
        ...prevTags,
        tags: [...prevTags.tags, newTag],
      }));
      setNewTag("");
    }
  };

  const handleDeleteTag = (tagToDelete: string) => {
    console.log("Deleting tag:", tagToDelete);
    setTags((prevTags) => ({
      ...prevTags,
      tags: prevTags.tags.filter((tag) => tag !== tagToDelete),
    }));
  };

  const handleChangeSubject = () => {
    if (editSubject) {
      setTags((prevTags) => ({ ...prevTags, mainSubject: editSubject }));
      setEditSubject("");
    }
  };

  const handleClickOpen = () => {
    setOpenClearConfirm(true);
  };

  const handleClose = () => {
    setOpenClearConfirm(false);
  };

  const handleClearConfirm = () => {
    clearChatHistory();
    handleClose();
  };

  const handleShare = async () => {
    const updateResponse = await fetch(
      `${BASE_DATABASE_URL}/update_post?container=Chats&itemId=${sessionId}`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          WhatsappShared: true,
        }),
      }
    );
    logEvent("share_chat", {
      category: "ChatMain",
      action: "Chat Shared",
      label: sessionId,
    });
    const postUrl = `${BASE_SITE_URL}${sessionId}`;

    if (window.innerWidth < 500) {
      const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(postUrl)}`;
      window.open(whatsappUrl, "_blank");
    } else {
      await copyToClipboard(postUrl);
      setSnackbarMessage(`קישור לשיחה הועתק!`);
      setSnackbarOpen(true);
    }
  };

  const handleRatingSubmit = () => {
    let elementBeforeLast = { content: "" };
    if (conversationHistory.length >= 2) {
      // Ensure the array has at least two elements
      elementBeforeLast = conversationHistory[conversationHistory.length - 2];
    }
    //SaveToDatabase(timer.toFixed(2), elementBeforeLast.content, alignment === 'gpt4', conversationHistory, partialResponse, rating);
    setResponseReceived(false);
    setSnackbarMessage(`הדירוג ${rating} כוכבים נשמר בהצלחה!`);
    setSnackbarOpen(true);
  };

  const handleQueryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
  };

  const handleAligmentChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    console.log(newAlignment);
    setAlignment(newAlignment);
  };

  const clearChatHistory = () => {
    setConversationHistory([]);
  };

  function handleQuerySubmit() {
    setProgress(0);
    setLoading(true);
    handleNewMeme();
    setActive((prev) => true);
    logCustomEvent("submit_query", {
      query,
    });
    sendAndGetResponse();
  }

  const handleChatLoad = (chatId: string) => {
    const selectedChat = chats.find((chat) => chat.id === chatId);
    if (selectedChat) {
      setConversationHistory(selectedChat.chatHistory);
      setDrawerOpen(false); // Close the drawer after selection
      logEvent("load_chat", {
        category: "ChatMain",
        action: "Chat Loaded",
        label: chatId,
      });
    } else {
      console.error("Chat not found");
    }
  };

  const toggleDrawer =
    (open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent | React.TouchEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setDrawerOpen(open);
    };

  // Use Effects

  //Get user Info
  useEffect(() => {
    const userAgent = navigator.userAgent;
    fetch("https://ipinfo.io/json")
      .then((response) => response.json())
      .then((data) => {
        if (saveResponse == "yes") {
          addPostToDatabase(
            { data: data, userAgent: userAgent },
            "userVisits",
            WEBSITE
          );
        }
        setIpData(data);
        setIp(data.city);
        setUserAgent(userAgent);
      })
      .catch((error) => console.error("Error fetching IP:", error));
  }, []);

  //Fetch Logs for saved Conversations
  useEffect(() => {
    fetchSharedLogs();
  }, [fetchSharedLogs, snackbarMessage]);

  //Read URL Params
  useEffect(() => {
    const fetchChat = async () => {
      const searchParams = new URLSearchParams(window.location.search);
      const showlogs = searchParams.get("type");
      const chatId = searchParams.get("id");
      const save_response = searchParams.get("save");
      const testMarkdown = searchParams.get("testMarkdown");
      if (showlogs === "showlogs") {
        setShowLogs(true);
      }

      if (chatId) {
        setLoadingShared(true);
        setIsSharedByID(chatId);

        // Make sure to adjust BASE_DATABASE_URL and your query appropriately
        try {
          const chat = await fetchFirstChat(
            BASE_DATABASE_URL,
            `SELECT * FROM c WHERE c.sessionId = '${chatId}'`
          );
          if (chat) {
            setConversationHistory(chat.chatHistory);
          }
          setLoadingShared(false);
        } catch (error) {
          console.error("Failed to fetch chat:", error);
          setLoadingShared(false);

          // Handle the error appropriately
        }
      }

      if (save_response === "no") {
        setSaveResponse("no");
      }
      if (testMarkdown === "true") {
        setShowTest(true);
      }
    };

    fetchChat();
  }, []);

  //Submit the rating
  useEffect(() => {
    if (rating > 0) {
      // Check to ensure a rating has been set
      handleRatingSubmit(); // Call the submission function when the rating changes
    }
  }, [rating]); // Add rating as a dependency

  //Start the timer
  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (loading) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer + 0.1);
        setProgress((oldProgress) => {
          const diff = Math.random() * 17;
          return Math.min(oldProgress + diff, 100);
        });
      }, 100);
    }
    return () => clearInterval(interval);
  }, [loading]);

  const streamResponse = async (answerStyle: string, query: string) => {
    const prompt = query;
    logCustomEvent("stream_response_start", {
      answerStyle,
      prompt,
      sessionId,
    });

    let finalResponse = ``;

    if (userAgent == null) {
      await fetch("https://ipinfo.io/json")
        .then((response) => response.json())
        .then((data) => {
          if (saveResponse == "yes") {
            addPostToDatabase(
              {
                data: data,
                userAgent: userAgent,
              },
              "userVisits",
              WEBSITE
            );
          }
          setIpData(data);
          setIp(data.ip);
          setUserAgent(userAgent);
        })
        .catch((error) => console.error("Error fetching IP:", error));
    }

    const queryParams = new URLSearchParams({
      sharedFromId: isSharedByID ? isSharedByID : "not shared",
      saveResponse: saveResponse,
      prompt: query,
      answerStyle: answerStyle,
      sessionId: sessionId,
      history: JSON.stringify([{}]),
      useGroq: useGroq ? "true" : "",
      useClaude: "",
      ipData: JSON.stringify(ipData),
      userAgent: userAgent ?? "no UserAgent",
    });

    if (ip == "Badnei Brak") {
      return "";
    }

    const sseUrl = `${ServerAddress}/getResponse?${queryParams.toString()}`;
    const sse = new EventSource(sseUrl);
    console.log("SSE URL:", sseUrl);
    console.log(JSON.stringify(sse));
    sse.addEventListener("message", ({ data }) => {
      try {
        console.log(JSON.parse(data));
        const message: SSEMessage = JSON.parse(data);
        if (message.sessionId && sessionId == "not found") {
          setSessionId(message.sessionId);
        }

        finalResponse += message.content;
        setPartialResponse((prevResponse) => prevResponse + message.content);
      } catch (error) {
        console.error("Error parsing SSE data:", error);
      }
    });
    sse.addEventListener("error", (e: any) => {
      sse.close();
      console.error("SSE error:", e);
      console.log("SSE readyState:", e.target.readyState);
      console.log("SSE URL:", e.target.url);
      console.error("SSE error:", e);
      // Update conversation history with the final response.
      setConversationHistory((prevHistory) => [
        ...prevHistory,
        { role: "system", content: finalResponse },
      ]);
      setResponseReceived(true);
      setLoading(false);
      setActive(false);
      logCustomEvent("model_response", {
        category: "ChatMain",
        action: "Response Received",
        label: finalResponse.substring(0, 100), // First 100 characters as label
        prompt: query,
        answerStyle: answerStyle,
        sessionId: sessionId,
        model_answer: finalResponse,
        history: JSON.stringify(conversationHistory),
      });
    });

    return () => sse.close(); // Cleanup function to close the SSE connection.
  };

  const sendAndGetResponse = async () => {
    try {
      setConversationHistory((prevHistory) => [
        ...prevHistory,
        { role: "user", content: query },
      ]);

      const fullResponse = await streamResponse(alignment, query);
      setPartialResponse(""); // Clear old partial responses
      setQuery(""); // Clear input field
    } catch (error) {
      logEvent("response_error", {
        category: "ChatMain",
        action: "Response Error",
      });
      console.error("Error in streaming response:", error);
    } finally {
      setResponseReceived(true);
    }
  };

  const updateShared = async (tags: TagsSuggestion) => {
    const updateResponse = await fetch(
      `${BASE_DATABASE_URL}/update_post?container=Chats&itemId=${sessionId}`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          shared: true,
          tags: tags,
        }),
      }
    );
  };

  const copyToClipboard = async (text: string) => {
    if ("clipboard" in navigator) {
      try {
        await navigator.clipboard.writeText(text);
      } catch (err) {
        console.error("Failed to copy: ", err);
      }
    } else {
      // Clipboard API not available, you may want to fallback to a more manual method
      console.log("Clipboard API not available");
    }
  };

  const addToUpvote = async (
    chatId: string,
    currentUpvotes: number,
    isUpvoted: boolean
  ) => {
    const newUpvotes = isUpvoted ? currentUpvotes - 1 : currentUpvotes + 1;
    console.log(newUpvotes);

    const updateResponse = await fetch(
      `${BASE_DATABASE_URL}/update_post?container=Chats&itemId=${chatId}`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          upvotes: newUpvotes,
        }),
      }
    );
    console.log(updateResponse);

    if (updateResponse.ok) {
      fetchSharedLogs();
      setUserUpvotes((prevUpvotes) => {
        const updatedUpvotes = new Set(prevUpvotes);
        if (isUpvoted) {
          updatedUpvotes.delete(chatId);
        } else {
          updatedUpvotes.add(chatId);
        }
        return updatedUpvotes;
      });
    } else {
      console.error("Failed to update upvotes");
    }
  };

  const addToSharedChat = async () => {
    await updateShared(tags);
    setShareDialogOpen(false);
    setTags({ mainSubject: "", tags: [] });
    setResponseReceived(false);

    setSnackbarMessage(`השיחה נשמרה בהצלחה!`);
    setSnackbarOpen(true);
  };

  const addToDrawer = async () => {
    setLoadingShared(true);

    const fetchedTags: TagsSuggestion = await addTagsAndSubject(
      JSON.stringify(conversationHistory)
    );

    setTags(fetchedTags);
    setLoadingShared(false);

    setShareDialogOpen(true);
  };

  const renderTest = () => {
    return <MarkdownTestComponent />;
  };

  return (
    <>
      <Box
        sx={{
          ...styles.mainBox,
          background: getBackgroundByAlignment(alignment, useGroq),
          transition: "background 1.5s ease",
        }}
      >
        <ResponsiveAppBar />
        <Box
          sx={{
            ...styles.innerBox,
          }}
        >
          <ChatDrawer
            userUpvotes={userUpvotes}
            addToUpvote={addToUpvote}
            drawerOpen={drawerOpen}
            toggleDrawer={toggleDrawer}
            chats={chats}
            handleChatLoad={handleChatLoad}
          />

          <Introduction />

          {loadingShared && (
            <Box
              sx={{
                position: "fixed", // Use fixed to keep it centered regardless of scrolling
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: 5000,
              }}
            >
              <CircularProgress color="warning" />
            </Box>
          )}

          <ChatBox
            useGroq={useGroq}
            loading={loading}
            progress={progress}
            active={active}
            partialResponse={partialResponse}
            timer={timer}
            conversationHistory={conversationHistory}
            cardRTL={cardRTL}
            memeIndex={memeIndex}
            alignment={alignment}
            query={query}
            sessionId={sessionId}
            handleFeedback={handleFeedback}
            handleAligmentChange={handleAligmentChange}
            handleShare={handleShare}
            handleClickOpen={handleClickOpen}
            setTimer={setTimer}
            onQueryChange={handleQueryChange}
            onQuerySubmit={handleQuerySubmit}
            toggleDrawer={toggleDrawer}
            addToDrawer={addToDrawer}
          />

          <Dialog
            open={openClearConfirm}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle sx={{ direction: "rtl" }} id="alert-dialog-title">
              {"בטוח שאתה רוצה לנקות?"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText
                sx={{ direction: "rtl" }}
                id="alert-dialog-description"
              >
                אחרי שתעשה דבר כזה, אין לי איך לעזור ולהחזיר את הצ'אט שלך
              </DialogContentText>
              <DialogContentText
                sx={{ direction: "rtl" }}
                id="alert-dialog-description"
              >
                במידה וטענת צ'אט חיצוני, הוא לא יעלם אבל והשינויים שביצעת לא
                ישמרו ��ו.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClearConfirm} autoFocus>
                נקה צ'אט
              </Button>
              <Button onClick={handleClose}>בטל</Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={shareDialogOpen}
            onClose={() => setShareDialogOpen(false)}
          >
            <DialogTitle sx={{ direction: "rtl" }}>שמור שיחה</DialogTitle>
            <DialogContent sx={{ direction: "rtl" }}>
              <DialogContentText sx={{ direction: "rtl" }}>
                ,הצ'אט יהיה גלוי לכל המשתמשים שילחצו על שיחות שמורות המערכת
                הציעה אוטומטית נושא ותגיות - אבל תרגישו חופשיים לשנות אותם
              </DialogContentText>

              <DialogContentText sx={{ direction: "rtl" }}></DialogContentText>
              <DialogContentText sx={{ direction: "rtl" }}></DialogContentText>
              <Box>
                <Box
                  sx={{ m: "15px", fontWeight: "bold", borderRadius: "4px" }}
                >
                  נושא השאלה המוצע:
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <Chip
                    sx={{ margin: "5px" }}
                    color="primary"
                    label={tags.mainSubject}
                  />

                  <FormControl sx={{ m: 1 }}>
                    <InputLabel id="edit-subject-label">
                      בחר נושא אחר
                    </InputLabel>
                    <Select
                      labelId="edit-subject-label"
                      id="edit-subject-select"
                      value={tags.mainSubject} // Bind directly to tags.mainSubject for instant update
                      label="בחר נושא אחר"
                      onChange={(e) =>
                        setTags((prevTags) => ({
                          ...prevTags,
                          mainSubject: e.target.value,
                        }))
                      }
                      sx={{ width: "150px" }}
                    >
                      {[
                        "סטטיסטיקה",
                        "כימיה אורגנית",
                        "כימיה אנליטית",
                        "ביופיזיקה",
                        "ביוכימיה",
                      ].map((subject) => (
                        <MenuItem key={subject} value={subject}>
                          {subject}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <Box
                  sx={{ m: "15px", fontWeight: "bold", borderRadius: "4px" }}
                >
                  תגיות מוצעות:
                </Box>
                {tags.tags.map((tag, index) => (
                  <Chip
                    key={index}
                    sx={{ margin: "5px" }}
                    label={tag}
                    variant="outlined"
                    onDelete={() => handleDeleteTag(tag)}
                  />
                ))}
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <TextField
                    size="small"
                    label="הצע תגית חדשה"
                    value={newTag}
                    onChange={(e) => setNewTag(e.target.value)}
                    variant="outlined"
                    sx={{ mt: 2, width: "150px" }}
                  />
                  <Button sx={{ width: "75px" }} onClick={handleAddTag}>
                    הוסף
                  </Button>
                </Box>
              </Box>
            </DialogContent>
            <DialogActions sx={{ direction: "rtl" }}>
              <Button onClick={() => setShareDialogOpen(false)}>בטל</Button>
              <Button variant="contained" onClick={addToSharedChat}>
                שתף
              </Button>
            </DialogActions>
          </Dialog>

          <Snackbar
            sx={{ direction: "rtl", zIndex: 9999 }}
            open={snackbarOpen}
            autoHideDuration={2500}
            onClose={() => setSnackbarOpen(false)}
            message={snackbarMessage}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          />
        </Box>
        <Waves
          waveSpeed={
            stopWaves
              ? 0
              : alignment === "short"
              ? 3.5
              : alignment === "medium"
              ? 6
              : 9
          }
        />
        <Fab
          color="primary"
          aria-label="speed"
          size="small"
          sx={{
            position: "fixed",
            opacity: 0.3,
            bottom: 8, // Reduced from 10
            left: 8, // Reduced from 10
            width: 25, // Added explicit small width
            height: 25, // Added explicit small height
            minHeight: "unset", // Override default minimum height
            "& .MuiSvgIcon-root": {
              fontSize: "1rem", // Smaller icon
            },
            "&:hover": {
              opacity: 0.6,
            },
          }}
          onClick={() => {
            setCardRTL((prev) => !prev);
          }}
        >
          {cardRTL ? <KeyboardArrowRightIcon /> : <KeyboardArrowLeftIcon />}
        </Fab>
        <Fab
          size="small"
          color="primary"
          aria-label="speed"
          sx={{
            position: "fixed",
            opacity: 0.7,
            bottom: 8, // Reduced from 10
            left: 40, // Adjusted from 60 to maintain proportional spacing
            width: 25, // Added explicit small width
            height: 25, // Added explicit small height
            minHeight: "unset", // Override default minimum height
            "& .MuiSvgIcon-root": {
              fontSize: "1rem", // Smaller icon
            },
            "&:hover": {
              opacity: 0.9,
            },
          }}
          onClick={() => {
            setStopWaves((prev) => !prev);
          }}
        >
          {stopWaves ? <PlayArrowIcon /> : <PauseIcon />}
        </Fab>
      </Box>

      {showTest && <MarkdownTestComponent />}
    </>
  );
};

export default ChatMain;

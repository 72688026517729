import React, { useState, useEffect } from 'react';
import {
    Box,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Dialog,
    DialogTitle,
    DialogContent,
    Typography,
    Chip,
    CircularProgress,
    Collapse,
    IconButton,
    Button,
} from '@mui/material';
import { Lecture, Chunk, ImageObject } from '../Constants/interface';
import { fetchSummary, SummaryResponse } from '../Transcription/Services/firebaseService';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import Markdown from '../Transcription/Markdown';
import { createStyles } from '../Transcription/styles';
import { ImageGallery } from './ChatBox';
import CloseIcon from '@mui/icons-material/Close';

interface CombinedLectureData extends SummaryResponse {
    name: string;
    subject?: string;
    category?: "MEDICAL" | "NON_MEDICAL" | "UNKNOWN";
    subFolder?: string;
    location?: string;
}

interface LectureReferenceProps {
    chunks: Chunk[];
    images: ImageObject[];
}

interface LectureSummaryDialogProps {
    lecture: CombinedLectureData | null;
    open: boolean;
    onClose: () => void;
    onNavigate?: (location: string) => void;
}

const extractLastHeader = (location: string): string | null => {
    console.log('location', location);
    const paths = location.split('/');
    // Get the last header (most specific one)
    const lastHeader = paths[paths.length - 1];
    if (!lastHeader) return null;

    // Extract the header text without the timestamp
    const match = lastHeader.match(/(.*?)\s*\(\d{2}:\d{2}:\d{2}/);
    return match ? match[1].trim() : null;
};

const findHeaderElement = (headerText: string): HTMLElement | null => {
    const headers = document.querySelectorAll('h1, h2, h3, h4, h5, h6');

    for (const header of Array.from(headers)) {
        if (header.textContent?.includes(headerText)) {
            console.log('headerText', headerText);
            return header as HTMLElement;
        }
    }
    console.log('headerText', headerText);
    return null;
};

const LectureSummaryDialog: React.FC<LectureSummaryDialogProps> = ({
    lecture,
    open,
    onClose,
    onNavigate
}) => {
    const [fontSize, setFontSize] = useState(1);
    const [showImages, setShowImages] = useState(true);
    const styles = createStyles(fontSize, true);

    // Auto-navigate when dialog opens, wait for 1 second before navigating for the context to load
    useEffect(() => {
        if (open && lecture?.location) {
            const headerText = extractLastHeader(lecture.location);
            console.log('navigate to lecture.location', lecture.location);
            console.log('headerText', headerText);
            setTimeout(() => {
                if (headerText) {
                    onNavigate?.(headerText);
                }
            }, 500);
        }
    }, [open, lecture?.location]);

    if (!lecture) return null;

    const handleImageClick = (src: string) => {
        window.open(src, '_blank');
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="md"
            fullWidth
            PaperProps={{
                sx: {
                    borderRadius: 2,
                    bgcolor: 'background.paper',
                    maxHeight: '90vh',
                    direction: 'rtl'
                }
            }}
        >
            <DialogTitle sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                direction: 'rtl'
            }}>
                <Typography variant="h6" sx={{ flex: 1 }}>{lecture.name}</Typography>
                <MenuBookIcon />
                <IconButton
                    edge="end"
                    color="inherit"
                    onClick={onClose}
                    aria-label="close"
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Box sx={{ mb: 2, direction: 'rtl', textAlign: 'right' }}>
                    {lecture.subject && <Chip
                        label={lecture.subject}
                        color="primary"
                        size="small"
                        sx={{ ml: 1 }}
                    />}
                    {lecture.category && <Chip
                        label={lecture.category}
                        color="secondary"
                        size="small"
                        sx={{ ml: 1 }}
                    />}
                    {lecture.subFolder && <Chip
                        label={lecture.subFolder}
                        color="secondary"
                        size="small"
                    />}
                </Box>

                <Box sx={{ maxHeight: '60vh', overflow: 'auto', direction: 'rtl', textAlign: 'right' }}>
                    <Markdown
                        fontSize={fontSize}
                        isLongForm={true}
                        handleImageClick={handleImageClick}
                        showImages={showImages}
                        isTocOpen={false}
                    >
                        {lecture.fullSummary || 'Loading summary...'}
                    </Markdown>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

const LectureReference: React.FC<LectureReferenceProps> = ({ chunks, images }) => {
    const [expanded, setExpanded] = useState(true);
    const [selectedLecture, setSelectedLecture] = useState<CombinedLectureData | null>(null);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [lectureCache, setLectureCache] = useState<Record<string, CombinedLectureData>>({});
    const [loading, setLoading] = useState<Record<string, boolean>>({});

    const uniqueLectures = chunks.reduce((acc, chunk) => {
        if (!acc[chunk.lecture_id]) {
            acc[chunk.lecture_id] = chunk;
        }
        return acc;
    }, {} as Record<string, Chunk>);

    const handleLectureClick = async (lectureId: string) => {
        setDialogOpen(true);

        if (lectureCache[lectureId]) {
            setSelectedLecture(lectureCache[lectureId]);
            return;
        }

        try {
            setLoading(prev => ({ ...prev, [lectureId]: true }));
            const summaryData = await fetchSummary(lectureId);
            const dataChunk = chunks.find(chunk => chunk.lecture_id === lectureId);

            const combinedData: CombinedLectureData = {
                ...summaryData,
                name: dataChunk?.lecture_name || '',
                subject: dataChunk?.subject,
                subFolder: dataChunk?.subfolder,
                location: dataChunk?.location
            };

            setLectureCache(prev => ({ ...prev, [lectureId]: combinedData }));
            setSelectedLecture(combinedData);
        } catch (error) {
            console.error('Error fetching lecture:', error);
        } finally {
            setLoading(prev => ({ ...prev, [lectureId]: false }));
        }
    };

    const handleNavigate = (headerText: string) => {
        console.log('headerText to search for', headerText);
        const element = findHeaderElement(headerText);
        if (element) {
            console.log('element', element);
            // Expand any collapsed sections
            const expandParents = () => {
                let currentElement = element;
                const headersToExpand: string[] = [];

                while (currentElement && currentElement.parentElement) {
                    const parentHeader = currentElement.closest('[data-header-level]');
                    if (parentHeader && parentHeader.classList.contains('collapsed')) {
                        headersToExpand.push(parentHeader.id);
                    }
                    currentElement = currentElement.parentElement;
                }

                // Expand headers from top to bottom
                headersToExpand.reverse().forEach((headerId) => {
                    const headerElement = document.getElementById(headerId);
                    if (headerElement) {
                        const headerButton = headerElement.querySelector('button');
                        if (headerButton) {
                            headerButton.click();
                        }
                    }
                });
            };

            expandParents();

            // Wait for DOM updates after expanding sections
            setTimeout(() => {
                // Add highlight class
                element.classList.add('highlight-header');

                // Scroll into view
                element.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center'
                });

                // Create highlight overlay
                const overlay = document.createElement('div');
                overlay.style.position = 'absolute';
                overlay.style.top = '0';
                overlay.style.left = '0';
                overlay.style.right = '0';
                overlay.style.bottom = '0';
                overlay.style.backgroundColor = 'rgba(255, 255, 0, 0.2)';
                overlay.style.pointerEvents = 'none';
                overlay.style.animation = 'fadeOut 2s forwards';

                const elementPosition = element.getBoundingClientRect();
                overlay.style.width = `${elementPosition.width}px`;
                overlay.style.height = `${elementPosition.height}px`;

                element.style.position = 'relative';
                element.appendChild(overlay);

                // Cleanup
                setTimeout(() => {
                    element.classList.remove('highlight-header');
                    if (element.contains(overlay)) {
                        element.removeChild(overlay);
                    }
                }, 2000);
            }, 300);
        }
    };

    if (Object.keys(uniqueLectures).length === 0) return null;

    return (
        <Box sx={{
            width: '100%',
            mb: 2,
            backgroundColor: 'rgba(255, 255, 255, 0.08)',
            borderRadius: 2,
            border: '1px solid rgba(255, 255, 255, 0.12)',
        }}>
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                p: 1.5,
                borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
            }}>
                <Typography
                    variant="subtitle2"
                    sx={{
                        color: 'white',
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        fontSize: '0.9rem'
                    }}
                >
                    <MenuBookIcon sx={{ fontSize: '1.1rem' }} />
                    מקורות רלוונטיים ({Object.keys(uniqueLectures).length})
                </Typography>
                <IconButton
                    size="small"
                    onClick={() => setExpanded(!expanded)}
                    sx={{ color: 'white' }}
                >
                    {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
            </Box>

            <Collapse in={expanded}>
                <Box sx={{
                    p: 1,
                    direction: 'rtl',
                    display: 'flex',
                    justifyContent: 'center'
                }}>
                    <ImageGallery images={images} />
                </Box>
                <List sx={{
                    p: 1,
                    direction: 'rtl',
                    maxHeight: '200px',
                    overflowY: 'auto'
                }}>

                    {Object.values(uniqueLectures).map((chunk) => (
                        <ListItem
                            key={chunk.lecture_id}
                            disablePadding
                            sx={{ mb: 1, direction: 'rtl', textAlign: 'right' }}
                        >
                            <ListItemButton
                                onClick={() => handleLectureClick(chunk.lecture_id)}
                                sx={{
                                    bgcolor: 'rgba(255, 255, 255, 0.05)',
                                    borderRadius: 1,
                                    '&:hover': {
                                        bgcolor: 'rgba(255, 255, 255, 0.1)',
                                    },
                                }}
                            >
                                <ListItemText
                                    primary={
                                        <Typography sx={{ color: 'white', direction: 'rtl', textAlign: 'right' }}>
                                            {chunk.lecture_name}
                                        </Typography>
                                    }
                                    secondary={
                                        <Typography variant="body2" sx={{ color: 'rgba(255, 255, 255, 0.7)', direction: 'rtl', textAlign: 'right' }}>
                                            {chunk.subject} • {chunk.subfolder}
                                        </Typography>
                                    }
                                />
                                {loading[chunk.lecture_id] && (
                                    <CircularProgress size={20} sx={{ ml: 2 }} />
                                )}
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </Collapse>

            <LectureSummaryDialog
                lecture={selectedLecture}
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                onNavigate={handleNavigate}
            />
        </Box>
    );
};

// Update the styles to include collapsed state
const styles = `
    @keyframes pulse {
        0% { opacity: 1; }
        50% { opacity: 0.5; }
        100% { opacity: 1; }
    }

    @keyframes fadeOut {
        from { opacity: 0.5; }
        to { opacity: 0; }
    }

    .highlight-header {
        position: relative;
        z-index: 1;
    }

    .collapsed {
        display: none;
    }
`;

export { LectureSummaryDialog };
export default LectureReference;
import { useState, useCallback } from 'react';
import { HistoryItem, SharedLogEntry, Chat } from '../Constants/interface';

export const fetchFirstChat = async (baseUrl: string, query: string): Promise<Chat | undefined> => {
    const url = `${baseUrl}/get_posts?container=Chats&query=${encodeURIComponent(query)}`;
    try {
        const response = await fetch(url);
        const data: SharedLogEntry[] = await response.json();
        if (data.length === 0) return undefined; // Early return if no data is found

        const firstLog = data[0];
        let chatHistory = firstLog.chatHistory;
        if (typeof chatHistory === 'string') {
            chatHistory = JSON.parse(chatHistory);
        }
        const formattedFirstChat: Chat = {
            id: firstLog.id,
            headline: firstLog.headline,
            upvotes: firstLog.upvotes,
            tags: firstLog.tags,
            labels: firstLog.labels,
            shared: firstLog.shared,
            sessionId: firstLog.sessionId,
            metadata: firstLog.metadata,
            chatHistory: chatHistory.map((item: HistoryItem) => ({
                role: item.role,
                content: item.content,
                definition: item.definitions ?? ""
            }))
        };
        return formattedFirstChat;
    } catch (error) {
        console.error('Error fetching logs:', error);
        // Handle or throw the error as needed
        throw error;
    }
};

import React from 'react';
import {
    Dialog,
    DialogTitle,
    Box,
    useTheme,
} from '@mui/material';
import SearchComponent from './SearchTest';
import { Term } from '../Constants/interface';
import Fuse from 'fuse.js';

interface SearchDialogProps {
    open: boolean;
    onClose: () => void;
    terms: Term[];
    fuse: Fuse<Term>;
    sessionId: string;
}

const SearchDialog: React.FC<SearchDialogProps> = ({
    open,
    onClose,
    terms,
    fuse,
    sessionId
}) => {
    const theme = useTheme();

    return (
        <Dialog
            fullWidth
            maxWidth="md"
            open={open}
            onClose={onClose}
            sx={{
                "& .MuiDialog-paper": {
                    bgcolor: theme.palette.mode === 'dark'
                        ? 'rgba(22, 28, 36, 0.94)'
                        : 'rgba(255, 255, 255, 0.9)',
                },
            }}
        >
            <DialogTitle
                sx={{
                    fontSize: "1.2rem",
                    color: theme.palette.text.primary,
                    direction: "rtl",
                }}
            >
                חיפוש מונח ספציפי במאגר המונחים של ההרצאות
            </DialogTitle>
            <Box sx={{ p: 3 }}>
                <SearchComponent sessionId={sessionId} terms={terms} fuse={fuse} />
            </Box>
        </Dialog>
    );
};

export default SearchDialog; 
import React, { useState, useEffect, useRef } from "react";
import {
  List,
  ListItem,
  ListItemText,
  Typography,
  LinearProgress,
  IconButton,
  Fab,
  Button,
} from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause"; // Import the pause icon
import SpeedIcon from "@mui/icons-material/Speed"; // Import an icon for the speed control button
import { Lecture } from "../Constants/interface";
import { a } from "react-spring";

export const Text = () => {
  const audioRef = useRef<HTMLAudioElement>(null);

  const handlePlay = () => {
    if (audioRef.current) {
      audioRef.current.play();
    }
  };

  const handleSeek = () => {
    if (audioRef.current) {
      audioRef.current.currentTime = 20; // Set to 30 seconds
    }
  };

  return (
    <div>
      <audio ref={audioRef} src="10_Nitsana.mp3" controls />
      <Button onClick={handlePlay}>Play</Button>
      <Button onClick={handleSeek}>Seek to 30 seconds</Button>
    </div>
  );
};
export default Text;

import { Chapter } from "./Quiz";

function parseAnswer(answer: any): number | string | undefined {
  if (answer === undefined || answer === "") return undefined;
  const num = Number(answer);
  return isNaN(num) ? answer : num;
}

export async function loadJsonAsChapter(
  jsonPath: string,
  chapterNumber?: number
): Promise<Chapter> {
  try {
    console.log("jsonPath", jsonPath);
    const response = await fetch(jsonPath);
    const json = await response.json();
    return {
      ...json,
      name: chapterNumber ? `פרק ${chapterNumber}` : json.name ?? "unknown",
      questions: json.questions.map((q: any) => ({
        ...q,
        correctAnswer: parseAnswer(q.correct_answer),
        correct_answer: undefined,
      })),
    };
  } catch (error) {
    console.error(`Error loading JSON from ${jsonPath}:`, error);
    return {
      questions: [],
      name: "שגיאה בטעינה",
    };
  }
}

export interface Folder {
  id: string;
  name: string;
  chapters: Chapter[];
}

export interface SubjectFolder {
  [key: string]: Folder;
}
export async function loadFolderAsQuizChapters(
  subjectFolderPath: string
): Promise<SubjectFolder> {
  try {
    const basePath = "/med-helper/";
    const cleanPath = `${basePath}${subjectFolderPath}`.replace(
      "/bioquiz/bioquiz/",
      "/bioquiz/"
    );
    const response = await fetch(`${cleanPath}/index.json`);
    const testFolders = await response.json();
    console.log(testFolders);
    const subjectFolder: SubjectFolder = {};
    let i = 1;
    for (const testFolder of testFolders) {
      const testFolderPath = `${cleanPath}/${testFolder}`;
      const testResponse = await fetch(`${testFolderPath}/index.json`);
      console.log("testResponse", testResponse);
      const fileList = await testResponse.json();
      console.log("fileList", fileList);

      const chapters: Chapter[] = await Promise.all(
        fileList.map((file: string, index: number) =>
          loadJsonAsChapter(`${testFolderPath}/${file}`, index + 1)
        )
      );

      subjectFolder[testFolder] = {
        id: i.toString(),
        name: `פרק ${i}`,
        chapters: chapters,
      };
      i++;
    }
    console.log("subjectFolder", subjectFolder);
    return subjectFolder;
  } catch (error) {
    console.error(
      `Error loading quiz chapters from ${subjectFolderPath}:`,
      error
    );
    throw error;
  }
}

export const folders: { [key: string]: string } = {
  biochapters: "biochapters",
  metabolic: "metabolic",
  lung_diseases: "Yael",
  bioinformatics: "bioinformatics",
  nutrition: "nutrition",
  inflamatory: "inflamatory",
};

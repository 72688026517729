import { ContentNode } from "./Markdown";
import { Box, Typography, IconButton, Collapse } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import HierarchicalContent from "./HierarchicalContent";
import Paragraph from "./Paragraph";

interface RenderNodeProps {
  node: ContentNode;
  styles: any;
  showImages: boolean;
  handleImageClick: (src: string) => void;
  toPDF?: boolean;
  depth?: number;
  handleHeaderClick: (headerId: string) => void;
  collapsedHeaders: Set<string>;
}

const RenderNode: React.FC<RenderNodeProps> = ({
  node,
  styles,
  showImages,
  handleImageClick,
  toPDF,
  depth = 0,
  handleHeaderClick,
  collapsedHeaders,
}) => {
  const isCollapsed = collapsedHeaders.has(node.id || "");

  if (node.type === "content") {
    return (
      <Paragraph
        content={node.content as string}
        styles={styles}
        showImages={showImages}
        handleImageClick={handleImageClick}
      />
    );
  }

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation(); // Stop event from bubbling up
    if (node.id) {
      handleHeaderClick(node.id);
    }
  };

  return (
    <Box
      id={node.id}
      sx={{
        scrollMarginTop: "100px",
        marginTop: 1,
        marginBottom: 1,
      }}
    >
      <Box
        component={node.type}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          cursor: "pointer",
          "&:hover": {
            "& .collapse-button": {
              opacity: 1,
            },
          },
        }}
        onClick={handleClick}
      >
        <Typography
          sx={{
            ...styles[node.type],
            fontWeight: 600 - (node.level || 3) * 50,
          }}
        >
          {node.content}
        </Typography>
        {node.children.length > 0 && (
          <IconButton
            className="collapse-button"
            size="small"
            sx={{
              opacity: 1,
              transition: "opacity 0.2s",
              color: "primary.main",
            }}
            onClick={handleClick}
          >
            {isCollapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}
          </IconButton>
        )}
      </Box>
      {node.children.length > 0 && (
        <Collapse in={!isCollapsed} timeout="auto">
          <HierarchicalContent
            nodes={node.children}
            styles={styles}
            showImages={showImages}
            handleImageClick={handleImageClick}
            toPDF={toPDF}
            depth={depth + 1}
            collapsedHeaders={collapsedHeaders}
            handleHeaderClick={handleHeaderClick}
          />
        </Collapse>
      )}
    </Box>
  );
};

export default RenderNode;

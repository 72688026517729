import React from "react";
import {
  Tooltip,
  IconButton,
  Typography,
  Switch,
  useMediaQuery,
} from "@mui/material";
import LyricsOutlinedIcon from "@mui/icons-material/LyricsOutlined";
import ShortTextIcon from "@mui/icons-material/ShortText";
import SubjectIcon from "@mui/icons-material/Subject";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import ImageIcon from "@mui/icons-material/Image";
import ToggleButton from "@mui/material/ToggleButton";
import {
  ControlContainer,
  ControlGroup,
  StyledToggleButtonGroup,
} from "./ControlStyles";
import MenuBookIcon from "@mui/icons-material/MenuBook";

interface ContentControlsProps {
  view: "transcription" | "summary";
  setView: (view: "transcription" | "summary") => void;
  length: "short" | "long";
  setLength: (length: "short" | "long") => void;
  fontSize: number;
  showImages: boolean;
  onIncreaseFontSize: () => void;
  onDecreaseFontSize: () => void;
  onToggleImages: () => void;
  isMobile: boolean;
  isTocOpen: boolean;
  onToggleToc: () => void;
}

const ContentControls: React.FC<ContentControlsProps> = ({
  view,
  setView,
  length,
  setLength,
  fontSize,
  showImages,
  onIncreaseFontSize,
  onDecreaseFontSize,
  onToggleImages,
  isMobile,
  isTocOpen,
  onToggleToc,
}) => {
  const [toggleValue, setToggleValue] = React.useState(
    view === "summary" && length === "short" ? "short_summary" : view
  );

  const handleToggle = (event: React.MouseEvent<HTMLElement>, value: any) => {
    setToggleValue(value);
    if (["short_summary", "summary"].includes(value)) {
      setView("summary");
      if (value === "short_summary") {
        setLength("short");
      } else {
        setLength("long");
      }
    } else {
      setView("transcription");
    }
  };

  return (
    <ControlContainer>
      <ControlGroup view={view}>
        <StyledToggleButtonGroup
          value={toggleValue}
          exclusive
          onChange={(_, newView) => handleToggle(_, newView)}
          size="large"
        >
          <Tooltip title="צפה בתמלול מלא" arrow>
            <ToggleButton
              value="transcription"
              sx={{ pr: 2, fontSize: { xs: "0.7rem", sm: "1rem" } }}
            >
              תמלול
              {!isMobile && <LyricsOutlinedIcon sx={{ pr: 1 }} />}
            </ToggleButton>
          </Tooltip>
          <Tooltip title="צפה בסיכום" arrow>
            <ToggleButton
              value="summary"
              sx={{ pr: 2, fontSize: { xs: "0.7rem", sm: "1rem" } }}
            >
              סיכום
              {!isMobile && <SubjectIcon sx={{ pr: 1 }} />}
            </ToggleButton>
          </Tooltip>
          <Tooltip title="הצג מונחים עיקריים" arrow>
            <ToggleButton
              value="short_summary"
              sx={{ pr: 2, fontSize: { xs: "0.7rem", sm: "1rem" } }}
            >
              מונחים
              {!isMobile && <ShortTextIcon sx={{ pr: 1 }} />}
            </ToggleButton>
          </Tooltip>
        </StyledToggleButtonGroup>
      </ControlGroup>
      <ControlGroup view={view}>
        <StyledToggleButtonGroup
          value={length}
          exclusive
          onChange={(_, newLength) => setLength(newLength)}
        >
          <Tooltip title="הקטן גודל טקסט" arrow>
            <IconButton onClick={onDecreaseFontSize}>
              <RemoveIcon sx={{ color: "white" }} />
            </IconButton>
          </Tooltip>
          <Typography variant="body2" sx={{ m: 1 }}>
            {(fontSize * 100).toFixed(0)}%
          </Typography>
          <Tooltip title="הגדל גודל טקסט" arrow>
            <IconButton onClick={onIncreaseFontSize}>
              <AddIcon sx={{ color: "white" }} />
            </IconButton>
          </Tooltip>
        </StyledToggleButtonGroup>
      </ControlGroup>

      {view === "summary" && (
        <>
          <ControlGroup view={view}>
            <Tooltip title={`${showImages ? "הסתר" : "הצג"} תמונות`} arrow>
              <IconButton onClick={onToggleImages}>
                <ImageIcon
                  sx={{
                    color: showImages ? "white" : "rgba(255, 255, 255, 0.2)",
                  }}
                />
              </IconButton>
            </Tooltip>
          </ControlGroup>

          {!isMobile && (
            <ControlGroup view={view}>
              <StyledToggleButtonGroup
                value={isTocOpen ? "open" : "closed"}
                exclusive
                onChange={onToggleToc}
                size="large"
              >
                <Tooltip
                  title={`${isTocOpen ? "הסתר" : "הצג"} תוכן עניינים`}
                  arrow
                >
                  <ToggleButton value="open">
                    <MenuBookIcon />
                  </ToggleButton>
                </Tooltip>
              </StyledToggleButtonGroup>
            </ControlGroup>
          )}
        </>
      )}
    </ControlContainer>
  );
};

export default ContentControls;

import React, { useCallback, useEffect, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Select,
  MenuItem,
  Typography,
  FormControl,
  InputLabel,
  Paper,
  SelectChangeEvent,
  TextField,
  CircularProgress,
  Stack,
  Switch,
  FormControlLabel,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Solution from "./AnswerSolution";
import {
  DEMO5,
  DEMO_100_ANSWERS,
  Phi_100_ANSWERS,
  Phi_2021_Answers,
  WEBSITE,
} from "../Constants/consts";
import { AnswerComment, QuestionAnswer } from "../Constants/interface";
import { addPostToDatabase } from "../ApiFunctions/addToDatabase";

import { debounce } from "lodash"; // lodash debounce function
import ResponsiveAppBar from "../shared/AppBar";
import RandomMeme from "../RandomMeme";

export interface DatabaseVotes {
  upvotes: number;
  questionId: string;
}

const TestSolutionMain = () => {
  const MemoizedSolution = React.memo(Solution);

  const [selectedTestSubject, setSelectedTestSubject] = useState("ביופיזיקה");
  const [selectedYear, setSelectedYear] = useState("2023");
  const [loadingFetch, setLoadingFetch] = useState(true);

  const [showAnswers, setShowAnswers] = useState(true);

  const testSubjects = ["ביוכימיה", "ביופיזיקה"]; // Example subjects
  const [testYears, setTestYears] = useState(["2023", "2021"]); // Example years
  const testYearsBioPhysics = ["2022", "2023"]; // Example years

  const [fetchedComments, SetFetchedComments] = useState<AnswerComment[]>([]);
  const [fetchedVotes, SetFetchedVotes] = useState<DatabaseVotes[]>([]);

  const [expanded, setExpanded] = useState<string | false>(false);
  const [questionAnswers, setQuestionAnswers] =
    useState<QuestionAnswer[]>(Phi_100_ANSWERS);
  const [sortedQuestionAnswers, setSortedQuestionAnswers] =
    useState<QuestionAnswer[]>(Phi_100_ANSWERS);

  const [userAgent, setUserAgent] = useState<string | null>(null);
  const [ipData, setIpData] = useState<object>({});
  const [ip, setIp] = useState<string>("");

  const [searchTerm, setSearchTerm] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [memeIndex, setMemeIndex] = useState(getRandomMemeIndex());

  function getRandomMemeIndex() {
    return Math.floor(Math.random() * 27); // Adjust to the number of your memes
  }

  const handleNewMeme = () => {
    const newIndex = getRandomMemeIndex();
    console.log(newIndex);
    setMemeIndex(newIndex);
  };

  const debounceSearch = useCallback(
    debounce((nextValue: string) => {
      setSearchTerm(nextValue);
      setIsSearching(false); // Stop showing progress indicator when search is executed
    }, 1500),
    []
  ); // 500ms delay

  const handleSearchChange = (event: any) => {
    setIsSearching(true); // Start showing progress indicator when user starts typing
    debounceSearch(event.target.value);
  };

  useEffect(() => {
    const userAgent = navigator.userAgent;
    fetch("https://ipinfo.io/json")
      .then((response) => response.json())
      .then((data) => {
        addPostToDatabase(
          {
            data: { ...data, VisitInTestSolutions: true },
            userAgent: userAgent,
          },
          "userVisits",
          WEBSITE
        );
        setIpData(data);
        setIp(data.city);
        setUserAgent(userAgent);
      })
      .catch((error) => console.error("Error fetching IP:", error));
  }, []);

  useEffect(() => {
    const filteredChats = questionAnswers.filter(
      (chat) =>
        searchTerm === "" ||
        chat.question.toLowerCase().includes(searchTerm.toLowerCase()) ||
        chat.answer.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setSortedQuestionAnswers(filteredChats);
  }, [searchTerm, questionAnswers]);

  useEffect(() => {
    const fetchCommentsFromDatabase = async () => {
      setLoadingFetch(true);
      try {
        const response = await fetch(
          `https://database-handling.azurewebsites.net/api/get_posts?container=TestSolutions`
        );
        const data: AnswerComment[] = await response.json();
        SetFetchedComments(data);
        setLoadingFetch(false);
      } catch (error) {
        console.error("Error fetching comments:", error);
        setLoadingFetch(false);
      }
    };

    const fetchVotesFromDatabase = async () => {
      setLoadingFetch(true);
      try {
        const response = await fetch(
          `https://database-handling.azurewebsites.net/api/get_posts?container=votes`
        );
        const data: DatabaseVotes[] = await response.json();
        SetFetchedVotes(data);
        setLoadingFetch(false);
      } catch (error) {
        console.error("Error fetching Votes:", error);
        setLoadingFetch(false);
      }
    };

    fetchVotesFromDatabase();
    fetchCommentsFromDatabase();
  }, []);

  useEffect(() => {
    let isUpdated = false; // Flag to check if we need to update the state
    const updatedQuestionAnswers = questionAnswers.map((qa) => {
      const matchedComments = fetchedComments.filter(
        (comment) => comment.questionID === qa.questionID
      );

      // Check if the comments have actually changed. This is a simple length check; you might need a deeper comparison.
      if (!qa.Comments || qa.Comments.length !== matchedComments.length) {
        isUpdated = true; // Set flag to true as we found an update
        return { ...qa, Comments: matchedComments };
      }
      return qa;
    });

    // Only update the state if there was an update
    if (isUpdated) {
      setQuestionAnswers(updatedQuestionAnswers);
    }
  }, [loadingFetch, fetchedComments, selectedYear, questionAnswers]);

  useEffect(() => {
    const updatedQuestionAnswers = questionAnswers.map((qa) => {
      const matchedVotes = fetchedVotes.filter(
        (vote) => vote.questionId === qa.questionID
      );
      if (matchedVotes.length != 0) {
        return {
          ...qa,
          votes: { ...qa.votes, upVotes: matchedVotes[0].upvotes },
        };
      }
      return { ...qa };
    });

    setQuestionAnswers(updatedQuestionAnswers);
  }, [loadingFetch, fetchedVotes, selectedYear]);

  const handleSelect = (event: SelectChangeEvent<string>) => {
    console.log(selectedTestSubject, "  ", event.target.value);
    if (
      event.target.value == testYears[1] &&
      selectedTestSubject == testSubjects[1]
    ) {
      setQuestionAnswers(Phi_2021_Answers);
      console.log("gi");
    }

    if (
      event.target.value == testYears[0] &&
      selectedTestSubject == testSubjects[1]
    ) {
      setQuestionAnswers(Phi_100_ANSWERS);
    }

    if (
      event.target.value == testYears[1] &&
      selectedTestSubject == testSubjects[0]
    ) {
      setQuestionAnswers(DEMO_100_ANSWERS);
    }

    if (
      event.target.value == testYears[0] &&
      selectedTestSubject == testSubjects[0]
    ) {
      setQuestionAnswers(DEMO5);
    }

    setSelectedYear(event.target.value);
  };

  const handleSelectSubject = (event: SelectChangeEvent<string>) => {
    if (event.target.value == testSubjects[1]) {
      setTestYears(["2023", "2021"]);
    } else {
      setTestYears(["2022", "2023"]);
    }
    setSelectedTestSubject(event.target.value);
  };
  const truncateQuestion = (text: string) => {
    const words = text.split(" ");
    return words.length > 6 ? words.slice(0, 8).join(" ") + "..." : text;
  };

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      const data = {
        ip: ipData,
        userAgent: userAgent,
        panel: panel,
        PressedInTestSolutions: true,
      };
      addPostToDatabase(
        { data: { ...data, VisitInTestSolutions: true }, userAgent: userAgent },
        "userVisits",
        WEBSITE
      );
      handleNewMeme();
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <Box
      sx={{
        width: "100%",
        minHeight: "100vh",
        background:
          "linear-gradient(60deg, rgba(84,58,183,1) 0%, rgba(0,172,193,1) 100%)",
        boxSizing: "border-box",
      }}
    >
      <ResponsiveAppBar />
      <Box
        sx={{
          direction: "rtl",
          textAlign: "center",
          color: "white",
          margin: "30px",
        }}
      >
        <Typography variant="h3" gutterBottom>
          פתרונות לכל* השחזורים
        </Typography>
        <Typography variant="body1" sx={{ m: "10px" }} gutterBottom>
          **וכשאני כותב "כל השחזורים" אני מתכוון למועד 2022 ו2023 של ביוכימיה{" "}
        </Typography>
        <Typography variant="h5">
          מצאתם תשובה שגוייה או תשובה ממש טובה? הצביעו או השאירו תגובה וזה יעזור
          לכולנו !
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          gap: 2,
          justifyContent: "center",
          marginBottom: "30px",
          maxWidth: "100%",
        }}
      >
        <FormControl
          variant="filled"
          sx={{
            direction: "rtl",
            borderRadius: 1,
            minWidth: { xs: 0, sm: 120 },
            backgroundColor: "white",
          }}
        >
          <InputLabel id="test-subject-select-label">בחר נושא</InputLabel>
          <Select
            labelId="test-subject-select-label"
            id="test-subject-select"
            value={selectedTestSubject}
            onChange={handleSelectSubject}
            label="בחר נושא"
          >
            {testSubjects.map((subject) => (
              <MenuItem key={subject} value={subject}>
                {subject}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl
          variant="filled"
          sx={{
            direction: "rtl",
            borderRadius: 1,
            minWidth: { xs: 0, sm: 120 },
            backgroundColor: "white",
          }}
        >
          <InputLabel id="test-year-select-label">בחר שנה</InputLabel>
          <Select
            labelId="test-year-select-label"
            id="test-year-select"
            value={selectedYear}
            onChange={handleSelect}
            label="Test Year"
          >
            {testYears.map((year) => (
              <MenuItem key={year} value={year}>
                {year}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Box
        sx={{
          flexDirection: "column",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            flexDirection: { xs: "column", sm: "row" },
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <FormControlLabel
            control={
              <Switch
                color="secondary"
                checked={showAnswers}
                onChange={(e) => {
                  setShowAnswers(e.target.checked);
                }}
              />
            }
            label="הצג תשובות"
            sx={{ direction: "ltr", color: "white" }} // Adjust the direction if needed
          />
          <TextField
            sx={{
              background: "#e7e4ff63",
              direction: "rtl",
              margin: "15px",
              fontWeight: "bold",
              "& label": {
                mb: "30px",
                mr: "5px",
                left: "auto",
                direction: "rtl",
              },
            }}
            variant="standard"
            label="חפשו בשחזור"
            onChange={handleSearchChange}
            margin="normal"
          />
          <Typography margin={"15px"} color={"white"} variant="h5">
            {`${selectedTestSubject} ${selectedYear}`}
          </Typography>
        </Box>
        {isSearching && (
          <CircularProgress
            color="secondary"
            size={24}
            sx={{ position: "relative", top: "10px", left: "15px" }}
          />
        )}

        <Paper
          elevation={3}
          sx={{
            direction: "rtl",
            maxWidth: "900px",
            margin: "auto",
            borderRadius: 2,
            padding: "20px",
            backgroundColor: "#f7f7f7",
          }}
        >
          {sortedQuestionAnswers.map((answer, index) => {
            const panelId = `panel${index}`;
            return (
              <Accordion
                key={index}
                expanded={expanded === panelId}
                onChange={handleChange(panelId)}
                sx={{
                  marginBottom: "10px",
                  borderRadius: "10px",
                  boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
                  bgcolor: "background.paper",
                  color: "text.primary",
                }}
              >
                {
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`${panelId}-content`}
                    id={`${panelId}-header`}
                    sx={{
                      backgroundColor: "#fff",
                      borderRadius: "10px",
                      "&:hover": {
                        backgroundColor: "rgba(0, 0, 0, 0.04)",
                      },
                    }}
                  >
                    {expanded !== panelId && (
                      <Box sx={{ whiteSpace: "pre-line" }}>
                        {`${answer.questionNumber}. ${answer.question}`}
                        {showAnswers && (
                          <Box sx={{ fontWeight: "bold" }}>
                            {answer.correctAnswer &&
                              `\n  תשובה בשחזור:   ${answer.correctAnswer}`}
                          </Box>
                        )}
                      </Box>
                    )}
                  </AccordionSummary>
                }
                <AccordionDetails sx={{ background: "whitesmoke" }}>
                  <Stack direction={"column"} alignItems={"center"}>
                    <Box sx={{ fontWeight: "bold" }}>
                      {answer.correctAnswer &&
                        `\n  תשובה בשחזור:   ${answer.correctAnswer}`}
                    </Box>
                    <MemoizedSolution questionAnswer={answer} />
                    <RandomMeme index={memeIndex} />
                  </Stack>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </Paper>
      </Box>
    </Box>
  );
};

export default TestSolutionMain;

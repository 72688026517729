import { RowLog, VisitLog } from "./Constants/interface";

interface PanelData {
    ip?: {
        ip?: string;
        city?: string;
        // Add other properties as needed
    };
    panel?: string;
    // Include other panel-related data here, e.g., time spent
}

interface Entry {
    data: PanelData;
    userAgent?: string;
    // Add other properties as needed
}

export interface AggregatedData {
        ip: string;
        city: string;
        userAgent: string;
        panels: Array<{ panel: string; timestamp: string }>;
}

export const aggregateDataByIP = (data: RowLog[]): AggregatedData[] => {
    const userSolutionVisits: AggregatedData[] = [];
    const ipIndexMap: Record<string, number> = {}; // Keeps track of each IP's index in userSolutionVisits
    data.forEach((entry, i) => {

        const ip = entry.data.ip.ip || 'Unknown IP';
        const city = entry.data.ip.city || 'Unknown City';
        const userAgent = entry.userAgent || 'Unknown User Agent';
        if (ip in ipIndexMap) {
            if (entry.data.panel) {
                userSolutionVisits.filter(sol=> sol.ip == ip)[0].panels.push({
                    panel: entry.data.panel,
                    timestamp: entry.timestamp,
                })
            }
        } else {

            ipIndexMap[ip] = userSolutionVisits.length; 
            userSolutionVisits.push({
                ip: ip,
                city: city,
                userAgent: userAgent,
                panels: entry.data.panel ? [{ panel: entry.data.panel, timestamp: entry.timestamp }] : [],
            });
        }
    });

    return userSolutionVisits;
};
import { useMemo, useEffect, useRef, useCallback, useState } from "react";
import { Term } from "./types"; // Move types to separate file if not already done
import { ForceGraph3D } from "react-force-graph";
import { CSS2DObject } from "three/examples/jsm/renderers/CSS2DRenderer";
import { CSS2DRenderer } from "three/examples/jsm/renderers/CSS2DRenderer";
import { Vector3 } from "three";
import SpriteText from "three-spritetext";

interface DisplayVisualTermsNetworkProps {
  terms: Term[];
}

const DisplayVisualTermsNetwork3D = ({
  terms,
}: DisplayVisualTermsNetworkProps) => {
  const fgRef = useRef();
  const [selectedNode, setSelectedNode] = useState<any>(null);

  const lectureColors = useMemo(() => {
    const uniqueLectures = Array.from(
      new Set(terms.map((term) => term.lectureName))
    );
    return Object.fromEntries(
      uniqueLectures.map((lecture, i) => [
        lecture,
        `hsl(${(i * 360) / uniqueLectures.length}, 70%, 50%)`,
      ])
    );
  }, [terms]);

  const graphData = useMemo(() => {
    try {
      console.log("Terms:", terms);
      const nodes = terms.map((term) => ({
        id: term.id,
        name: term.term.split("(")[0].trim(),
        val: 1,
        desc: term.definition,
        lectureName: term.lectureName,
      }));

      // Debug: Log nodes
      console.log("Nodes created:", nodes);

      // Create links from related_terms
      const links = terms.flatMap((term) => {
        // Debug: Log term and its related terms
        console.log(
          "Processing term:",
          term.term,
          "Related terms:",
          term.relatedTerms
        );

        return term.relatedTerms.map((relatedId) => {
          // Debug: Log link creation
          console.log("Creating link from", term.id, "to", relatedId);

          return {
            source: term.id,
            target: relatedId,
            value: 1,
          };
        });
      });

      // Debug: Log final links
      console.log("Links created:", links);

      return {
        nodes,
        links: links.filter(
          (link) =>
            nodes.some((node) => node.id === link.source) &&
            nodes.some((node) => node.id === link.target)
        ),
      };
    } catch (error) {
      console.error("Error creating graph data:", error);
      return { nodes: [], links: [] };
    }
  }, [terms]);

  const handleClick = useCallback(
    (node: any) => {
      const distance = 70;
      const distRatio = 1 + distance / Math.hypot(node.x, node.y, node.z);

      (fgRef.current as any).cameraPosition(
        { x: node.x * distRatio, y: node.y * distRatio, z: node.z * distRatio }, // new position
        node, // lookAt ({ x, y, z })
        1000 // ms transition duration
      );
      setTimeout(() => {
        setSelectedNode(selectedNode === node ? null : node);
      }, 1200);
    },
    [fgRef]
  );
  return (
    <div className="graph-container" style={{ position: "relative" }}>
      <ForceGraph3D
        ref={fgRef}
        graphData={graphData}
        nodeAutoColorBy="lectureName"
        linkDirectionalParticles={1}
        onNodeClick={handleClick}
        nodeThreeObject={(node) => {
          const sprite = new SpriteText(node.name);
          sprite.color = "white";
          sprite.textHeight = 5;
          sprite.fontFace = "system-ui, -apple-system, sans-serif";
          sprite.text = `\u200F${node.name}`; // Add RTL marker before text
          return sprite;
        }}
        nodeThreeObjectExtend={true}
      />
      {selectedNode && (
        <div
          style={{
            position: "absolute",
            top: "20px",
            right: "20px",
            backgroundColor: "rgba(255, 255, 255, 0.95)",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            maxWidth: "300px",
            direction: "rtl",
            zIndex: 1000,
          }}
        >
          <h3
            style={{
              margin: "0 0 10px 0",
              color: lectureColors[selectedNode.lectureName],
              fontSize: "18px",
            }}
          >
            {selectedNode.name}
          </h3>
          <div
            style={{
              fontSize: "14px",
              color: "#666",
              marginBottom: "10px",
            }}
          >
            {selectedNode.lectureName}
          </div>
          <p
            style={{
              margin: "0",
              fontSize: "14px",
              lineHeight: "1.5",
              color: "#333",
            }}
          >
            {selectedNode.desc}
          </p>
          <button
            onClick={() => setSelectedNode(null)}
            style={{
              position: "absolute",
              top: "10px",
              left: "10px",
              background: "none",
              border: "none",
              fontSize: "20px",
              cursor: "pointer",
              color: "#666",
            }}
          >
            ×
          </button>
        </div>
      )}
    </div>
  );
};

export default DisplayVisualTermsNetwork3D;

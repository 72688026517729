import React, { useState, useEffect } from "react";
import {
  Box,
  Chip,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import { Chat } from "../Constants/interface";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
interface ChatDrawerProps {
  userUpvotes: Set<string>;
  drawerOpen: boolean;
  toggleDrawer: (
    open: boolean
  ) => (event: React.KeyboardEvent | React.MouseEvent) => void;
  chats: Chat[];
  handleChatLoad: (chatId: string) => void;
  addToUpvote: (
    chatId: string,
    currentUpvotes: number,
    isUpvoted: boolean
  ) => Promise<void>;
}

const ChatDrawer: React.FC<ChatDrawerProps> = ({
  userUpvotes,
  addToUpvote,
  drawerOpen,
  toggleDrawer,
  chats,
  handleChatLoad,
}) => {
  const [filteredChats, setFilteredChats] = useState<Chat[]>([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    if (searchTerm === "") {
      setFilteredChats(chats);
    } else {
      const lowercasedFilter = searchTerm.toLowerCase();
      const filteredData = chats.filter(
        (chat) =>
          chat.headline?.toLowerCase().includes(lowercasedFilter) ||
          chat.tags?.mainSubject?.toLowerCase().includes(lowercasedFilter) ||
          chat.tags?.tags?.some((tag) =>
            tag.toLowerCase().includes(lowercasedFilter)
          )
      );
      setFilteredChats(filteredData);
    }
  }, [searchTerm, chats]);

  return (
    <Drawer
      anchor="right"
      open={drawerOpen}
      onClose={toggleDrawer(false)}
      sx={{
        "& .MuiDrawer-paper": {
          width: { xs: "85vw", sm: "400px" },
          maxWidth: "100vw",
          bgcolor: "background.paper",
        },
      }}
    >
      <Box sx={{ p: 2, direction: "rtl" }}>
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          placeholder="חיפוש בצ'אטים שמורים..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onClick={(e) => e.stopPropagation()}
          sx={{ mb: 2 }}
        />

        <List>
          {filteredChats.length === 0 ? (
            <Typography
              sx={{ textAlign: "center", p: 2, color: "text.secondary" }}
            >
              לא נמצאו צ'אטים שמורים
            </Typography>
          ) : (
            filteredChats.map((chat) => (
              <React.Fragment key={chat.id}>
                <ListItem
                  button
                  onClick={() => handleChatLoad(chat.id)}
                  sx={{ flexDirection: "column", alignItems: "flex-start" }}
                >
                  <Typography variant="subtitle1" sx={{ width: "100%", mb: 1 }}>
                    {chat.headline}
                  </Typography>

                  <Box
                    sx={{ display: "flex", flexWrap: "wrap", gap: 0.5, mb: 1 }}
                  >
                    {chat.tags?.mainSubject && (
                      <Chip
                        label={chat.tags.mainSubject}
                        color="primary"
                        size="small"
                      />
                    )}
                    {chat.tags?.tags?.map((tag, index) => (
                      <Chip
                        key={index}
                        label={tag}
                        variant="outlined"
                        size="small"
                      />
                    ))}
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      alignSelf: "flex-end",
                    }}
                  >
                    <ThumbUpIcon
                      sx={{
                        fontSize: 18,
                        mr: 0.5,
                        color: userUpvotes.has(chat.id)
                          ? "primary.main"
                          : "text.secondary",
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        addToUpvote(
                          chat.id,
                          chat.upvotes || 0,
                          userUpvotes.has(chat.id)
                        );
                      }}
                    />
                    <Typography variant="caption">
                      {chat.upvotes || 0}
                    </Typography>
                  </Box>
                </ListItem>
                <Divider />
              </React.Fragment>
            ))
          )}
        </List>
      </Box>
    </Drawer>
  );
};

export default ChatDrawer;

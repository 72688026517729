import React, { useEffect, useState } from "react";
import { Box, Typography, TextField, Button, Card } from "@mui/material";
import { AnswerComment } from "../Constants/interface";
import { WEBSITE } from "../Constants/consts";
import { addPostToDatabase } from "../ApiFunctions/addToDatabase";

interface Prop {
  initialComments: AnswerComment[];
  questionId: string;
  questionNumber: number;
}

const QuestionCommentSection = ({
  questionNumber,
  questionId,
  initialComments,
}: Prop) => {
  const [comments, setComments] = useState<AnswerComment[]>(initialComments);
  useEffect(() => {
    setComments(initialComments);
  }, [initialComments]);

  const [commentText, setCommentText] = useState("");
  const [author, setAuthor] = useState("");

  const formatTime = (time: string) => {
    const timestamp = time.replace("item-", "");
    const date = new Date(timestamp);
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear().toString().slice(2); // Get last two digits of the year

    return `${hours}:${minutes}  ${day}/${month}/${year}`;
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault(); // Prevent the form from reloading the page
    const newComment: AnswerComment = {
      content: commentText,
      author: author,
      questionID: questionId,

      timestamp: new Date().toISOString(),
    };
    addPostToDatabase(
      { ...newComment, questionNumber: questionNumber },
      "TestSolutions",
      WEBSITE
    );
    setComments((prevComments) => [...prevComments, newComment]); // Add the new comment to the local state
    setCommentText(""); // Clear the input field after submission
    setAuthor(""); // Clear the author name field after submission
  };

  return (
    <Box dir="rtl" sx={{ margin: "20px 0" }}>
      {comments.map((comment, index) => (
        <Box
          key={index}
          sx={{
            margin: "10px 0",
            padding: "10px",
            borderRadius: "15px",
            backgroundColor: "rgb(209, 217, 228)",
            boxShadow: "0 1px 2px rgba(0,0,0,0.1)",
          }}
        >
          <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
            {comment.author}
          </Typography>
          <Typography variant="body2">{comment.content}</Typography>
          <Typography
            variant="caption"
            sx={{ display: "block", marginTop: "8px" }}
          >
            {formatTime(comment.timestamp)}
          </Typography>
        </Box>
      ))}
      <Card
        component="form"
        onSubmit={handleSubmit}
        sx={{ p: 2, maxWidth: "500px", mt: 2 }}
      >
        <TextField
          variant="standard"
          sx={{
            mt: 2,
            "& label": {
              right: "20px",
              left: "auto",
              direction: "rtl",
            },
            "& label.Mui-focused": {
              right: "20px",
              left: "auto",
              direction: "rtl",
            },
            "& .MuiFilledInput-input": {
              direction: "rtl",
            },
          }}
          label="שם"
          value={author}
          onChange={(e) => setAuthor(e.target.value)}
        />
        <TextField
          fullWidth
          variant="outlined"
          minRows={2}
          multiline
          sx={{
            mt: 2,
            "& label": {
              right: "20px",
              left: "auto",
              direction: "rtl",
            },
            "& label.Mui-focused": {
              right: "auto",
              left: "1px",
              direction: "rtl",
            },
            "& .MuiFilledInput-input": {
              direction: "rtl",
            },
          }}
          label="הוסף תגובה"
          value={commentText}
          onChange={(e) => setCommentText(e.target.value)}
        />

        <Button
          type="submit"
          variant="contained"
          color="primary"
          sx={{ marginTop: "18px" }}
        >
          שלח
        </Button>
      </Card>
    </Box>
  );
};

export default QuestionCommentSection;

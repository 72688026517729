import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  RadioGroup,
  Radio,
  FormControl,
  FormControlLabel,
  Button,
  Box,
  Card,
  CardContent,
  CardActions,
  Select,
  MenuItem,
  Paper,
  SelectChangeEvent,
  Tooltip,
  useTheme,
  IconButton,
} from "@mui/material";
import { logCustomEvent } from "../utils/Analytics";
import { log } from "console";
import { useParams, useNavigate } from "react-router-dom";
import RefreshIcon from "@mui/icons-material/Refresh";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import {
  folders,
  loadFolderAsQuizChapters,
  SubjectFolder,
} from "./quizLoaders";
import { createStyles } from "../Transcription/styles";
import ResponsiveAppBar from "../shared/AppBar";
interface Props {
  questions?: Question[];
}
export interface Question {
  question: string;
  options: string[];
  correctAnswer: number;
  explanation: string;
  page_number?: string;
}
export interface Chapter {
  name: string;
  questions: Question[];
  start_page?: number;
}
export interface QuizChapters {
  id: string;
  name: string;
  chapters: Chapter[];
}
const Quiz: React.FC<Props> = () => {
  const [version, setVersion] = useState<string>("v1");
  const [chapters, setChapters] = useState<Chapter[]>([]);
  const [selectedChapter, setSelectedChapter] = useState<number | null>(null);
  const [questions, setQuestions] = useState<Question[]>([]);
  const [selectedAnswers, setSelectedAnswers] = useState<number[]>([]);
  const [checkedQuestions, setCheckedQuestions] = useState<boolean[]>([]);
  const [showAnswers, setShowAnswers] = useState<boolean[]>([]);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [score, setScore] = useState<number | null>(null);

  const { subject } = useParams<{ subject: string }>();
  const [allChapters, setAllChapters] = useState<SubjectFolder | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [colors, setColors] = useState<string[]>(["#ff9a9e", "#fad0c4"]);
  const gradientPairs = [
    ["#ff9a9e", "#fad0c4"], // Soft peach
    ["#a18cd1", "#fbc2eb"], // Lavender blend
    ["#ffecd2", "#fcb69f"], // Warm sunset
    ["#84fab0", "#8fd3f4"], // Fresh mint
    ["#d4fc79", "#96e6a1"], // Spring greens
    ["#cfd9df", "#e2ebf0"], // Soft blue
    ["#a6c0fe", "#f68084"], // Twilight
    ["#fccb90", "#d57eeb"], // Mango fusion
    ["#e0c3fc", "#8ec5fc"], // Lavender dew
    ["#f093fb", "#f5576c"], // Berry blast
    ["#4facfe", "#00f2fe"], // Azure pop
    ["#43e97b", "#38f9d7"], // Emerald water
    ["#fa709a", "#fee140"], // Sunset glow
    ["#30cfd0", "#330867"], // Deep ocean
    ["#a8edea", "#fed6e3"], // Cotton candy
  ];

  const [gradiantColor_1, gradiantColor_2] = colors;

  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      console.log("subject", subject);
      const subjectFolder =
        folders[subject ?? "lung_diseases"] ?? folders["lung_diseases"];
      if (subjectFolder) {
        try {
          setLoading(true);
          const subjectChapters = await loadFolderAsQuizChapters(subjectFolder);
          setAllChapters(subjectChapters);
        } catch (error) {
          console.error("Error loading chapters:", error);
        } finally {
          setLoading(false);
        }
      } else {
        console.error("Invalid subject:", subject);
      }
    }
    fetchData();
  }, [subject]);

  useEffect(() => {
    if (allChapters) {
      const chapterIds = Object.keys(allChapters);
      if (chapterIds.length > 0) {
        setVersion(chapterIds[0]);
        setChapters(allChapters[chapterIds[0]].chapters);
      }
    }
  }, [allChapters]);

  useEffect(() => {
    logCustomEvent("enter_quiz", {});
    setColors(gradientPairs[Math.floor(Math.random() * gradientPairs.length)]);
  }, []);

  useEffect(() => {
    if (selectedChapter !== null) {
      logCustomEvent("chapter_choosen", {
        query: selectedChapter,
        chapter: chapters[selectedChapter].name ?? "",
        name: chapters[selectedChapter].name ?? "",
      });

      const chapterQuestions = chapters[selectedChapter].questions;
      setQuestions(chapterQuestions);
      setSelectedAnswers(new Array(chapterQuestions.length).fill(-1));
      setCheckedQuestions(new Array(chapterQuestions.length).fill(false));
      setShowAnswers(new Array(chapterQuestions.length).fill(false));
      setSubmitted(false);
      setScore(null);
    }
  }, [selectedChapter, chapters]);

  const handleVersionChange = (
    event: React.MouseEvent<HTMLElement>,
    newVersion: string
  ) => {
    if (newVersion !== null && allChapters) {
      setVersion(newVersion);
      setChapters(allChapters[newVersion].chapters);
      setQuestions([]);
      setSelectedAnswers([]);
      setCheckedQuestions([]);
      setShowAnswers([]);
      setSubmitted(false);
      setScore(null);
    }
  };

  const handleChapterChange = (event: SelectChangeEvent<number>) => {
    setSelectedChapter(event.target.value as number);
  };

  const handleGenerateTest = () => {
    logCustomEvent("generate_test", {
      query: "generate_test",
    });

    const allQuestions = chapters.flatMap((chapter) => chapter.questions);
    const shuffled = allQuestions.sort(() => 0.5 - Math.random());
    const selected = shuffled.slice(0, 30);
    console.log(allQuestions);
    setQuestions(selected);

    setSelectedAnswers(new Array(selected.length).fill(-1));
    setCheckedQuestions(new Array(selected.length).fill(false));
    setShowAnswers(new Array(selected.length).fill(false));
    setSubmitted(false);
    setScore(null);
    setSelectedChapter(null);
  };

  const handleOptionChange = (questionIndex: number, optionIndex: number) => {
    const newSelectedAnswers = [...selectedAnswers];
    newSelectedAnswers[questionIndex] = optionIndex;
    setSelectedAnswers(newSelectedAnswers);
  };

  const handleCheckQuestion = (
    index: number,
    currectAnswer: boolean,
    question: string
  ) => {
    const newCheckedQuestions = [...checkedQuestions];
    newCheckedQuestions[index] = true;
    setCheckedQuestions(newCheckedQuestions);
    logCustomEvent("checked_question", {
      index: index,
      query: question,
      score: currectAnswer,
      givenAnswer: selectedAnswers[index],
      chapter: selectedChapter !== null ? chapters[selectedChapter].name : "",
    });
  };

  const handleSubmit = () => {
    setSubmitted(true);
    calculateScore();
    const score_event: number = questions.reduce(
      (sum, question, index) =>
        selectedAnswers[index] === question.correctAnswer ? sum + 1 : sum,
      0
    );
    const percentage_score: number = (score_event / questions.length) * 100;

    logCustomEvent("quiz_submitted", {
      query: selectedChapter,
      score: percentage_score,
      chapter: selectedChapter !== null ? chapters[selectedChapter].name : "",
    });
  };

  const calculateScore = () => {
    const correctAnswers = questions.reduce(
      (sum, question, index) =>
        selectedAnswers[index] === question.correctAnswer ? sum + 1 : sum,
      0
    );
    const percentage_score: number = (correctAnswers / questions.length) * 100;
    setScore(percentage_score);
  };
  const handleRetry = (index: number) => {
    const newSelectedAnswers = [...selectedAnswers];
    newSelectedAnswers[index] = -1;
    setSelectedAnswers(newSelectedAnswers);
    const newCheckedQuestions = [...checkedQuestions];
    newCheckedQuestions[index] = false;
    setCheckedQuestions(newCheckedQuestions);
    const newShowAnswers = [...showAnswers];
    newShowAnswers[index] = false;
    setShowAnswers(newShowAnswers);
    logCustomEvent("retry_question", {
      index: index,
      query: questions[index].question,
      chapter: selectedChapter !== null ? chapters[selectedChapter].name : "",
    });
  };
  const onSelectQuiz = (chapterId: string, quizIndex: number) => {
    setSelectedChapter(quizIndex);
    setQuestions(chapters[quizIndex].questions);
  };

  const handleShowAnswer = (index: number) => {
    const newShowAnswers = [...showAnswers];
    newShowAnswers[index] = true;
    setShowAnswers(newShowAnswers);
  };

  const handleBack = () => {
    navigate("/bioquiz");
  };

  const theme = useTheme();
  return (
    <Box
      sx={{
        backgroundImage: `linear-gradient(120deg, ${gradiantColor_1} 0%, ${gradiantColor_2} 100%)`,
        minHeight: "100vh",
        py: 4,
      }}
    >
      <Container
        component={Paper}
        elevation={3}
        sx={{
          direction: "rtl",
          maxWidth: { xs: "95vw", md: "70vw" },
          mt: 4,
          p: { xs: 1, md: 4 },
          borderRadius: 2,
          backgroundColor: "rgba(255, 255, 255, 0.7)",
          backdropFilter: "blur(5px)",
          border: "1px solid rgba(255, 255, 255, 0.3)",
          position: "relative", // Add this to position the back button
        }}
      >
        <IconButton
          onClick={handleBack}
          sx={{
            position: "absolute",
            top: 16,
            left: 16,
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            "&:hover": {
              backgroundColor: "rgba(255, 255, 255, 1)",
            },
          }}
        >
          <ArrowBackIcon />
        </IconButton>

        <Typography
          variant="h4"
          gutterBottom
          align="center"
          color="primary"
          sx={{ fontWeight: "bold", mb: 4 }}
        >
          בחן את הידע שלך{" "}
        </Typography>

        <Typography variant="body1" sx={{ mb: 4, textAlign: "right" }}>
          ברוכים הבאים, הכנתי את זה כי לא מצאתי שאלות בדרייב על הקורס <br />
          <br />
          <strong>הוראות שימוש:</strong>
          <br />
          אפשר לבדוק תשובות בנפרד עם "בדוק תשובה" או את כולן בבת אחת בסוף וגם
          לקבל ציון עם "הגש פתרון".
          <br />
          אפשר לבחור פרק ספציפי או לקבל מבחן לדוגמא. עם הכפתור "צור מבחן
          לדוגמא".
          <br />
          <br />
          שימו לב: המבחן עשוי להכיל שאלות שהAI חשב שהן מתוחכמות / טובות. אם אתם
          לא מבינים אותן, אל תרגישו רע - גם הוא כנראה לא.
          <br />
          לשאלות, בעיות, הצעות לשיפור ונאצות למיניהן מוזמנים ליצור איתי קשר
          בוואטסאפ.
        </Typography>

        <Box sx={{ mb: 4 }}>
          <ToggleButtonGroup
            value={version}
            exclusive
            onChange={handleVersionChange}
            aria-label="version selection"
            sx={{ mb: 2 }}
          >
            {allChapters &&
              Object.keys(allChapters).map((chapterId) => (
                <ToggleButton
                  key={chapterId}
                  value={chapterId}
                  aria-label={`version ${chapterId}`}
                >
                  v{allChapters[chapterId].id}
                </ToggleButton>
              ))}
          </ToggleButtonGroup>
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
            {chapters.map((chapter, index) => (
              <Button
                key={index}
                variant={selectedChapter === index ? "contained" : "outlined"}
                onClick={() => onSelectQuiz(chapter.name, index)}
                sx={{
                  borderRadius: 2,
                  "&:hover": {
                    backgroundColor: theme.palette.action.hover,
                  },
                  opacity: selectedChapter === index ? 0.69 : 1,
                }}
              >
                {chapter.name}
              </Button>
            ))}
          </Box>

          <Button
            variant="contained"
            color="secondary"
            onClick={handleGenerateTest}
            sx={{ mt: 2, borderRadius: 2, maxWidth: "500px" }}
            size="large"
          >
            צור מבחן לדוגמא
          </Button>
        </Box>

        {questions.map((question, index) => (
          <Card
            key={index}
            variant="outlined"
            sx={{
              mb: 4,
              borderRadius: 2,
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              transition: "transform 0.2s",
              "&:hover": {
                transform: "translateY(-4px)",
              },
            }}
          >
            <CardContent>
              <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold" }}>
                {question.question}
              </Typography>
              <FormControl component="fieldset">
                <RadioGroup
                  value={
                    selectedAnswers[index] !== -1 ? selectedAnswers[index] : ""
                  }
                  onChange={(e) =>
                    handleOptionChange(index, parseInt(e.target.value))
                  }
                >
                  {question.options.map((option, optionIndex) => (
                    <FormControlLabel
                      key={optionIndex}
                      value={optionIndex}
                      control={<Radio />}
                      label={option}
                      disabled={submitted || checkedQuestions[index]}
                      sx={{ mb: 1 }}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
              {(checkedQuestions[index] || submitted) &&
                !showAnswers[index] && (
                  <>
                    <Typography
                      variant="body1"
                      sx={{ mt: 2 }}
                      color={
                        selectedAnswers[index] === question.correctAnswer
                          ? "success.main"
                          : "error.main"
                      }
                    >
                      {selectedAnswers[index] === question.correctAnswer
                        ? "נכון!"
                        : "לא נכון"}
                    </Typography>
                  </>
                )}
              {showAnswers[index] && (
                <Typography
                  variant="body2"
                  sx={{ mt: 1 }}
                  color="textSecondary"
                >
                  תשובה נכונה: {question.options[question.correctAnswer]}
                  <br />
                  <br />
                  הסבר: {question.explanation}
                  <br />
                  <br />
                  {question.page_number &&
                  selectedChapter !== null &&
                  chapters[selectedChapter]
                    ? chapters[selectedChapter].start_page !== undefined
                      ? `השאלה מבוססת על עמוד ${
                          parseInt(question.page_number) +
                          (chapters[selectedChapter].start_page ?? 0)
                        } בסיכום של הדר`
                      : `השאלה מבוססת על העמוד שנמצא ${question.page_number} עמודים לאחר תחילת הפרק`
                    : ""}
                </Typography>
              )}
            </CardContent>
            <CardActions sx={{ justifyContent: "flex-start", p: 2 }}>
              {!checkedQuestions[index] && !submitted && (
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() =>
                    handleCheckQuestion(
                      index,
                      question.correctAnswer === selectedAnswers[index],
                      question.question
                    )
                  }
                >
                  בדוק תשובה
                </Button>
              )}
              {(checkedQuestions[index] || submitted) &&
                !showAnswers[index] && (
                  <>
                    {selectedAnswers[index] !== question.correctAnswer && (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleRetry(index)}
                        sx={{ ml: 2 }}
                      >
                        נסה שנית
                      </Button>
                    )}
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => handleShowAnswer(index)}
                    >
                      הראה תשובה
                    </Button>
                  </>
                )}
            </CardActions>
          </Card>
        ))}
        {questions.length > 0 && !submitted && (
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            sx={{ my: 4, borderRadius: 2 }}
            size="large"
            fullWidth
          >
            Submit All
          </Button>
        )}
        {submitted && score !== null && (
          <Box
            sx={{
              mt: 4,
              mb: 2,
              p: 3,
              borderRadius: 2,
              backgroundColor: "rgba(255, 255, 255, 0.7)",
              textAlign: "center",
            }}
          >
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              ציון: {score.toFixed(1)}
            </Typography>
          </Box>
        )}
      </Container>
    </Box>
  );
};

export default Quiz;
